import { useEffect, useState } from "react";

import { Table } from "../../../../../../../Components/FinalTable";

import THeader from "./THeader";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext";

import { TableTopBar } from "../../../../../../../Components/FinalTable";

import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";

import { useNavigate } from "react-router-dom";

import useApp from "../../../../../../../Hooks/useApp";

import useAxios from "../../../../../../../Hooks/useAxios";

import Fuse from "fuse.js";

const AllInventoryItemsTable = ({ showLive, setShowLive }) => {
  const [changes, setChanges] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const request = useAxios();

  const [docs, setDocs] = useState(0);

  const [checked_id, setCheckedId] = useState(-1);

  const { setShowFormModal, setModalMessage, setShowSuccessModal } = useApp();

  const navigate = useNavigate();

  const getInitInventoryItems = async (
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) => {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "inventory",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
    setTotal(res.total_docs);
  };

  useEffect(() => {
    getInitInventoryItems(1, "created_at", "desc", {});
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "inventory/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      const fuse = new Fuse([...res], {
        keys: ["name", "code", "shelf_no"],
      });

      const result = fuse.search(searchInput);

      let ids = [];
      let new_docs = [];

      for (let i = 0; i < result.length; i++) {
        if (i === limit) {
          break;
        }
        let doc = result[i].item;
        if (ids.includes(doc.id)) {
          continue;
        }
        ids.push(doc.id);
        new_docs.push(doc);
      }
      setData(new_docs);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitInventoryItems(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitInventoryItems(page - 1, sortField, sortOrder);
    }
  }

  async function handleClearFilters() {
    await getInitInventoryItems(1, "created_at", "desc");
    setPage(1);
    setSearchInput("");
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  async function Submit() {
    if (checked_id === -1) {
      setModalMessage("Select at least one inventory item");
      setShowFormModal(true);
      return;
    }

    let found = false;

    for (let doc of data) {
      if (doc.id === checked_id) {
        found = true;
        let res = await request({
          method: "POST",
          url: "hospital/consumable",
          data: {
            inventory_item_id: parseInt(checked_id),
            price: parseInt(doc.nqty || doc.unit_cost),
          },
        });

        if (res !== "error") {
          navigate(`/dashboard/cash`);
        }
      }
    }

    if (found === false) {
      setModalMessage("Select at least one inventory item");
      setShowFormModal(true);
      return;
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getInitInventoryItems(1, "created_at", "desc", filters);

    setIsFiltered(true);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitInventoryItems(1, field, order);
  }

  return (
    <TContext.Provider
      value={{
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
        checked_id,
        setCheckedId,
        docs,
        setDocs,
      }}
    >
      <div className="w-full overflow-y-auto bg-white px-7 py-8">
        <TableTopBar
          mt={2}
          mb={2}
          showAdd={false}
          isFiltered={isFiltered}
          handleClearFilters={handleClearFilters}
          tableIsLoading={tableIsLoading}
          showReport={false}
          showFilter={false}
          addLabel="Add"
          changes={changes}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <Table>
          <THeader />
          <TBody />
          <TInfo />
          <div className="max-w-2xl w-full mx-auto">
            <BtnRounded mt={2} mb={10} click={Submit} className="btn">
              <span className=" font-pop-reg">Add Item</span>
              <span className="px-3 font-normal text-2xl"></span>
            </BtnRounded>
          </div>
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default AllInventoryItemsTable;
