import { useContext, useEffect, useRef, useState } from "react";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import TextArea from "../../../../../../../Components/Inputs/TextArea";
import NumberInput from "../../../../../../../Components/Inputs/NumberInput";
import BasicModal from "../../../../../../../Components/Modals/BasicModal";
import BtnBlock from "../../../../../../../Components/Buttons/BtnBlock";
import { useNavigate, useParams } from "react-router-dom";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";
import useAxios from "../../../../../../../Hooks/useAxios";
import useApp from "../../../../../../../Hooks/useApp";
import usePouchCud from "./../../../../../Hooks/usePouchCud";
import TableContext from "../InventoryAllocations/TContext";

const AssetModal = ({ showModal = false, setShowModal, idd, qtyy,  }) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);
  const navigate = useNavigate();
  const { update } = usePouchCud();
  const { id } = useParams();
  const request = useAxios();
  const formErrors = useHandleFormError();
  const { setModalMessage, setShowSuccessModal } = useApp();
  const { data, setData, getSpaceInventories } = useContext(TableContext);
  const handleSubmit = async () => {
    // setShowError(showError + 1);
    //navigate("/dashboard/orders/add");
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let datas = {
      id: parseInt(idd),
      qty: parseInt(input.current.form.qty),
      space_id: parseInt(id),
    };

    let res = await request({
      method: "PUT",
      url: "store/allocation",
      data: datas,
    });

    if (res === "error") return;

    if (res !== "error") {
      setModalMessage(`Quantity Updated to ${input.current.form.qty}!`);
      setShowSuccessModal(true);
      await update({ name: "allocations", doc: res });
      getSpaceInventories()
    }

    setShowModal(false);
  };
  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Update Quantity
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <NumberInput
            initial={qtyy}
            inputKey="qty"
            input={input}
            mt={0.5}
            showError={showError}
            config={{
              required: true,
              label: "Quantity",
              placeholder: "14",
              min: 0,
              max: 999999,
            }}
          />

          <BtnBlock
            click={handleSubmit}
            mt={2.5}
            bg="primary"
            textColor="white"
          >
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Update</span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default AssetModal;
