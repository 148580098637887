import React from 'react';

import nurse from "../../../../../../../APP/Assets/Images/hospitalroom.svg";

import { toTZS } from "../../../../../../Util/Currency"

const InvestigationCard = ({ doc }) => {
 
  return (
    <div className="mt-5  bg-white rounded-lg shadow-md w-[50%] m-auto">
      <img src={ nurse } alt="Nurse" className="mx-auto p-5"  />
      <div className="flex justify-between">
        <div className="flex flex-col justify-between gap-x-28 p-10 px-20">
            <div className="flex flex-col my-5">
                <h1 className='text-[#98A1B5] font-semibold text-xl'>NAME</h1>
                <p className='text-xl'>{doc.name}</p>
            </div>
            <div className="flex flex-col">
            <h1 className='text-[#98A1B5] font-semibold text-xl'>CODE</h1>
                <p className='text-xl'>{doc.code}</p>    
            </div>
        </div>
        <div className="flex flex-col justify-between gap-x-28 p-10 px-20">
            <div className="flex flex-col my-5">
            <h1 className='text-[#98A1B5] font-semibold text-xl'>REGULAR PRICE</h1>
                <p className='text-xl'>{toTZS(doc.regular_price)}</p>
            </div>
            <div className="flex flex-col">
            <h1 className='text-[#98A1B5] font-semibold text-xl'>DESCRIPTION</h1>
                <p className='text-xl w-48 break-words'>{doc.description}</p>    
            </div>
        </div>
        </div>
        
      </div>
  )
}

export default InvestigationCard;