import React, { useEffect, useState } from "react";

import TableActions from "./TableActions";

import { convDate } from "../../../../../../Util/Time";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../Components/FinalTable";

import usePouch from "../../../../../../Hooks/usePouch";

import { toTZS } from "../../../../../../Util/Currency";

const TRow = ({ doc }) => {
  const [invent, setInvent] = useState({});
  const [unit, setUnit] = useState({});
  const [categ, setCateg] = useState({});
  const { fetchById } = usePouch();

  useEffect(() => {
    async function initialize() {
      try {
        let inv = await fetchById({
          db: "inventory_items",
          id: doc.inventory_item_id.toString(),
        });
        setInvent(inv);
        let cat = await fetchById({
          db: "inventory_item_categories",
          id: inv.category_id.toString(),
        });
        setCateg(cat);
        let unt = await fetchById({
          db: "unit_of_measures",
          id: inv.unit_of_measure_id.toString(),
        });
        setUnit(unt);
      } catch (e) {}
    }
    initialize();
  }, []);

  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 5}>
        <TableText label={invent.name || doc?.name || doc.inventory_items?.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={toTZS(doc.price)} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={categ.name || doc.inventory_items?.inventory_item_categories?.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={unit.name || doc.inventory_items?.unit_of_measures?.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={convDate(doc.created_at)} />
      </TableCell>
      <TableCell>
        <TableActions doc={doc} />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
