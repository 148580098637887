import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const VisitsChart = ({ wVisits }) => {
  const [wkData, setWeekData] = useState([]);
  function getWeekStart() {
    const locale = navigator.language;
    const options = { weekday: "short" };
    const date = new Date();
    const days = [...Array(7).keys()].map(
      (i) => new Date(date.getTime() - i * 24 * 60 * 60 * 1000)
    );
    const dayNames = days.map((day) => day.toLocaleDateString(locale, options));
    const firstDayIndex = dayNames.indexOf("Sun");
    return firstDayIndex === -1 ? "Mon" : "Sun";
  }

  const getWeekData = (wVisits, weekStart) => {
    const weekData = [];

    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const startDay = (currentDay - weekDays.indexOf(weekStart) + 7) % 7;
    const startDate = new Date(
      currentDate.getTime() - startDay * 24 * 60 * 60 * 1000
    );
    const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    for (let i = 0; i < 7; i++) {
      const day = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
      weekData.push({ day: weekDays[day.getDay()], patients: 0 });
    }
    wVisits.forEach((patient) => {
      const patientDate = new Date(patient.date);
      if (patientDate >= startDate && patientDate <= endDate) {
        const patientDay = patientDate.getDay();
        const dayIndex = (patientDay - weekDays.indexOf(weekStart) + 7) % 7;
        weekData[dayIndex].patients += patient.count;
      }
    });
    return weekData;
  };

  useEffect(() => {
    const weekStart = getWeekStart();
    const weekData = getWeekData(wVisits, weekStart);
    setWeekData(weekData);
  }, [wVisits]);

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  //Fallback function in the event the above function fails
  //Please do not delete
  // Get the current week number
  // const now = new Date();
  // const currentWeek = getWeekNumber(now);

  // // Filter data to keep only the current week's data
  // const filteredData = wVisits.filter(d => getWeekNumber(new Date(d.date)) === currentWeek);

  // // Helper function to get the week number of a date
  // function getWeekNumber(date) {
  //   const yearStart = new Date(date.getFullYear(), 0, 1);
  //   const weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
  //   return weekNo;
  // }

  return (
    <div className=" rounded-xl py-3 bg-white">
      <div className="w-full h-72">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={750}
            height={250}
            data={wkData}
            margin={{
              top: 5,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
              dataKey="day"
              margin={{ top: 20, bottom: 20 }}
              axisLine={false}
              tickLine={false}
              xAxisId={1}
            />
            <XAxis
              dataKey="patients"
              padding={{ top: 20, bottom: 20 }}
              axisLine={false}
              tickLine={false}
              orientation="top"
            />
            <Bar
              dataKey="patients"
              fill="#8085d6"
              barSize={35}
              background={{ fill: "#eee" }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default VisitsChart;
