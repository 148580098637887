import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import { TableTopBar } from "../../../../../Components/FinalTable";
import AllRooms from "./components/Table";
import { useNavigate } from "react-router-dom";
import usePouchPagination from "../../../../../Hooks/usePouchPagination";
import AllSpacesContext from "./context/AllSpacesContext";
import useFuzzySearch from "../../../../../Hooks/useFuzzySearch";
import { useEffect, useState } from "react";
import useAxios from "../../../../../Hooks/useAxios";
import Fuse from "fuse.js";

const LiveData = ({ showLive, setShowLive }) => {
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [filters, setFilters] = useState({});

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const request = useAxios();

  useEffect(() => {
    getInitSpaces(1, "created_at", "desc", filters);
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "hospital/spaces/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      setData(res);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function getInitSpaces(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "hospital/spaces",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitSpaces(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitSpaces(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getInitSpaces(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitSpaces(1, field, order);
  }

  async function handleClearFilters() {
    await getInitSpaces(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <AllSpacesContext.Provider
      value={{
        showLive,
        limit,
        getInitSpaces,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Spaces" />
        </div>
        <div className="w-full px-7 pb-8">
          <TableTopBar
            mt={2}
            mb={2}
            addLabel="Add"
            showAdd={true}
            AddClick={() => navigate("/dashboard/spaces/add")}
            changes={0}
            handleLiveStream={() => {}}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={false}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <AllRooms />
        </div>
      </div>
    </AllSpacesContext.Provider>
  );
};

export default LiveData;
