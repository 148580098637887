import React from 'react'

import { Table } from '../../../../../../Components/FinalTable'

import TableHeader from './TableHeader'

import TBody from './TBody'

import TInfo from './TInfo'

const AllCashTable = () => {
  return (
    <Table>
      <TableHeader />
      <TBody />
      <TInfo />
    </Table>
  )
}

export default AllCashTable