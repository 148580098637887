/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
    TableCell,
    TableRow,
    TableText,
  } from '../../../../../../Components/FinalTable'

import TableActions from './TableActions'
import {convDate} from '../../../../../../Util/Time'
import usePouch from '../../../../../../Hooks/usePouch'
import useApp from '../../../../../../Hooks/useApp'

const TRow = ({doc}) => {

    const { setIsLoading } = useApp();
    const [itemName, setItemName] = useState('....')

    const {fetchById} = usePouch()
    useEffect(() => {
        async function initialize(){
                try {
                  setIsLoading(true);
                    let usr = await fetchById({
                        db: 'space_types',
                        id: doc.type_id.toString()
                    })
                    setItemName(usr.name)
                } catch (e) {
                    
                } finally {
                  setIsLoading(false);
                }
        }
        initialize()
    },[])
  return (
    <TableRow key={doc._id}>
          <TableCell w={90 / 5}>
            <TableText label={doc.name} />
          </TableCell>
          <TableCell w={90 / 5}>
            <TableText label={doc.space_types.name} />
          </TableCell>
          <TableCell w={90 / 5}>
            <TableText label={` ${doc.no}`} />
          </TableCell>
          <TableCell w={90 / 5}>
            <TableText label={convDate(doc.created_at)} />
          </TableCell>
          <TableCell w={90 / 5}>
            <TableText  />
          </TableCell>
          <TableCell>
            <TableActions doc={doc} />
          </TableCell>
        </TableRow>
  )
}

export default TRow