import React from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";
import TableActions from "./TableActions";

const TRow = ({ doc, sid }) => {
  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.titles.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={` ${doc.team_roles.role}`} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.email} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.phone} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableActions doc={doc} sid={sid}/>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
