import { useEffect, useState } from "react";
import LiveData from "./LiveData"
import PouchData from "./PouchData"

const SchemeConsumableTable = () => {
  const [showLive, setShowLive] = useState(true);

  if (showLive) {
    return <LiveData showLive={showLive} setShowLive={setShowLive} />
  }
  return <PouchData showLive={showLive} setShowLive={setShowLive} />
  
};

export default SchemeConsumableTable;
