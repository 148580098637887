import React, { useState } from "react";

import LiveTable from "./LiveTable";

import PouchTable from "./PouchTable";

const Table = () => {
  const [showLive, setShowLive] = useState(true);

  if (showLive) {
    return <LiveTable showLive={showLive} setShowLive={setShowLive} />;
  }

  return <PouchTable showLive={showLive} setShowLive={setShowLive} />;
};

export default Table;
