import { } from '../../../../../../../Components/FinalTable';

import TableHeader from '../../../../../../../Components/FinalTable/components/TableHeader';
import HeaderBtn from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtn';
import HeaderBtnSort from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort';
import HorizontalActionBtn from '../../../../../../../Components/FinalTable/components/Btns/HorizontalActionBtn';
import { useContext, useState } from 'react';
import SchemesAll from '../context/SchemesAll';


const Header =()=>{
   const { handleHeaderQuery,showLive, handleFilterQuery, setIsFiltered } = useContext(SchemesAll)

   const [no, setNo] = useState(true);
   const [name, setName] = useState(true);
   const [created, setCreated] = useState(true);
   const [limit, setLimit] = useState(true)

   function handleNoSort(){
    if (showLive){
        handleHeaderQuery("no", no ? "asc" : "desc");
        setNo(!no);
        return;
    }

   }

   function handleNameSort(){
    if (showLive){
        handleHeaderQuery("name", name ? "asc" : "desc");
        setName(!name);
        return;
    }

   }

   function handleLimitSort(){
    if (showLive){
        handleHeaderQuery("consoltation_limit", limit ? "asc" : "desc" );
        setLimit(!limit)
        return;
    }
   }

   function handleDateSort(){
    if (showLive){
        handleHeaderQuery("created_at", created ? "asc" : "desc");
        setCreated(!created)
        return;
    }
   }
     

    return (
        <TableHeader h={2} mt={10}>
            <HeaderBtnSort label="SCHEME NO." w={90 / 5} click={() => handleNoSort()}  />
            <HeaderBtnSort label="SCHEME NAME" w={90 / 5} click={() => handleNameSort()} />   
            <HeaderBtn label="INSURANCE" w={90 / 5} />
            <HeaderBtnSort label="CONSOLTATION LIMIT" w={90 / 5} click={() => handleLimitSort()} />
            <HeaderBtnSort label="DATE CREATED" w={90 / 5} click={() => handleDateSort()} />
            <div className="flex-1 invisible">
              <HorizontalActionBtn></HorizontalActionBtn>
            </div>         
        </TableHeader>
        
    )
}
export default Header;