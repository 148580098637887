import React from 'react'

import AssetsItemsTable from './AssetsTableForm/Table'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'

const FixedAssetsTable = () => {
  return (
    <div className="w-full h-full bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Fixed Asset" />
          
        </div>
        <div className='w-[70%] mt-10  bg-white m-auto'>
          <AssetsItemsTable />
        </div>
    </div>
  )
}

export default FixedAssetsTable;