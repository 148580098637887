import React, { useEffect, useState } from 'react'

import FormScreen from '../../../../../Components/Templates/FormScreen'

import FormLabel from '../../../../../Components/Label/FormLabel'

import CancelBar from '../../../../../Components/Steppers/CancelBar'

import CashForm from './components/CashForm'

import CashEdit from './context/CashEdit'

import useApp from '../../../../../Hooks/useApp'

import { MdAddCircle } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom'
import useAxios from '../../../../../Hooks/useAxios'

const Edit = () => {

  const [loadingComplete, setLoadingComplete] = useState(false);

  const { setIsLoading } = useApp();

  const navigate = useNavigate()

  const [cash, setCash] = useState({
    id: "",
    price: null,
  });

  const { id } = useParams();

  const request = useAxios();

  useEffect(() => {
    async function initialize() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/consumable/${id}`,
        
        });
         
        if (res === "error") {
          navigate("../")
        }

        setCash(res);
        setLoadingComplete(true);

      } catch (e) {
        navigate("../");
      } finally {
        setIsLoading(false);
      }
      
    }
    initialize();
  }, []);

  return (
    <CashEdit.Provider value={{
      cash,
      setCash,
      loadingComplete,
    }}>
    <div className="w-full h-full bg-ghost_white">
    <FormScreen backBtn={false} back={() => {}}>
      <FormLabel label="Edit Consumable" mt={2} mb={2}>
        <MdAddCircle />
      </FormLabel>
      <CancelBar />
      <div className="w-full h-full flex flex-row justify-center items-center">
        <CashForm />
      </div>
    </FormScreen>
  </div>
  </CashEdit.Provider>
  )
}

export default Edit