import { BiLogOutCircle } from "react-icons/bi";
import BtnTemplate from "../BtnTemplate";

import Tooltip from "../../ToolTip";

import useApp from "../../../../../Hooks/useApp";

import usePouch from "./../../../../../Hooks/usePouch";

import { useNavigate } from "react-router-dom";

import useTables from "../../../Hooks/useTables";

const LogOut = ({ open }) => {
  const id = "logout-tooltip";
  const { setIsLoading, setIsLoggedIn } = useApp();
  const navigate = useNavigate();
  const { deleteIndexes, deleteDB } = usePouch();

  const tables = useTables();

  async function handleLogout() {
    try {
      setIsLoading(true);
      for (let table of tables) {
        await deleteIndexes({ db: table.name });
        await deleteDB({ db: table.name });
      }
      await deleteIndexes({ db: "medico" });
      await deleteDB({ db: "medico" });
    } catch (e) {
      // console.log(e);
    } finally {
      setIsLoading(false);
      //await setIsLoggedIn(false);
      await navigate("/");
    }
  }

  return (
    <div data-tip data-for={id} className="w-full" onClick={handleLogout}>
      <BtnTemplate label={"Logout"} open={open} active={false} click={() => {}}>
        <BiLogOutCircle />
      </BtnTemplate>
      <Tooltip id={id} txt1="Want to" txt2="logout ?">
        <BiLogOutCircle />
      </Tooltip>
    </div>
  );
};

export default LogOut;
