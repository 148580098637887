import React, { useContext, useRef, useState } from 'react'

import TextArea from '../../../../../../Components/Inputs/TextArea'

import TextInput from '../../../../../../Components/Inputs/TextInput'

import BtnRounded from '../../../../../../Components/Buttons/BtnRounded'

import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from '../../../../../../Hooks/useHandleFormError'

import useAxios from '../../../../../../Hooks/useAxios'

import useApp from '../../../../../../Hooks/useApp';

import { useNavigate, useParams } from 'react-router-dom';

import EditContext from './EditContext';

const InsuranceForm = () => {

    const input = useRef({ form: {}, err: {} });

    const [showError, setShowError] = useState(0);
  
    const formErrors = useHandleFormError();

    const { setShowSuccessModal, setModalMessage } = useApp();

    const navigate = useNavigate();

    const {id} = useParams()

    const {insure, setInsure, loadingComplete} = useContext(EditContext)

    const request = useAxios()

    const handleSubmit = async () => {

        setShowError(showError + 1);
    
        if (formErrors({ input })) return;

        let res = await request({
          method: "PUT",
          url: "hospital/insurance",
          data: {
            ...input.current.form,
            id: parseInt(id),
          },
        });
    
        if (res !== "error") {
          setModalMessage("Insurance Updated!");
          setShowSuccessModal(true);
          navigate("../");
        }
      };

      if (loadingComplete === false){
        return null
       }

  return (
    <div
      className="flex flex-col  bg-white rounded-2xl p-10 "
      style={{ width: "70%" }}
    >
      <div className="flex w-full flex-col md:flex-row gap-x-4">
        {/* Left */}
        <div className="flex w-full md:w-full flex-col ">
          <TextInput
          initial={insure.company_name || ""}
            input={input}
            showError={showError}
            inputKey="company_name"
            mt={0.5}
            config={{
              required: true,
              label: "Company",
              placeholder: "Eg. Jubilee",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
          initial={insure.name || ""}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "Eg. Maternity, Health",
              maxChar: 250,
              minChar: 4,
            }}
          />

        <TextArea
            initial={insure.description || ""}
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: true,
              label: "Description",
              placeholder: "Insurance description",
              maxChar: 250,
              minChar: 4,
            }}
          />
        </div>
      </div>
      <div className="max-w-2xl w-full mx-auto">
        <BtnRounded mt={2} click={handleSubmit} className="btn">
          <span className=" font-pop-reg">Submit</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnRounded>
      </div>
    </div>
  )
}

export default InsuranceForm