import React, { useRef, useState, useEffect, useContext } from "react";

import SelectInput from "../../../../../../Components/Inputs/SelectInput";

import useAxios from "./../../../../../../Hooks/useAxios";

import useApp from "./../../../../../../Hooks/useApp";

import { useNavigate, useParams } from "react-router-dom";

import BtnBlock from "../../../../../../Components/Buttons/BtnBlock";

import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import usePouch from "../../../../../../Hooks/usePouch";

import AddContext from "../../Edit/components/AddContext";

import usePouchCud from "./../../../../Hooks/usePouchCud";

import StateInput from "../../../../../../Components/StateInput/SelectInput";

const Form = () => {
  const input = useRef({ form: {}, err: {} });
  const { findAll } = usePouch();

  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState({ "": [] });
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const { create } = usePouchCud();
  const request = useAxios();

  const { setShowSuccessModal, setModalMessage, setShowFormModal } = useApp();

  const navigate = useNavigate();

  const formErrors = useHandleFormError();

  const { id } = useParams();

  useEffect(() => {
    async function Initialize() {
      let res = await request({ method: "GET", url: "team/role" });

      if (res === "error") {
        navigate(`/dashboard/spaces/view/${id}`);
        return;
      }

      let roles = [{ label: "Select Role", value: "" }];
      let users = {};

      for (let doc of res) {
        roles.push({ value: doc.id, label: doc.role });
        users[doc.id] = [{ label: "Select User", value: "" }];
        for (let user of doc.team) {
          users[doc.id].push({ value: user.id, label: user.name });
        }
      }

      setUsers(users);
      setRoles(roles);
      // console.log(users);
      // console.log(roles);
    }
    Initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    // console.log(selectedUser);
    // console.log(selectedRole);

    if (selectedRole === "") {
      setModalMessage("!! Select Role");
      setShowFormModal(true);
      return;
    }

    if (selectedUser === "") {
      setModalMessage("!! Select User");
      setShowFormModal(true);
      return;
    }

    let data = {
      ...input.current.form,
      space_id: parseInt(id),
      user_id: parseInt(selectedUser),
    };

    let res = await request({
      method: "POST",
      url: "hospital/space/assign",
      data: data,
    });

    if (res !== "error") {
      setModalMessage("Space Assigned");
      setShowSuccessModal(true);
      await create({ name: "space_types", doc: res });
      navigate(`/dashboard/spaces/view/${id}`);
    }
  };

  return (
    <div style={{ width: "50%" }} className="bg-white rounded-[15px] p-10">
      <div className="flex gap-x-6 w-full    ">
        <div className=" flex-1">
          <StateInput
            input={selectedRole}
            setInput={setSelectedRole}
            mt={0.5}
            config={{
              required: true,
              firstDisabled: true,
              items: roles,
              label: "Select Role",
            }}
          />

          {selectedRole === "" ? null : (
            <StateInput
              input={selectedUser}
              setInput={setSelectedUser}
              mt={0.5}
              config={{
                required: true,
                firstDisabled: true,
                items: users[selectedRole],
                label: "Select Role",
              }}
            />
          )}
        </div>
      </div>
      <div className="w-full flex justify-center">
        <BtnBlock
          bg="primary"
          textColor="white"
          activeBgColor="secondary"
          mt={2}
          click={handleSubmit}
          className="btn"
        >
          <span className=" font-pop-reg">Submit</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnBlock>
      </div>
    </div>
  );
};

export default Form;
