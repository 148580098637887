import React, { useEffect, useState } from "react";
import { Table } from "../../../../../../../Components/FinalTable";
import usePouchPagination from "../../../../../../../Hooks/usePouchPagination";
import TBody from "./TBody";
import THead from "./THeader";
import TInfo from "./TInfo";
import { useNavigate, useParams } from "react-router-dom";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import TableContext from "./TContext";
import useAxios from "../../../../../../../Hooks/useAxios";

const LiveData = ({ showLive, setShowLive }) => {
  const { id } = useParams();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [space_id, setspace_id] = useState(`${id}`);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const request = useAxios();
  useEffect(() => {
    getSpaceInventories(1, "created_at", "desc", `${id}`, filters);
  }, [limit]);

  async function getSpaceInventories(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    space_id = `${id}`,
    filters = {}
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "hospital/space/team",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        space_id: space_id,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;
    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getSpaceInventories(page + 1, sortField, sortOrder, space_id);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getSpaceInventories(page - 1, sortField, sortOrder, space_id);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getSpaceInventories(1, "created_at", "desc", `${id}`, filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getSpaceInventories(1, field, order, space_id);
  }

  async function handleClearFilters() {
    await getSpaceInventories(1, "created_at", "desc", `${id}`);
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }
  return (
    <TableContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        getSpaceInventories,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex justify-between w-full ">
          <p className="font-DM-Med text-xl uppercase text-cadet_grey">
            Assigned Users
          </p>
          <div className="">
            <BtnPlain
              bg="primary"
              textColor="white"
              activeBgColor="secondary"
              mt={2}
              click={() => navigate(`/dashboard/spaces/assign/${id}`)}
              className="btn"
            >
              <span className="px-2 font-light text-xl"></span>
              <span className=" font-pop-reg">Assign User</span>
            </BtnPlain>
          </div>
        </div>
        <div className="w-full ">
          <Table>
            <THead />
            <TBody />
            <TInfo />
          </Table>
        </div>
      </div>
    </TableContext.Provider>
  );
};

export default LiveData;
