import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import InsuranceItem from "./components/InsuranceItem";

import { useNavigate, useParams } from "react-router-dom";

import { useEffect, useState } from "react";

import SchemesTable from "./components/InsuranceSchemes/SchemesTable";

import useApp from "../../../../../Hooks/useApp";
import useAxios from "../../../../../Hooks/useAxios";

const View = () => {
  const { id } = useParams();
  const [insurance, setInsurance] = useState({});
  const { setIsLoading } = useApp();
  const request = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchInsurance() {
      try {
        setIsLoading(true);
        let res = await request({
          method: "GET",
          url: `hospital/insurance/${id}`,
        });

        setInsurance(res);
      } catch (e) {
        navigate("../");
      } finally {
        setIsLoading(false);
      }
    }
    fetchInsurance();
  }, []);

  return (
    <div className="w-full h-auto bg-ghost_white">
      <BellTopBar label={`${insurance.name}`} />
      <div className="py-5 my-10 w-full flex align-center justify-center">
        <InsuranceItem doc={insurance} id={id} />
      </div>
      <div className="w-full h-full p-10">
        <SchemesTable />
      </div>
    </div>
  );
};

export default View;
