import { useEffect, useRef, useState } from "react";

import TextArea from "../../../../../../Components/Inputs/TextArea";

import TextInput from "../../../../../../Components/Inputs/TextInput";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import useAxios from "../../../../../../Hooks/useAxios";

import useApp from "../../../../../../Hooks/useApp";

import { useNavigate } from "react-router-dom";

import usePouch from "../../../../../../Hooks/usePouch";

import usePouchCud from "./../../../../Hooks/usePouchCud";

import SelectInput from "../../../../../../Components/Inputs/SelectInput";

const SchemeDetails = () => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);

  const formErrors = useHandleFormError();

  const { findAll } = usePouch();

  const { create } = usePouchCud();

  const [insurance, setInsurance] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { setShowSuccessModal, setModalMessage } = useApp();

  const navigate = useNavigate();

  const request = useAxios();


  useEffect(() => {
    initialize(1, "created_at", "desc")
  }, [limit])


    const initialize = async () => {
      try {
        let res = await request({
          method: 'GET',
          url: "hospital/insurances",
          params: {
            page,
            limit,
            
          },
        });
        
        let allCompanies = res.docs

        let companies = [{ value: "", label: "Select Insurance" }];
        allCompanies.forEach((company) => {
          companies.push({ label: company.name, value: company.id });
        });
        setInsurance(companies);
      } catch (e) {}
    };
    


  const submit = async () => {
    setShowError(showError + 1);

    if (formErrors({ input })) return;

    let res = await request({
      method: "POST",
      url: "hospital/insurance/scheme",
      data: {
        insurance_id: parseInt(input.current.form.insurance_id),
        no: input.current.form.no,
        name: input.current.form.name,
        description: input.current.form.description,
        consoltation_limit: parseInt(input.current.form.consoltation_limit),
      },
    });

    if (res !== "error") {
      await create({ name: "insurance_scheme", doc: res });
      setModalMessage("Scheme Added Successfully");
      setShowSuccessModal(true);
      navigate("../");
    }
  };

  return (
    <div
      className="flex flex-col  bg-white rounded-2xl p-10 "
      style={{ width: "55%" }}
    >
      <div className="flex w-full flex-col md:flex-row gap-x-4">
        <div className="flex w-full md:w-full flex-col ">
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="no"
            mt={0.5}
            config={{
              required: true,
              label: "Scheme No.",
              placeholder: "Eg. Scheme001",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Scheme Name",
              placeholder: "Eg. SCHEME001",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <TextInput
            initial={3000}
            input={input}
            showError={showError}
            inputKey="consoltation_limit"
            mt={0.5}
            config={{
              required: true,
              label: "Consoltation Limit",
              placeholder: "Eg. 3000",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <SelectInput
            initial={""}
            input={input}
            inputKey="insurance_id"
            showError={showError}
            mt={0.5}
            config={{
              required: true,
              firstDisabled: false,
              items: insurance,
              label: "Select Insurance",
            }}
          />

          <TextArea
            initial={""}
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: true,
              label: "Description",
              placeholder: "Scheme description",
              maxChar: 250,
              minChar: 4,
            }}
          />
        </div>
      </div>
      <div className="w-full mx-auto">
        <BtnRounded mt={2} click={submit} className="btn">
          <p className=" font-pop-reg">Continue</p>
        </BtnRounded>
      </div>
    </div>
  );
};

export default SchemeDetails;
