import React from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../../Util/Time";

import TableAction from "./TAction";

import { toTZS } from "../../../../../../../Util/Currency";

const TRow = ({ doc }) => {

  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={90 / 5.5}>
          <TableText label={doc.investigations.name} />
        </TableCell>
        <TableCell w={90 / 5.5}>
          <TableText label={doc.investigations.code} />
        </TableCell>
        <TableCell w={90 / 5.5}>
          <TableText label={toTZS(doc.investigations.regular_price)} />
        </TableCell>
        <TableCell w={90 / 5.5}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell w={90 / 5.5}>
          <TableText label={doc.price} />
        </TableCell>
        <TableCell  w={90 / 8.5}>
          <TableAction doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
