import React from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../../Util/Time";
import TableActions from "./TableActions";

const TRow = ({ doc }) => {
  return (
    <>
      <TableRow key={doc.spaces.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.spaces.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.spaces.no} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={` ${doc.spaces.space_types.name}`} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.spaces.description} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={convDate(doc.spaces.created_at)} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
