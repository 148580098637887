import React from 'react'

import AssetsItemsTable from './AssetsTableForm/Table'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'

const FixedAssetTable = () => {
  return (
    <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Fixed Asset" />
          
        </div>
        <AssetsItemsTable />
    </div>
  )
}

export default FixedAssetTable