import React from 'react'
import {Table} from "../../../../../../../Components/FinalTable"
import Header from './TableHeader'
import TableBody from "./TableBody"
import Infosection from "./Infosection"

const SchemesTable = () => {
  return (
      <Table>
        <Header />
        <TableBody />
        <Infosection />
      </Table>
   
  )
}

export default SchemesTable;