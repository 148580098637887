import { FaProcedures } from "react-icons/fa";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const Procedure = ({ open = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "procedure-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Procedure"}
        open={open}
        active={location.pathname.includes("dashboard/procedure")}
        click={() => navigate("procedure")}
      >
        <FaProcedures />
      </BtnTemplate>
      <Tooltip id={id} txt1="Procedure Management" txt2="in one spot">
        <FaProcedures />
      </Tooltip>
    </div>
  );
};

export default Procedure;
