import InsuranceForm from "./components/InsuranceForm";

import FormScreen from '../../../../../Components/Templates/FormScreen'

import FormLabel from '../../../../../Components/Label/FormLabel'

import { useNavigate, useParams } from "react-router-dom";

import { FaRegSun } from "react-icons/fa";
import { useEffect, useState } from "react";
import useApp from "../../../../../Hooks/useApp";
import EditContext from "./components/EditContext";
import useAxios from "../../../../../Hooks/useAxios";

const Insurance = () => {

  const navigate = useNavigate()
  const request = useAxios()
  const { setIsLoading } = useApp();
  const { id } = useParams();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [insure, setInsure] = useState({
    id: "",
    company_name: '',
    name: '',
    description:'',
  });

  useEffect(() => {
    async function initialize() {
      
        setIsLoading(true);
        let doc = await request({
          method: "GET",
          url: `hospital/insurance/${id}`,
        });
        setInsure(doc);
        setLoadingComplete(true);
     
    }
    initialize();
  }, []);

  return (
     <EditContext.Provider value={{insure, setInsure, loadingComplete}}>
      <div className="w-full h-full bg-ghost_white ">
       <FormScreen backBtn={true} back={() => {navigate('../')}}>
        <FormLabel label="Edit Insurance" mt={2} mb={2}>
          <FaRegSun />
        </FormLabel>
        <div className="w-full flex flex-row justify-center items-center">
          <InsuranceForm />
        </div>
      </FormScreen>
      </div>
     </EditContext.Provider>
  );
};

export default Insurance;
