import React, { useContext, useEffect, useState } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../Components/FinalTable";

import NumberInput from "../../../../../../../Components/StateInput/NumberInput";

import TContext from "./TContext";

import SingleCheckBox from "../../../../../../../Components/FinalTable/components/Actions/SingleCheckBox";

import usePouch from "../../../../../../../Hooks/usePouch";

const TRow = ({ doc }) => {

  const [unit, setUnit] = useState({});
  const [categ, setCateg] = useState({});
  const { fetchById } = usePouch();

  useEffect(() => {
    async function initialize() {
      try {
        let cat = await fetchById({
          db: "inventory_item_categories",
          id: doc.category_id.toString(),
        });
        setCateg(cat);
        let unt = await fetchById({
          db: "unit_of_measures",
          id: doc.unit_of_measure_id.toString(),
        });
        setUnit(unt);
      } catch (e) {}
    }
    initialize();
  }, []);
  
  const { checked_id, setCheckedId, setDocs } = useContext(TContext);

  return (
    <TableRow key={doc.id || doc._id}>
      <TableCell w={90 / 6}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={categ.name || doc.inventory_item_categories.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.total_qty} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={unit.name || doc.inventory_item_categories.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <NumberInput
          initial={doc.unit_cost}
          doc={doc}
          setInput={setDocs}
          input_key="price"
          label="Price"
          mt={0.5}
          config={{ required: true }}
        />
      </TableCell>
      <TableCell>
        <SingleCheckBox
          checked_id={checked_id}
          setCheckedId={setCheckedId}
          id={doc.id || doc._id}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
