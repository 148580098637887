import React, { useContext, useEffect, useState } from 'react'

import HeaderBtn from '../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../../Components/FinalTable'
import useAxios from '../../../../../../Hooks/useAxios';
import useApp from '../../../../../../Hooks/useApp';
import AllProceduresContext from '../context/AllProceduresContext';
import HeaderBtnSort from '../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort';

const TableHeader = () => {

  const [name, setName] = useState(true);
  const [code, setcode] = useState(true);
  const [regular_price, setregular_price] = useState(true);
  const [datesort, setdatesort] = useState(true);
  const { setTableIsLoading } = useApp()
  const request = useAxios()

  const { handleHeaderQuery, showLive, handleFilterQuery, setIsFiltered } =
    useContext(AllProceduresContext);


  function handleNameSort() {
    if (showLive) {
      handleHeaderQuery("name", name ? "asc" : "desc");
      setName(!name);
      setIsFiltered(true);
      return;
    }

  }

  function handleCostSort() {
    if (showLive) {
      handleHeaderQuery("regular_price", regular_price ? "asc" : "desc");
      setregular_price(!regular_price);
      setIsFiltered(true);
      return;
    }
  }

  function handlecodeSort() {

    if (showLive) {
      handleHeaderQuery("code", code ? "asc" : "desc");
      setcode(!code);
      setIsFiltered(true);
      return;
    }

    }
  
    function handleDateSort() {

      if (showLive) {
        handleHeaderQuery("created_at", datesort ? "asc" : "desc");
        setdatesort(!datesort);
        setIsFiltered(true);
        return;
      }
  
      }


  return (
    <TableHeaderRow>
      <HeaderBtnSort w={90 / 5} label="NAME" click={handleNameSort} />
      <HeaderBtnSort w={90 / 5} label="CODE." click={handlecodeSort} />
      <HeaderBtnSort w={90 / 5} label="REGULAR PRICE" click={handleCostSort} />
      <HeaderBtn w={90 / 5} label="DESCRIPTION" />
      <HeaderBtnSort w={90 / 5} label="DATE CREATED" click={handleDateSort} />
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default TableHeader