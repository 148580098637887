import React, { useContext, useEffect, useState } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../Components/FinalTable";

import NumberInput from "../../../../../../Components/StateInput/NumberInput";

import TContext from "./TContext";

import SingleCheckBox from "../../../../../../Components/FinalTable/components/Actions/SingleCheckBox";
import usePouch from "../../../../../../Hooks/usePouch";
import { toTZS } from "../../../../../../Util/Currency";

const TRow = ({ doc }) => {
  const [cats, setCats] = useState("...");
  const { fetchById } = usePouch();

  useEffect(() => {
    async function fetchAssets() {
      try {
        let cat = await fetchById({
          db: "inventory_item_categories",
          id: doc.category_id.toString(),
        });
        setCats(cat.name);
      } catch (e) {
      } finally {
      }
    }
    fetchAssets();
  }, []);

  const { checked_id, setCheckedId, setDocs } = useContext(TContext);

  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 6}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.inventory_item_categories ? doc.inventory_item_categories.name : '---'} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.store_qty} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={toTZS(doc.unit_cost)} />
      </TableCell>
      <TableCell w={90 / 6}>
        <NumberInput
          initial={0}
          update={checked_id === doc.id ? true : false}
          setInput={setDocs}
          input_key="qty"
          mt={0.5}
          config={{ max: 9999999999999999, min: 1 }}
        />
      </TableCell>
      <TableCell>
        <SingleCheckBox
          checked_id={checked_id}
          setCheckedId={setCheckedId}
          id={doc.id}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
