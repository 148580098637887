import { RiPieChartLine } from "react-icons/ri";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const SummaryBtn = ({ open = false, active = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "summary-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Summary"}
        open={open}
        active={location.pathname === "/dashboard" ? true : false}
        click={() => navigate("")}
      >
        <RiPieChartLine />
      </BtnTemplate>
      <Tooltip id={id} txt1="Get personal overview of" txt2="all assets">
        <RiPieChartLine />
      </Tooltip>
    </div>
  );
};

export default SummaryBtn;
