import React, { useContext } from "react";

import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";

import { FaRegTrashAlt } from "react-icons/fa";

import useAxios from "../../../../../../../Hooks/useAxios";

import useApp from "../../../../../../../Hooks/useApp";

import TableContext from "./TContext";

const TableActions = ({ doc }) => {
  const request = useAxios();
  const { setShowSuccessModal, setModalMessage } = useApp();
  const { getProcedureInventory } = useContext(TableContext);

  const handleDelete = async () => {
    let res = await request({
      method: "DELETE",
      url: `hospital/procedure/inventory/${doc.id}`,
    });

    if (res !== "error") {
      setModalMessage("Assigned Inventory Deleted!");
      setShowSuccessModal(true);
      getProcedureInventory()
    }
  };

  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <div className=" gap-y-2 flex flex-col text-sm">
        <BtnPlain activeBgColor="white" bg="white" click={handleDelete}>
          <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
            <p className="  text-red">
              <FaRegTrashAlt />
            </p>
          </div>
        </BtnPlain>
      </div>
    </div>
  );
};

export default TableActions;
