import { useContext } from "react";
import InvestigationsAll from "../context/InvestigationContext";

import TRow from "./TableRow";

const TableBody = () => {
  const { data } = useContext(InvestigationsAll);

  return (
    <>
      {data.map((doc) => (
        <TRow key={doc.id || doc._id} doc={doc} data={data} />
      ))}
    </>
  );
};

export default TableBody;
