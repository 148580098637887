import React from 'react'
import BellTopBar from "../../../../../Components/Bars/BellTopBar"
import SchemeConsumableTable from './ConsumeTableForm/Table'

const ConsumableTable = () => {
  return (
    <div className="w-full h-full bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Consumable Scheme" />
          
        </div>
        <div className='w-[90%] mt-10  bg-white m-auto'>
          <SchemeConsumableTable />
        </div>
    </div>
  )
}

export default ConsumableTable