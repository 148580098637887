import React, { useContext, useEffect, useState } from "react";

import TRow from "./TRow";

import TableContext from "./TContext";

const TBody = () => {
  const { data } = useContext(TableContext);

  return (
    <>
      {data.map(
        (doc) =>
          doc.fixed_asset_id && <TRow key={doc._id || doc.id} doc={doc} />
      )}
    </>
  );
};

export default TBody;
