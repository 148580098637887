import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import SignIn from "./Pages/SignIn";

// import Admin from "./Pages/Admin";

import Dashboard from "./Pages/Dashboard";

import Summary from "./Pages/Dashboard/Features/Summary";

import Team from "./Pages/Dashboard/Features/Team";

import AllTeamMembers from "./Pages/Dashboard/Features/Team/All";
import AddTeamMember from "./Pages/Dashboard/Features/Team/AddMember";
import EditTeamMember from "./Pages/Dashboard/Features/Team/Edit";
import ViewTeamMember from "./Pages/Dashboard/Features/Team/SingleTeamMember";
import TeamAddInventory from "./Pages/Dashboard/Features/Team/AddItems/InventoryTable";
import TeamAddFixedAsset from "./Pages/Dashboard/Features/Team/AddItems/FixedAssetTable";
import TeamAddSpace from "./Pages/Dashboard/Features/Team/AddItems/SpacesTable";

import Procedure from "./Pages/Dashboard/Features/Procedures";
import AllProcedures from "./Pages/Dashboard/Features/Procedures/All";
import AddProcedure from "./Pages/Dashboard/Features/Procedures/Add";
import EditProcedure from "./Pages/Dashboard/Features/Procedures/Edit";
import ViewProcedure from "./Pages/Dashboard/Features/Procedures/View";

import Investigation from "./Pages/Dashboard/Features/Investigations";
import AllInvestigation from "./Pages/Dashboard/Features/Investigations/All";
import EditInvestigation from "./Pages/Dashboard/Features/Investigations/Edit";
import AddInvestigation from "./Pages/Dashboard/Features/Investigations/Add";
import ViewInvestigations from "./Pages/Dashboard/Features/Investigations/View";

import Schemes from "./Pages/Dashboard/Features/Schemes";
import AllSchemes from "./Pages/Dashboard/Features/Schemes/All";
import EditScheme from "./Pages/Dashboard/Features/Schemes/Edit";
import AddScheme from "./Pages/Dashboard/Features/Schemes/Add";
import ViewScheme from "./Pages/Dashboard/Features/Schemes/View";

import Spaces from "./Pages/Dashboard/Features/Spaces";
import AllSpaces from "./Pages/Dashboard/Features/Spaces/All";
import EditSpace from "./Pages/Dashboard/Features/Spaces/Edit";
import ViewSpace from "./Pages/Dashboard/Features/Spaces/View";
import AddSpace from "./Pages/Dashboard/Features/Spaces/Add";
import AssignSpace from "./Pages/Dashboard/Features/Spaces/Assign";
import SpaceTable from "./Pages/Dashboard/Features/Procedures/AddItems/SpacesTable";

import AllocateInventoryTable from "./Pages/Dashboard/Features/Spaces/Allocate/InventoryTable";
import AllocateFixedAssetTable from "./Pages/Dashboard/Features/Spaces/Allocate/FixedAssetTable";

import Cash from "./Pages/Dashboard/Features/Cash";
import AllCash from "./Pages/Dashboard/Features/Cash/All";
import AddCash from "./Pages/Dashboard/Features/Cash/Add";
import EditCash from "./Pages/Dashboard/Features/Cash/Edit";

import Insurance from "./Pages/Dashboard/Features/Insurance";
import AddInsurance from "./Pages/Dashboard/Features/Insurance/Add";
import EditInsurance from "./Pages/Dashboard/Features/Insurance/Edit";
import AllInsurance from "./Pages/Dashboard/Features/Insurance/All";
import ViewInsurance from "./Pages/Dashboard/Features/Insurance/View";

import NotFound from "./Pages/404";

import Protected from "./Protected";

import AppContext from "./Context/AppContext";

// import useAxios from "./Hooks/useAxios";

import LoadingModal from "./Components/Modals/LoadingModal";

import RequestModal from "./Components/Modals/RequestModal";

import FormModal from "./Components/Modals/FormModal";

import SuccessModal from "./Components/Modals/SuccessModal";
import InventoryTable from "./Pages/Dashboard/Features/Procedures/AddItems/InventoryTable";
import FixedAssetTable from "./Pages/Dashboard/Features/Procedures/AddItems/FixedAssetTable";
import InventoryItemsTable from "./Pages/Dashboard/Features/Investigations/AddItems/InventoryItemsTable";
import FixedAssetsTable from "./Pages/Dashboard/Features/Investigations/AddItems/FixedAssetsTable";
import InvestigationSpacesTable from "./Pages/Dashboard/Features/Investigations/AddItems/InvestigationSpacesTable";

import InventorySchemeTable from "./Pages/Dashboard/Features/Schemes/AddItems/InventorySchemeTable";
import InvestigateTable from "./Pages/Dashboard/Features/Schemes/AddItems/InvestigateTable";
import ProcedureTable from "./Pages/Dashboard/Features/Schemes/AddItems/ProcedureTable";

import ConsumableTable from "./Pages/Dashboard/Features/Schemes/AddItems/ConsumableTable";

const APP = () => {
  // const request = useAxios();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState();

  const [isOnline, setIsOnline] = useState(true);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [modal_type, setModalType] = useState(null);
  const [modal_message, setModalMessage] = useState(null);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  return (
    <AppContext.Provider
      value={{
        setIsLoading,
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        token,
        setToken,
        showRequestModal,
        setShowRequestModal,
        modal_type,
        setModalType,
        modal_message,
        setModalMessage,
        setShowFormModal,
        setShowSuccessModal,
        isOnline,
        setIsOnline,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="" element={<SignIn />} />

          <Route path="" element={<Protected />}>
            {/* <Route path="admin" element={<Admin />} /> */}
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<Summary />} />
              <Route path="team" element={<Team />}>
                <Route path="" element={<AllTeamMembers />} />
                <Route path="add" element={<AddTeamMember />} />
                <Route path="edit/:id" element={<EditTeamMember />} />
                <Route path="view/:id" element={<ViewTeamMember />} />
                <Route
                  path="view/:id/add/inventory"
                  element={<TeamAddInventory />}
                />
                <Route
                  path="view/:id/add/fixed"
                  element={<TeamAddFixedAsset />}
                />
                <Route path="view/:id/add/space" element={<TeamAddSpace />} />
              </Route>
              <Route path="procedure" element={<Procedure />}>
                <Route path="" element={<AllProcedures />} />
                <Route path="add" element={<AddProcedure />} />
                <Route path="view/:id" element={<ViewProcedure />} />
                <Route
                  path="view/:id/add/inventory"
                  element={<InventoryTable />}
                />
                <Route
                  path="view/:id/add/fixed"
                  element={<FixedAssetTable />}
                />
                <Route path="view/:id/add/space" element={<SpaceTable />} />
                <Route path="edit/:id" element={<EditProcedure />} />
              </Route>
              <Route path="investigations" element={<Investigation />}>
                <Route path="" element={<AllInvestigation />} />
                <Route path="add" element={<AddInvestigation />} />
                <Route path="edit/:id" element={<EditInvestigation />} />
                <Route path="view/:id" element={<ViewInvestigations />} />
                <Route
                  path="view/:id/add/inventory"
                  element={<InventoryItemsTable />}
                />
                <Route
                  path="view/:id/add/fixed"
                  element={<FixedAssetsTable />}
                />
                <Route
                  path="view/:id/add/spaces"
                  element={<InvestigationSpacesTable />}
                />
              </Route>
              <Route path="schemes" element={<Schemes />}>
                <Route path="" element={<AllSchemes />} />
                <Route path="add" element={<AddScheme />} />
                <Route path="edit/:id" element={<EditScheme />} />
                <Route path="view/:id" element={<ViewScheme />} />
                <Route
                  path="view/:id/add/inventory"
                  element={<InventorySchemeTable />}
                />
                <Route
                  path="view/:id/add/investigation"
                  element={<InvestigateTable />}
                />
                <Route
                  path="view/:id/add/procedure"
                  element={<ProcedureTable />}
                />
                <Route
                  path="view/:id/add/consumable"
                  element={<ConsumableTable />}
                />
              </Route>
              <Route path="spaces" element={<Spaces />}>
                <Route path="" element={<AllSpaces />} />
                <Route path="add" element={<AddSpace />} />
                <Route path="edit/:id" element={<EditSpace />} />
                <Route path="assign/:id" element={<AssignSpace />} />
                <Route path="view/:id" element={<ViewSpace />} />
                <Route
                  path="view/:id/add/inventory"
                  element={<AllocateInventoryTable />}
                />
                <Route
                  path="view/:id/add/fixed"
                  element={<AllocateFixedAssetTable />}
                />
              </Route>
              <Route path="cash" element={<Cash />}>
                <Route path="" element={<AllCash />} />
                <Route path="add" element={<AddCash />} />
                <Route path="edit/:id" element={<EditCash />} />
              </Route>
              <Route path="insurance" element={<Insurance />}>
                <Route path="" element={<AllInsurance />} />
                <Route path="add" element={<AddInsurance />} />
                <Route path="edit/:id" element={<EditInsurance />} />
                <Route path="view/:id" element={<ViewInsurance />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      <LoadingModal showModal={isLoading} />
      <RequestModal
        showRequestModal={showRequestModal}
        setShowRequestModal={setShowRequestModal}
        modal_type={modal_type}
        modal_message={modal_message}
      />
      <FormModal
        showFormModal={showFormModal}
        setShowFormModal={setShowFormModal}
        modal_message={modal_message}
      />
      <SuccessModal
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
        modal_message={modal_message}
      />
    </AppContext.Provider>
  );
};

export default APP;
