import React from 'react'

import AssetsItemsTable from './AssetsTableForm/Table'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'
import CancelBar from '../../../../../Components/Steppers/CancelBar'
import { useNavigate } from 'react-router-dom'

const FixedAssetTable = () => {
  const navigate = useNavigate()
  return (
    <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Fixed Asset" />
          <CancelBar click={() => navigate(-1)} />
        </div>
        <div className='w-10/12 bg-white my-20  overflow-x-hidden overflow-y-auto mx-auto z-20'>
          <AssetsItemsTable />
        </div>
    </div>
  )
}

export default FixedAssetTable