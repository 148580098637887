import React, { useContext } from 'react'

import { 
  TableCell,
  TableRow,
  TableText, 
} from '../../../../../../Components/FinalTable'

import TContext from './TContext'

import SingleCheckBox from '../../../../../../Components/FinalTable/components/Actions/SingleCheckBox'

import {convDate} from "../../../../../../Util/Time"

const TRow = ( {doc} ) => {


  const {checked_id, setCheckedId, price, setPrice} = useContext(TContext)
  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 4}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 4}>
        <TableText label={doc.no} />
      </TableCell>
          
      <TableCell w={90 / 4}>
        <TableText label={ convDate(doc.created_at)} />
      </TableCell>
      <TableCell >
      <SingleCheckBox checked_id={checked_id} setCheckedId={setCheckedId} id={doc.id} />
      </TableCell>
  </TableRow>
  )
}

export default TRow