import React from 'react'

import useAllProcedures from '../hooks/useAllProcedures'

import TRow from './TRow'

const TBody = () => {

    const { data } = useAllProcedures();

  return (
    <>
      {data.map((doc) => (
        <TRow key={doc.id || doc._id} doc={doc} />
      ))}
    </>
  )
}

export default TBody