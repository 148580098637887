import React, { useEffect, useState } from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";


import TableAction from "./TAction";

import { toTZS } from "../../../../../../../Util/Currency";

const TRow = ({ doc }) => {
  
  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.inventory_item_categories.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={toTZS(doc.inventory_items.unit_cost)} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.store_qty} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={`${doc.qty}`} />
        </TableCell>
        <TableCell>
          <TableAction doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
