import React, { useEffect } from "react";

import { Outlet } from "react-router-dom";

const Cash = () => {
  return (
    <div className="w-full bg-white h-full">
      <Outlet />
    </div>
  );
};

export default Cash;
