import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";

import { ActionBtn } from "../../../../../../Components/FinalTable";

import { FiEdit2 } from "react-icons/fi";

import { FaRegTrashAlt } from "react-icons/fa";

import AllInsuranceContext from "../context/AllInsuranceContext";
import useAxios from "../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";

const TableActions = ({ doc }) => {
  const request = useAxios();
  const { setShowSuccessModal, setModalMessage } = useApp();
  const { getInitInsurance } = useContext(AllInsuranceContext);

  const handleDelete = async () => {
    let res = await request({
      method: "DELETE",
      url: `hospital/insurance/${doc.id}`,
    });

    if (res !== "error") {
      setModalMessage("Insurance Deleted!");
      setShowSuccessModal(true);
      getInitInsurance()
    }

  };

  const navigate = useNavigate();
  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn next={() => navigate(`view/${doc.id}`)}>
        <div className=" gap-y-2 flex flex-col text-sm">
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => navigate(`edit/${doc.id}`)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FiEdit2 />
              </p>
              <p className=" ">Edit Insurance</p>
            </div>
          </BtnPlain>
          <BtnPlain activeBgColor="white" bg="white" click={handleDelete}>
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-red">
                <FaRegTrashAlt />
              </p>
              <p className=" ">Delete Insurance</p>
            </div>
          </BtnPlain>
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableActions;
