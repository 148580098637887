import React from 'react'

import {
    TableCell,
    TableRow,
    TableText,
} from '../../../../../../Components/FinalTable'

import TableActions from './TableActions'
import {convDate} from '../../../../../../Util/Time'
import {toTZS} from '../../../../../../Util/Currency'

const TRow = ( {doc} ) => {
  return (
    <TableRow key={doc._id}>
    <TableCell w={90 / 5}>
      <TableText label={doc.name} />
    </TableCell>
    <TableCell w={90 / 5}>
      <TableText label={doc.code} />
    </TableCell> 
    <TableCell w={90 / 5}>
      <TableText label={toTZS(doc.regular_price)} />
    </TableCell>
    <TableCell w={90 / 5}>
      <TableText label={doc.description} />
    </TableCell>
   
    <TableCell w={90 / 5}>
      <TableText label={convDate(doc.created_at)} />
    </TableCell>
    <TableCell >
      <TableActions doc={doc} />
    </TableCell>
  </TableRow>
  )
}

export default TRow