import React from "react";

import { Table } from "../../../../../../Components/FinalTable";
import TableBody from "./TableBody";

import TableHeader from "./TableHeader";
import TableInfoSection from "./TableInfoSection";

const AllRooms = () => {
  return (
    <Table>
      <TableHeader />
      <TableBody />
      <TableInfoSection />
    </Table>
  );
};

export default AllRooms;
