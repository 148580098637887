import React from 'react'
import Hospital from '../../../../../Assets/Images/hospital.svg'

const MainBar = () => {
  return (
    <div className='shadow-sm rounded-2xl bg-gradient-to-b from-indigo-500 to-rose-400 flex justify-between p-10'>
        <div className="flex flex-col justify-between gap-y-5 w-full">
            <h1 className="h1 text-3xl text-white py-6">MKOMBOZI HOSPITAL</h1>
            <div className="flex flex-col text-white bg-[#9fa4db98] py-6 pl-6 rounded-2xl h-36">
                <h1 className="text-3xl">MISSION</h1>
                <p className="text-base">To offer patients the best care using advanced technology in an atmosphere of trust, safety and comfort.</p>
            </div>
            <div className="flex flex-col text-white bg-[#9fa4db98] py-6 pl-6 x-auto w-full rounded-2xl h-36">
                <h1 className="text-3xl">VISION</h1>
                <p className="text-base">To be a world-class multi-speciality hospital , delivering exceptional quality, patient safety and outstanding experience, whilst achieving sustainable growth.</p>
            </div>
        </div>
        <img src={Hospital} alt="" className='' />
    </div>
  )
}

export default MainBar