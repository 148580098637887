import { useState, useRef } from "react";

import FormScreen from "../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../Components/Label/FormLabel";

import AddSteppers from "./components/Stepper";

import Forms from "./components/Forms";

import AddProcedure from "./context/addProcedure";

import { FaRegSun } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

const Add = () => {
  const [step, setStep] = useState(-1);

  const navigate = useNavigate();

  const [procedure, setProcedure] = useState({
    name: "",
    code: "",
    description: "",
    regular_price: null,
    room_id: null,
  });

  const [items, setItems] = useState({
    name: "",
    email: "",
    phone: null,
  });

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  return (
    <AddProcedure.Provider
      value={{
        procedure,
        setProcedure,
        items,
        setItems,
      }}
    >
      <FormScreen backBtn={false} back={handleBack}>
        <FormLabel label="Create Procedure" mt={2} mb={2}>
          <FaRegSun />
        </FormLabel>
        <AddSteppers
          currentStep={step}
          close={() => {
            navigate("../");
          }}
        />
        <div className="w-full flex flex-row justify-center items-center">
          <Forms step={step} setStep={setStep} />
        </div>
      </FormScreen>
    </AddProcedure.Provider>
  );
};

export default Add;
