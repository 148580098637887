import React, { useState, useRef, useEffect } from "react";

import { Outlet } from "react-router-dom";

import SideBar from "./Components/SideBar/Sidebar";

import FeatureContext from "./FeatureContext";

const Dashboard = () => {
  return (
    <FeatureContext.Provider value={{}}>
      <div className=" w-screen h-screen overflow-clip flex flex-row">
        <SideBar />
        <div className="w-full h-full overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </FeatureContext.Provider>
  );
};

export default Dashboard;
