import React from 'react' 

import hospitalroom from '../../../../../../Assets/Images/hospitalroom.svg'


const SpaceItem = ({ doc }) => {

  return (
    <div className='bg-white w-[650px] shadow rounded flex flex-col justify-start items-between relative'>
        <img src={hospitalroom} className='mx-auto p-5' alt="" />
        <div className="flex justify-between">
        <div className="flex flex-col justify-between gap-x-28 p-10 px-20">
            <div className="flex flex-col my-5">
                <h1 className='text-[#98A1B5] font-semibold text-xl'>SPACE NO</h1>
                <p className='text-xl'>{doc.no}</p>
            </div>
            <div className="flex flex-col text-xl">
            <h1 className='text-[#98A1B5] font-semibold text-xl'>SPACE TYPE</h1>
            {doc.space_types ? doc.space_types.name : '---'}
                    
            </div>
        </div>
        <div className="flex flex-col justify-between gap-x-28 p-10 px-20">
            <div className="flex flex-col my-5">
            <h1 className='text-[#98A1B5] font-semibold text-xl'>SPACE NAME</h1>
                <p className='text-xl'>{doc.name}</p>
            </div>
            <div className="flex flex-col">
            <h1 className='text-[#98A1B5] font-semibold text-xl'>INFO</h1>
                <p className='text-xl w-48 break-words'>{doc.description}</p>    
            </div>
        </div>
        </div>
    </div>
  )
}

export default SpaceItem