import FormScreen from "../../../../../Components/Templates/FormScreen";
import FormLabel from "../../../../../Components/Label/FormLabel";
import CancelBar from "../../../../../Components/Steppers/CancelBar";
import Form from "./components/Form";
import { MdModeEdit } from "react-icons/md";
import usePouch from "../../../../../Hooks/usePouch";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddContext from "../Edit/components/AddContext";
import useApp from "../../../../../Hooks/useApp";

const AssignUser = () => {
  const { id } = useParams();
  const { fetchById } = usePouch();
  const navigate = useNavigate();
  const { setIsLoading } = useApp();
  const [rom, setRom] = useState({});
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    async function fetchRoom() {
      try {
        setIsLoading(true);
        let res = await fetchById({ db: "spaces", id: id.toString() });
        setRom(res);
        setComplete(true);
      } catch (e) {
        // console.log(e);
        // navigate("../");
      } finally {
        setIsLoading(false);
      }
    }
    fetchRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddContext.Provider value={{ rom, setRom, complete }}>
      <div className="w-full h-full bg-ghost_white">
        <FormScreen backBtn={false} back={() => {}}>
          <FormLabel label="Assign user" mt={2} mb={2}>
            <MdModeEdit />
          </FormLabel>
          <CancelBar />
          <div className="w-full h-full flex flex-row justify-center items-center">
            <Form thisRoom={rom} />
          </div>
        </FormScreen>
      </div>
    </AddContext.Provider>
  );
};

export default AssignUser;
