import React from 'react'

import HeaderBtn from '../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../../Components/FinalTable'

const THeader = () => {
  return (
    <TableHeaderRow>
      
      <HeaderBtn w={90 / 6} label="NAME" />
      <HeaderBtn w={90 / 6} label="SERIAL NUMBER"  />
      <HeaderBtn w={90 / 6} label="CATEGORY"  />
      <HeaderBtn w={90 / 6} label="QUANTITY" />
      <HeaderBtn w={90 / 6} label="UNIT COST" />
      <HeaderBtn w={90 / 6}  />
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default THeader