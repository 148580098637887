import React from 'react'

import HeaderBtnSort from '../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../../Components/FinalTable'
import HeaderBtn from '../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

const THeader = () => {
  return (
    <TableHeaderRow>
      
      <HeaderBtnSort w={90 / 6} label="NAME" />
      <HeaderBtnSort w={90 / 6} label="SERIAL NUMBER"  />
      <HeaderBtnSort w={90 / 6} label="CATEGORY"  />
      <HeaderBtnSort w={90 / 6} label="QUANTITY" />
      <HeaderBtnSort w={90 / 6} label="UNIT COST" />
      <HeaderBtn w={90 / 6}  />
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default THeader