import React from 'react'

import {
    TableCell,
    TableRow,
    TableText,
} from '../../../../../../Components/FinalTable'

import TableActions from './TableActions'

const TRow = ( {doc} ) => {
  return (
    <TableRow key={doc._id}>
    <TableCell w={90 / 5}>
      <TableText label={doc.company_name} />
    </TableCell>
    <TableCell w={90 / 5}>
      <TableText label={doc.name} />
    </TableCell>
    <TableCell w={90 / 5}>
      <TableText label={doc.description} />
    </TableCell>
    <TableCell w={90 / 5}>
      <TableText />
    </TableCell>
    <TableCell w={90 / 5}>
      <TableText />
    </TableCell>
    <TableCell >
      <TableActions doc={doc} />
    </TableCell>
  </TableRow>
  )
}

export default TRow