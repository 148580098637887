import { BsDiagram3Fill } from "react-icons/bs";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const Scheme = ({ open = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "scheme-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Schemes"}
        open={open}
        active={location.pathname.includes("dashboard/schemes")}
        click={() => navigate("schemes")}
      >
        <BsDiagram3Fill />
      </BtnTemplate>
      <Tooltip id={id} txt1="Scheme Management" txt2="in one spot">
        <BsDiagram3Fill />
      </Tooltip>
    </div>
  );
};

export default Scheme;
