import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
  } from "recharts";
  
  const data = [
    {
      month: "JAN",
      patients: 230,
    },
    {
      month: "FEB",
      patients: 280,
    },
    {
      month: "MAR",
      patients: 150,
    },
    {
      month: "APR",
      patients: 210,
    },
    {
      month: "MAY",
      patients: 230,
    },
    {
      month: "JUN",
      patients: 180,
    },
    {
      month: "JUL",
      patients: 290,
    },
    {
      month: "AUG",
      patients: 240,
    },
    {
      month: "SEP",
      patients: 200,
    },
    {
      month: "OCT",
      patients: 60,
    },
    {
      month: "NOV",
      patients: 120,
    },
    {
      month: "DEC",
      patients: 300,
    },
  ];
  const PatientsChart = ({mVisits}) => {
    

    function getMonthName(month) {
      const date = new Date(2023, parseInt(month) - 1);
      return date.toLocaleString('default', { month: 'short' });
    }

    const monthData = [];

  // Initialize month data objects with zero patient visits
  for (let i = 0; i < 12; i++) {
    monthData.push({ month: getMonthName(i + 1), patients: 0 });
  }

  // Fill in month data with patient data
  mVisits.forEach(visit => {
    const monthName = getMonthName(visit.month);
    const monthIndex = monthData.findIndex(month => month.month === monthName);
    if (monthIndex !== -1) {
      monthData[monthIndex].patients = visit.count;
    }
  });


    return (
      <div className=" rounded-xl h-96 px-6 py-3 bg-white">
        <div className="flex justify-between pb-14">
          <h1 className="font-bold text-base  ">No. of Patients per month</h1>
        </div>
        <div className="w-full h-72">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={750}
              height={250}
              data={monthData}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="month" padding={{ bottom: 20 }} axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <Bar dataKey="patients" fill="#a4a9e1" barSize={15} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };
  
  export default PatientsChart;
  