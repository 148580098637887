import React from 'react'

import useAllSpaces from '../hooks/useAllSpaces'
import TRow from './TRow'



const TableBody = () => {
    const { data } = useAllSpaces();
  return (
    <>
      {data.map((doc) => (
        <TRow key={doc.id || doc._id} doc={doc} />
      ))}
    </>
  )
}

export default TableBody