import React from 'react'

import Steppers from '../../../../../../Components/Steppers/Steppers'

import {
    FaPlusCircle,
    // FaWpforms,
  } from "react-icons/fa";

const AddSteppers = ({ currentStep, close }) => {
  return (
    <Steppers currentStep={currentStep} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaPlusCircle />
        <p>EDIT PROCEDURE DETAILS</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        {/* <FaWpforms /> */}
        <p></p>
      </div>
    </Steppers>
  )
}

export default AddSteppers