import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import InvestigationCard from "./components/InvestigationCard";

import { useParams, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import FixedAssetInventory from "./components/Investigationfixed/Table";

import InventoryInvestigation from "./components/InvestigationInventory/Table";
import SpacesInvestigation from "./components/InvestigationSpaces/Table";
import useAxios from "../../../../../Hooks/useAxios";
import useApp from "../../../../../Hooks/useApp";

const View = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const request = useAxios()
  const [investigation, setInvestigation] = useState([]);
  const { setIsLoading } = useApp();
  const [inv, setInv] = useState([]);
  const [fixed, setFixed] = useState([]);
  const [spaces, setSpaces] = useState([]);

  useEffect(() => {
    async function initialize() {
      await fetchData();
    }

    initialize();
  }, []);

    async function fetchData() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/investigation/${id}`,
        });
        
      if (res === "error") {
        navigate("../");
        return;
      }
      setInvestigation(res);
      setInv(res.investigation_inventory);
      setFixed(res.investigation_fixed);
      setSpaces(res.investigation_space)

      } catch (e) {
        navigate("../");
      } finally {
        setIsLoading(false)
      }
    }
  

  return (
    <div className="w-full bg-ghost_white">
      <BellTopBar label={`${investigation.name}`} />
      <InvestigationCard doc={investigation} setDoc={setInvestigation} fetchData={fetchData} />
      <div className="mt-5 px-6">
        <InventoryInvestigation inv={inv} setInv={setInv} fetchData={fetchData}/>
      </div>
      <div className="mt-5 px-6">
        <FixedAssetInventory fixed={fixed} setFixed={setFixed} fetchData={fetchData}/>
      </div>
      <div className="mt-5 px-6">
        <SpacesInvestigation spaces={spaces} setSpaces={setSpaces} fetchData={fetchData} />
      </div>
    </div>
  );
};

export default View;
