import React from 'react'

import InvestigationDetails from './InvestigationDetails'

const Forms = ({ step, setStep }) => {
    if (step === -1) {
        return <InvestigationDetails setStep={setStep} />;
      }
    return <InvestigationDetails setStep={setStep} />;
}

export default Forms