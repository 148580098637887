import React from "react";

import FormScreen from "../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../Components/Label/FormLabel";

import CancelBar from "../../../../../Components/Steppers/CancelBar";

import CashForm from "./components/CashForm";

import { MdAddCircle } from "react-icons/md";

const Add = () => {
  return (
    <div className="w-full h-full bg-ghost_white">
      <FormScreen backBtn={false} back={() => {}}>
        <FormLabel label="Add Consumable 1" mt={2} mb={2}>
          <MdAddCircle />
        </FormLabel>
        <CancelBar />
        <div className="w-full h-full flex flex-row justify-center items-center">
          <CashForm />
        </div>
      </FormScreen>
    </div>
  );
};

export default Add;
