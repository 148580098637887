import React from 'react'

import AllInventoryItemsTable from './InventoryTableForm/Table'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'

const InventoryTable = () => {

  return (

    <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Inventory Item" />
        </div>
        
        <AllInventoryItemsTable />
        
    </div>
    
  )
}

export default InventoryTable