const host = "prod.relax.mkombozihealthcare.co.tz";

const baseUrl = "https://api.mkombozihealthcare.co.tz/api";

const user = "admin";

const pass = "medico-prod";

const dbs = [
  "team",
  "allocations",
  "pics",
  "team_roles",
  "titles",
  "spaces",
  "space_team",
  "space_types",
  "unit_of_measures",
  "unit_of_measures",
  "fixed_assets",
  "fixed_assets_categories",
  "insurance",
  "insurance_scheme",
  "scheme_inventory",
  "scheme_investigation",
  "scheme_procedure",
  "scheme_consumable",
  "inventory_item_categories",
  "inventory_items",
  "consumables",
  "procedures",
  "procedure_fixed",
  "procedure_inventory",
  "procedure_space",
  "investigations",
  "investigation_fixed",
  "investigation_inventory",
  "investigation_space",
];

export { host, pass, user, baseUrl, dbs };
