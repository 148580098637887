import React from 'react'

import HeaderBtn from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import { TableHeaderRow } from '../../../../../../../Components/FinalTable'
import HorizontalActionBtn from '../../../../../../../Components/FinalTable/components/Btns/HorizontalActionBtn'

const THead = () => {
  return (
    <div className="w-full">
    <TableHeaderRow>
      <HeaderBtn w={90 / 5} label="NAME" />
      <HeaderBtn w={90 / 5} label="NO" />
      <HeaderBtn w={90 / 5} label="DESCRIPTION" />
      <HeaderBtn w={90 / 5} label="DATE CREATED" />
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  </div>
  )
}

export default THead