import { SiMicrosoftteams } from "react-icons/si";
import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const Team = ({ open = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "team-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Team"}
        open={open}
        active={location.pathname.includes("dashboard/team")}
        click={() => navigate("team")}
      >
        <SiMicrosoftteams />
      </BtnTemplate>
      <Tooltip id={id} txt1="All your team members" txt2="in one spot">
        <SiMicrosoftteams />
      </Tooltip>
    </div>
  );
};

export default Team;
