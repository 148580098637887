import React, { useState, useEffect } from "react";

import TeamContext from "./TeamContext";

import { FaUsers } from "react-icons/fa";

import FormScreen from "../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../Components/Label/FormLabel";

import Steppers from "./Components/Steppers";

import Forms from "./Components/Forms";

import { useParams, useNavigate } from "react-router-dom";

import usePouch from "../../../../../Hooks/usePouch";

import useApp from "../../../../../Hooks/useApp";

import useAxios from "../../../../../Hooks/useAxios";

const EditMember = () => {
  const [step, setStep] = useState(-1);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    consoltation_fee: "0",
    role_id: "",
    title_id: "",
    gender: "",
  });

  const [pic, setPic] = useState(null);
  const [signature, setSignature] = useState(null);

  const { setIsLoading } = useApp();

  const { fetchById } = usePouch();

  const { id } = useParams();

  const request = useAxios();

  const navigate = useNavigate();

  useEffect(() => {
    async function initialize() {
      try {
        setIsLoading(true);
        let res = await request({
          method: "GET",
          url: `team/${id}`,
        });

        if(res === "error"){
          navigate("/dashboard/team");
        };

        let doc =res;

        try {
          if (doc.avatar_id !== null) {
            let pic = await fetchById({ db: "pics", id: doc.avatar_id });
            doc.avatar_id = pic.pic;
          }
          if (doc.signature_id !== null) {
            let pic = await fetchById({ db: "pics", id: doc.signature_id });
            doc.signature_id = pic.pic;
          }
        } catch (e) {}

        setUser(doc);
        setLoadingComplete(true);
      } catch (e) {
        navigate("../");
      } finally {
        setIsLoading(false);
      }
    }
    initialize();
  }, []);

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  return (
    <TeamContext.Provider
      value={{
        user,
        setUser,
        step,
        setStep,
        pic,
        setPic,
        signature,
        setSignature,
      }}
    >
      <FormScreen backBtn={true} back={handleBack}>
        <FormLabel label={`Edit: ${user.name}`} mt={2} mb={2}>
          <FaUsers />
        </FormLabel>
        <Steppers currentStep={step} close={() => navigate(-1)} />
        <div className="w-full flex flex-row justify-center items-center">
          <div className=" shadow-lg p-6 rounded-2xl" style={{ width: "60%" }}>
            {loadingComplete ? <Forms step={step} setStep={setStep} /> : null}
          </div>
        </div>
      </FormScreen>
    </TeamContext.Provider>
  );
};

export default EditMember;
