import React from "react";

import AllInventoryItemsTable from "./InventoryTableForm/Table";

import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import CancelBar from "../../../../../Components/Steppers/CancelBar";

import { useNavigate } from "react-router-dom";

const InventoryTable = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen  bg-ghost_white">
      <div className="w-full bg-ghost_white">
        <BellTopBar label="Add Inventory Item" />
      </div>
      <CancelBar click={() => navigate(-1)} />
      <div className="w-10/12  bg-white my-20  overflow-x-hidden overflow-y-auto mx-auto z-20">
        <AllInventoryItemsTable />
      </div>
    </div>
  );
};

export default InventoryTable;
