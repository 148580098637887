import React, { useRef, useState, useEffect, useContext } from "react";
import user_img from "./../../../../../../Assets/Images/User_Img.png";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import { BsFillPlusCircleFill } from "react-icons/bs";

import TeamContext from "../TeamContext";

const SelectPic = () => {
  const [files, setFiles] = useState(null);
  const [submited, setSubmited] = useState(null);
  const [image, setImage] = useState(null);

  const { user, setPic, pic, setStep } = useContext(TeamContext);

  const doc = useRef();

  function handleAdd() {
    doc.current.click();
    setSubmited(true);
  }

  useEffect(() => {
    if (files === null || submited === false) return;

    async function addDocument() {
      setImage(URL.createObjectURL(files[0]));
      setPic(files[0]);
    }
    addDocument();
  }, [submited, files]);

  useEffect(() => {
    if (pic === null) {
      setImage(user_img);
      return;
    }
    setImage(URL.createObjectURL(pic));
  }, []);

  async function submit() {
    if (files !== null) {
      setPic(files[0]);
    }

    setStep((c) => c + 1);
  }

  return (
    <div>
      <div className="w-full h-full flex justify-center items-center">
        <div className=" bg-bright_grey p-8 rounded-2xl relative">
          <img
            src={
              pic === null && user.avatar_id !== null
                ? `data:image/jpeg;base64, ${user.avatar_id}`
                : image
            }
            alt="user"
            style={{ maxWidth: "350px", maxHeight: "350px" }}
          />
          <span
            onClick={handleAdd}
            className=" text-5xl cursor-pointer absolute right-3 bottom-2 shadow-2xl bg-transparent rounded-full z-50 text-primary"
          >
            <BsFillPlusCircleFill />
          </span>
        </div>
        <input
          className=" hidden"
          ref={doc}
          type="file"
          id="myFile"
          name="filename"
          accept="image/png, image/jpeg"
          onChange={(e) => setFiles(e.target.files)}
        />
      </div>
      <BtnRounded mt={2} click={submit} className="btn">
        <span className=" font-pop-reg">Continue</span>
      </BtnRounded>
    </div>
  );
};

export default SelectPic;
