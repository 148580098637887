import { useEffect, useRef, useState } from "react";

import NumberInput from "../../../../../../Components/Inputs/NumberInput";

import SelectInput from "../../../../../../Components/Inputs/SelectInput";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import useAxios from "../../../../../../Hooks/useAxios";

import useApp from "../../../../../../Hooks/useApp";

import { useNavigate } from "react-router-dom";

import usePouch from "../../../../../../Hooks/usePouch";

import AllInventoryItemsTable from "./InvTable/Table";

import BellTopBar from "../../../../../../Components/Bars/BellTopBar";

const CashForm = () => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);

  const formErrors = useHandleFormError();

  const { findAll } = usePouch();

  const [inventory, setInventory] = useState([]);

  const { setShowSuccessModal, setModalMessage, setIsLoading } = useApp();

  const navigate = useNavigate();

  const request = useAxios();

  useEffect(() => {
    async function Initialize() {
      setIsLoading(true);
      try {
        let res = await findAll({
          db: "inventory_items",
          index: ["_id"],
          selector: {},
          sort: [{ _id: "asc" }],
        });
        let c = [{ value: 0, label: "Select Inventory Item" }];
        for (let i = 0; i < res.length; i++) {
          let cat = res[i];
          c.push({
            value: parseInt(cat.id),
            label: cat.name,
          });
        }
        setInventory(c);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
    Initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    setShowError(showError + 1);

    if (formErrors({ input })) return;

    let res = await request({
      method: "POST",
      url: "hospital/consumable",
      data: {
        ...input.current.form,
        inventory_item_id: parseInt(input.current.form.inventory_item_id),
        price: parseInt(input.current.form.price),
      },
    });

    if (res !== "error") {
      setModalMessage("Consumable Added Successfully");
      setShowSuccessModal(true);
      navigate("../");
    }
  };

  return (
    <div className="w-full h-screen  overflow-y-auto bg-ghost_white">
      <AllInventoryItemsTable />
    </div>
  );
};

export default CashForm;
