import React, { useEffect, useState } from "react";
import { Table } from "../../../../../../../Components/FinalTable";
import usePouchPagination from "../../../../../../../Hooks/usePouchPagination";
import TBody from "./TBody";
import THead from "./THeader";
import TInfo from "./TInfo";
import { useNavigate, useParams } from "react-router-dom";

import TableContext from "./TContext";

const PouchData = ({showLive}) => {
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const db_name = "space_team";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
  });

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex justify-between w-full ">
          <p className="font-DM-Med text-xl uppercase text-cadet_grey">
            Assigned Users
          </p>
          <div
            className="px-6 py-2 rounded shadow cursor-pointer bg-primary text-white font-semibold mr-5"
            onClick={() => navigate(`/dashboard/spaces/assign/${id}`)}
          >
            Assign User
          </div>
        </div>
        <div className="w-full ">
          <Table>
            <THead />
            <TBody />
            <TInfo />
          </Table>
        </div>
      </div>
    </TableContext.Provider>
  );
};

export default PouchData;
