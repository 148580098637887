import React, { useEffect, useState } from "react";

import { Table } from "../../../../../../../Components/FinalTable";

import TBody from "./TBody";

import THeader from "./THeader";

import TInfo from "./TInfo";

import TableContext from "./TContext";

import { useParams } from "react-router-dom";

import useAxios from "../../../../../../../Hooks/useAxios";

const LiveData = ({showLive, setShowLive}) => {
    const {id} = useParams()
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortField, setSortField] = useState("created_at");
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [data, setData] = useState([]);
    const [showPagination, setShowPagination] = useState(true);
    const [filters, setFilters] = useState({});
    
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
  
    const request = useAxios();
    useEffect(() => {
        getUserAllocations(1, "created_at", "desc", `${id}`, filters);
      }, [limit]);
    
      
    
      async function getUserAllocations(
        page = 1,
        sort_field = "created_at",
        sort_order = "desc",
        filters = {}
      ) {
        setTableIsLoading(true);
        let res = await request({
          method: "GET",
          url: `hospital/insurance/${id}`,
          params: {
            page,
            limit,
            sort_field: sort_field,
            sort_order: sort_order,
            ...filters,
          },
          network_error: false,
        });
    
        if (res === "network-error") {
          setShowLive(false);
          return;
        }
    
        setTableIsLoading(false);
    
        if (res === "error") return;
        setData(res.insurance_scheme);
        setHasNextPage(res.hasNextPage);
        setHasPrevPage(res.hasPrevPage);
      }
    
      async function handleNext() {
        if (hasNextPage) {
          setPage(page + 1);
          getUserAllocations(page + 1, sortField, sortOrder);
        }
      }
    
      async function handlePrev() {
        if (hasPrevPage) {
          setPage(page - 1);
          getUserAllocations(page - 1, sortField, sortOrder);
        }
      }
    
      async function handleFilterQuery(filters) {
        setSortField("created_at");
        setSortOrder("desc");
        getUserAllocations(1, "created_at", "desc", filters);
    
        setIsFiltered(true);
        setFilters(filters);
      }
    
      async function handleHeaderQuery(field, order) {
        setPage(1);
        setSortOrder(order);
        setSortField(field);
        getUserAllocations(1, field, order);
      }
    
      async function handleClearFilters() {
        await getUserAllocations(1, "created_at", "desc", );
        setPage(1);
        setSortField("created_at");
        setSortOrder("desc");
        setIsFiltered(false);
        setShowPagination(true);
      }

  return (
    <TableContext.Provider
      value={{
        showLive,
        getUserAllocations,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full">
          <p className="font-DM-Med text-lg uppercase text-BLACK">SCHEMES</p>
          {/* <div className="">
    <BtnPlain
          bg="primary"
          textColor="white"
          activeBgColor="secondary"
          mt={2}
          click={() => navigate("add/inventory")}
          className="btn"
        >
          
          <span className="px-2 font-light text-xl">
            <FaPlus />
          </span>
          <span className=" font-pop-reg">Add Inventory</span>
        </BtnPlain>
    </div> */}
        </div>
        <Table>
          <THeader />
          <TBody />
          <TInfo />
        </Table>
      </div>
    </TableContext.Provider>
  );
};

export default LiveData;
