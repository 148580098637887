import React from 'react'
import { Table } from '../../../../../../../Components/FinalTable'
import TBody from './TBody'
import THead from './THeader'
import TInfo from './TInfo'
import TableContext from './TContext'
import { useNavigate } from 'react-router-dom'
import {FaPlus} from 'react-icons/fa'

import BtnRounded from '../../../../../../../Components/Buttons/BtnRounded'

const SpacesInvestigation = ({spaces, setSpaces}) => {
  const navigate = useNavigate()

return (
  <TableContext.Provider
    value={{
      data : spaces,
      setData : setSpaces,
      
    }}
  >
  <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
    <div className="flex flex-row justify-between w-full">
      <p className="font-DM-Med text-lg uppercase text-BLACK">
      SPACES INVESTIGATION
      </p>
      <BtnRounded click={() => navigate("add/spaces")} >
        <span className="px-2 font-light text-xl">
          <FaPlus />
        </span>
        <span className=" font-pop-reg">Add Investigation Space</span>
      </BtnRounded>
    </div>
    <Table>
      <THead />
      <TBody />
      <TInfo />
    </Table>
  </div>
</TableContext.Provider>
)
}

export default SpacesInvestigation;