import React from 'react'

import AssetsItemsTable from './AssetsTableForm/Table'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'

import CancelBar from '../../../../../Components/Steppers/CancelBar'

const FixedAssetTable = () => {
  return (
    <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Fixed Asset" />
          <CancelBar />
        </div>
        <div className='w-10/12 bg-white my-20  overflow-x-hidden overflow-y-auto mx-auto z-20'>
          <AssetsItemsTable />
        </div>
    </div>
  )
}

export default FixedAssetTable