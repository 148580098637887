import React, { useState, useEffect } from "react";

import { colors } from "./../../Util/colors";

const NumberInput = ({
  initial,
  doc = {},
  mt = 0,
  mb = 0,
  config = {
    disabled: false,
    required: true,
    label: "Number input",
    placeholder: "",
    max: 999999999,
    min: 0,
  },
}) => {
  const [borderColor, setBorderColor] = useState("bright_grey");
  const [no, setNo] = useState(initial);

  function handleChange(n) {
    if (n > config.max) return;
    setNo(n);
    doc.nqty = n;
  }

  return (
    <div
      className="flex flex-col"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <label className=" font-DM-Reg text-base">{config.label}</label>
      <input
        className={`border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey `}
        onFocus={() => setBorderColor("primary")}
        style={{
          borderColor: colors[borderColor],
          cursor: config.disabled ? "not-allowed" : "text",
        }}
        type={"number"}
        placeholder={config.placeholder}
        value={no}
        disabled={config.disabled}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

export default NumberInput;
