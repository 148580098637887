import React, { useContext, useEffect, useState } from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";

import usePouch from "../../../../../../../Hooks/usePouch";

import TableActions from "./TableActions";

import { convDate } from "../../../../../../../Util/Time";

import { toTZS } from "../../../../../../../Util/Currency";

const TRow = ({ doc }) => {
  const [assets, setAssets] = useState({});

  const [cats, setCats] = useState("-");

  const { fetchById } = usePouch();

  useEffect(() => {
    async function fetchAssets() {
      try {
        let inv = await fetchById({
          db: "fixed_assets",
          id: doc.fixed_asset_id,
        });
        setAssets(inv);
        let cat = await fetchById({
          db: "fixed_assets_categories",
          id: inv.category_id,
        });
        setCats(cat.name);
      } catch (e) {
      } finally {
      }
    }
    fetchAssets();
  }, [fetchById, doc.fixed_asset_id]);
  useEffect(() => {}, [doc]);
  return (
    <>
      <TableRow key={assets.id}>
        <TableCell w={90 / 6}>
          <TableText label={doc.fixed_assets.name || assets.name} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={doc.fixed_assets.sn || assets.sn} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={doc.fixed_assets.fixed_assets_categories.name || cats} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={` ${doc.qty}`} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={toTZS(doc.fixed_assets.unit_cost ||assets.unit_cost)} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
