import { useContext, useRef, useState } from 'react'

import NumberInput from '../../../../../../Components/Inputs/NumberInput'

import BtnRounded from '../../../../../../Components/Buttons/BtnRounded'

import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from '../../../../../../Hooks/useHandleFormError';

import useAxios from '../../../../../../Hooks/useAxios2';

import useApp from '../../../../../../Hooks/useApp';

import { useNavigate, useParams } from 'react-router-dom';

import CashEdit from '../context/CashEdit';


const CashForm = () => {

    const input = useRef({ form: {}, err: {} });

    const [showError, setShowError] = useState(0);
  
    const formErrors = useHandleFormError();

    const { setShowSuccessModal, setModalMessage } = useApp();

    const navigate = useNavigate();

    const request = useAxios()

    const {cash , loadingComplete} = useContext(CashEdit)

    const {id} = useParams()

      const handleSubmit = async () => {

        setShowError(showError + 1);
    
        if (formErrors({ input })) return;

        let res = await request({
          method: "PUT",
          url: "hospital/consumable",
          data: {
            ...input.current.form,
            id: parseInt(id),
            price: parseInt(input.current.form.price),
          },
        });
    
        if (res !== "error") {
          setModalMessage("Consumable Updated!");
          setShowSuccessModal(true);
          navigate("../");
        }
      };

      if (loadingComplete === false){
        return null
       }



  return (
    <div
      className="flex flex-col  bg-white rounded-2xl p-10 "
      style={{ width: "70%" }}
    >
      <div className="flex w-full flex-col md:flex-row gap-x-4">
        {/* Left */}
        <div className="flex w-full md:w-full flex-col ">

        <NumberInput
            initial={cash.price || ""}
            input={input}
            showError={showError}
            inputKey="price"
            mt={0.5}
            config={{
              required: true,
              label: "Price",
              placeholder: "Enter price ",
              maxChar: 13,
              minChar: 6,
            }}
          />

      
        </div>
      </div>
      <div className="max-w-2xl w-full mx-auto">
        <BtnRounded mt={2} click={handleSubmit} className="btn">
          <span className=" font-pop-reg">Submit</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnRounded>
      </div>
    </div>
  )
}

export default CashForm