import React, { useState, useEffect } from "react";

import {
  HorizontalActionBtn,
  TableHeaderRow,
} from "../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import useTeamAll from "../Hooks/useTeamAll";

import usePouch from "./../../../../../../Hooks/usePouch";

const TableHeader = () => {
  const [name, setName] = useState(true);
  const [email, setEmail] = useState(true);
  const [phone, setPhone] = useState(true);
  const [status, setStatus] = useState(true);
  const [role, setRole] = useState(0);

  const [userRoles, setUserRoles] = useState([]);

  const { handleHeaderQuery, handleFilterQuery, setIsFiltered, showLive } = useTeamAll();

  const { findAll } = usePouch();

  useEffect(() => {
    async function init() {
      try {
        let res = await findAll({
          db: "team_roles",
          selector: { _id: { $gte: null } },
          sort: [{ _id: "asc" }],
          index: ["_id"],
        });
        let roles = res.map((role) => role.id);
        setUserRoles(roles);
      } catch (e) {}
    }

    init();
  }, []);

  function HandleNameSort() {

    if (showLive) {
      handleHeaderQuery("name", name ? "asc" : "desc");
      setName(!name);
      setIsFiltered(true);
      return;
    }

    setIsFiltered(false);
    const index = ["name"];
    const sort = [{ name: name ? "asc" : "desc" }];
    const selector = { name: { $gte: null } };

    setName(!name);

    handleHeaderQuery(index, sort, selector);
  }

  function HandleEmailSort() {
    
    if (showLive) {
      handleHeaderQuery("email", email ? "asc" : "desc");
      setEmail(!email);
      setIsFiltered(true);
      return;
    }

    setIsFiltered(false);
    const index = ["email"];
    const sort = [{ email: email ? "asc" : "desc" }];
    const selector = { email: { $gte: null } };

    setEmail(!email);

    handleHeaderQuery(index, sort, selector);
  }

  function HandlePhoneSort() {
    
    if (showLive) {
      handleHeaderQuery("phone", phone ? "asc" : "desc");
      setPhone(!phone);
      setIsFiltered(true);
      return;
    }

    setIsFiltered(false);
    const index = ["phone"];
    const sort = [{ phone: phone ? "asc" : "desc" }];
    const selector = { phone: { $gte: null } };
    setPhone(!phone);
    handleHeaderQuery(index, sort, selector);
  }

  function HandleRoleFilter() {

    if (showLive) {
      handleHeaderQuery("role_id", role ? "asc" : "desc");
      setRole(!role);
      setIsFiltered(true);
      return;
    }

    const index = ["created_at"];
    const sort = [{ created_at: "desc" }];
    const selector = {
      created_at: { $gte: null },
    };
    const secondarySelector = {
      role_id: {
        $eq: role > userRoles.length - 1 ? userRoles[0] : userRoles[role],
      },
    };

    if (role >= userRoles.length - 1) {
      setRole(0);
    } else {
      setRole(role + 1);
    }
    handleFilterQuery(index, sort, selector, secondarySelector);

    setIsFiltered(true);
  }

  function HandleStatusFilter() {

    if (showLive) {
      handleHeaderQuery("is_active", status ? "asc" : "desc");
      setStatus(!status);
      setIsFiltered(true);
      return;
    }
    
    setIsFiltered(true);
    const index = ["created_at"];
    const sort = [{ created_at: "desc" }];
    const selector = {
      created_at: { $gte: null },
    };

    const secondarySelector = {
      is_active: {
        $eq: status,
      },
    };

    handleFilterQuery(index, sort, selector, secondarySelector);
    setStatus(!status);
    setIsFiltered(true);
  }

  return (
    <TableHeaderRow>
      <HeaderBtnSort w={90 / 5} label="NAME" click={HandleNameSort} />
      <HeaderBtnSort w={90 / 5} label="EMAIL" click={HandleEmailSort} />
      <HeaderBtnSort w={90 / 5} label="PHONE" click={HandlePhoneSort} />
      <HeaderBtnSort w={90 / 5} label="ROLE" click={HandleRoleFilter} />
      <HeaderBtnSort w={90 / 5} label="Status" click={HandleStatusFilter} />
      <div className="flex-1 invisible">
        {/* <TableHorizontalActionBtn /> */}
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  );
};

export default TableHeader;
