// eslint-disable-next-line no-unused-vars
import React, {useContext} from 'react'

import AllSpacesContext from '../context/AllSpacesContext'

const useAllSpaces = () => {
    const {
        showLive,
        limit,
        setLimit,
        getInitSpaces,
        data,
        setData,
        rusers,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        setIsFiltered,
        total,
        showPagination,
        setFixed,
        resetQuery,
      } = useContext(AllSpacesContext);

    return {
        showLive,
        limit,
        setLimit,
        getInitSpaces,
        data,
        setData,
        rusers,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
    };
}

export default useAllSpaces