import React, { useContext, useEffect, useState } from 'react'

import TRow from './TRow'

import TableContext from './TContext';
import { useParams } from 'react-router-dom';

const TBody = () => {
  const { data } = useContext(TableContext);
  const {id} = useParams()
  const [newData, setNewData] = useState([])
  
  useEffect(()=>{
    async function getData(){
      let dta = await data.filter(function(item){
          return item.investigation_id === parseInt(id)
        })
        setNewData(dta)
    }
    getData()
  },[data])


  return (
    <>
      {newData.map((doc) => <TRow key={doc._id || doc.id} doc={doc} /> )}
    </>
  )
}

export default TBody