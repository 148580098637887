import React from 'react'

import Steppers from '../../../../../../Components/Steppers/Steppers'

import { FaPlusCircle } from "react-icons/fa";

const AddSteppers = ({ currentStep, close }) => {
  return (
    <Steppers currentStep={currentStep} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaPlusCircle />
        <p>ADD INVESTIGATION DETAILS</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
       
        
      </div>
    </Steppers>
  )
}

export default AddSteppers