import React from 'react'

import useAllInsurance from '../hooks/useAllInsurance'

import TRow from './TRow'

const TBody = () => {

    const { data } = useAllInsurance();

  return (
    <>
      {data.map((doc) => (
        <TRow key={doc.id || doc._id} doc={doc} />
      ))}
    </>
  )
}

export default TBody