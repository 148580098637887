import { useContext } from "react";
import SchemesAll from "../context/SchemesAll";

import TRow from "./TableRow";

const TableBody = () => {
  const { data } = useContext(SchemesAll);

  return (
    <>
      {data.map((doc) => (
        <TRow key={doc.id || doc._id} doc={doc} />
      ))}
    </>
  );
};

export default TableBody;
