import React from 'react'

import HeaderBtn from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import { TableHeaderRow } from '../../../../../../../Components/FinalTable'

const THeader = () => {
  return (
    <div className="w-full">
    <TableHeaderRow>
      <HeaderBtn w={90 / 4} label="NO." />
      <HeaderBtn w={90 / 4} label="NAME" />
      <HeaderBtn w={90 / 4} label="DESCRIPTION" />
      <HeaderBtn w={90 / 4} label="DATE CREATED" />
    </TableHeaderRow>
  </div>
  )
}

export default THeader