import { useState, useEffect } from "react";

import FormScreen from "../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../Components/Label/FormLabel";

import CancelBar from "../../../../../Components/Steppers/CancelBar";

import { FiEdit2 } from "react-icons/fi";

import { useNavigate, useParams } from "react-router-dom";

import useApp from "../../../../../Hooks/useApp";
import useAxios from "../../../../../Hooks/useAxios";
import EditSchemeDetails from "./components/EditSchemeDetails";

const Edit = () => {
  const [step, setStep] = useState(-1);

  const { id } = useParams();

  const {setIsLoading } = useApp();

  const request = useAxios()

  const navigate = useNavigate();

  const [scheme, setScheme] = useState([]);
  const [complete, setComplete] = useState(false)  


  useEffect(() => {
    async function initialize() {
      await fetchData();
    }

    initialize();
  }, []);

    async function fetchData() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/insurance/scheme/${id}`,
        });
        
      if (res === "error") {
        navigate("../");
        return;
      }

      setScheme(res);
      setComplete(true)
      
      } catch (e) {
        navigate("../");
      } finally {
        setIsLoading(false)
      }
    }


  return (
       <FormScreen backBtn={false} >
        <FormLabel label="Edit Scheme" mt={2} mb={2}>
          <FiEdit2 />
        </FormLabel>
        <CancelBar />
        <div className="w-full mt-8 flex flex-row justify-center items-center">
          <EditSchemeDetails
           scheme={scheme}
           etScheme={setScheme} 
           complete={complete} 
           setComplete={setComplete} 
           fetchData={fetchData}
          />
        </div>
      </FormScreen>
    
  );
};

export default Edit;
