import React, { useState } from 'react'

import AllInventoryItemsTable from './InventoryTableForm/Table'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'

import AddItemsContext from './context/AddItemsContext'

const InventoryItemsTable = () => {
  const [docs, setDocs] = useState(0);
  
  return (
    <AddItemsContext.Provider
      value={{
        docs, 
        setDocs,
        
      }}
    >

    <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Inventory Item" />
          
        </div>
        <div className='w-[70%] bg-white m-auto'>
          <AllInventoryItemsTable />
        </div>
    </div>

    </AddItemsContext.Provider>
    
  )
}

export default InventoryItemsTable;