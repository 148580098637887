import { useEffect, useState } from "react";

import { Table } from "../../../../../../Components/FinalTable";

import THeader from "./THeader";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext";

import { TableTopBar } from "../../../../../../Components/FinalTable";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import { useNavigate, useParams } from "react-router-dom";

import useApp from "../../../../../../Hooks/useApp";
import usePouchCud from "./../../../../Hooks/usePouchCud";
import useAxios from "../../../../../../Hooks/useAxios";

const SchemeConsumableTable = ({showLive, setShowLive}) => {
  
    const [limit, setLimit] = useState(5);
    const [data, setData] = useState([]);
    const [checked_id, setCheckedId] = useState(-1);
    const [showPagination, setShowPagination] = useState(true);
  
    const { id } = useParams();
    const [sortValue, setSortValue] = useState("desc");
    const [sortField, setSortField] = useState("created_at");
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [page, setPage] = useState(1);
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [searchInput, setSearchInput] = useState("");
  
    const [price, setPrice] = useState(0);
  
    const request = useAxios();
  
    const { setShowFormModal, setModalMessage, setShowSuccessModal } = useApp();
  
    const navigate = useNavigate();
  
    const { create } = usePouchCud();
  
    
    useEffect(() => {
      getInventory(1, "created_at", "desc");
    }, [limit]);
  
    useEffect(() => {
      if (searchInput.length < 3) return;
      async function search() {
        setTableIsLoading(true);
        let res = await request({
          method: "GET",
          url: "inventory/search",
          params: {
            value: searchInput,
          },
        });
  
        if (res === "network-error") {
          setShowLive(false);
          return;
        }
  
        if (res === "error") return;
  
        setData(res);
  
        setShowPagination(false);
        setIsFiltered(true);
        setTableIsLoading(false);
      }
  
      search();
    }, [searchInput]);
    
  
    async function getInventory(
      page = 1,
      sort_field = "created_at",
      sort_value = "desc",
      
    ) {
      setTableIsLoading(true);
  
      let res = await request({
        method: "GET",
        url: "inventory",
        params: {
          page,
          limit,
          sort_field: sort_field,
          sort_value: sort_value,
          
        },
        network_error: false,
      });
  
  
      if (res === "network-error") {
        setShowLive(false);
        return;
      }
  
      setTableIsLoading(false);
  
      if (res === "error") return;
  
      setData(res.docs);
      setHasNextPage(res.hasNextPage);
      setHasPrevPage(res.hasPrevPage);
    }
  
    async function handleNext() {
      if (hasNextPage) {
        setPage(page + 1);
        getInventory(page + 1, sortField, sortValue);
      }
    }
  
    async function handlePrev() {
      if (hasPrevPage) {
        setPage(page - 1);
        getInventory(page - 1, sortField, sortValue);
      }
    }
  
    async function handleFilterQuery(filters) {
      setSortField("created_at");
      setSortValue("desc");
      setPage(1);
      getInventory(1, "created_at", "desc", filters);
  
      setIsFiltered(true);
      
    }
  
    async function handleHeaderQuery(field, order) {
      setPage(1);
      setSortValue(order);
      setSortField(field);
      getInventory(1, field, order);
    }
  
  
    async function handleClearFilters() {
      setSearchInput("");
      await getInventory(1, "created_at", 'desc')
      setPage(1);
      setSortField("created_at");
      setSortValue("desc");
      setIsFiltered(false);
      setShowPagination(true);
    }

  async function submit() {
    if (checked_id === -1) {
      setModalMessage("Select at least one consumable");
      setShowFormModal(true);
      return;
    }

    let price = 1;
    for (let doc of data) {
      if (doc.id === checked_id) {
        if (doc.nqty) {
          price = parseInt(doc.nqty);
        }
        break;
      }
    }

    if (price < 1) {
      setModalMessage("Price should be greater than 1");
      setShowFormModal(true);
      return;
    }

    let res = await request({
      method: "POST",
      url: "hospital/insurance/scheme/consumable",
      data: {
        scheme_id: parseInt(id),
        inventory_item_id: parseInt(checked_id),
        price: price,
      },
    });

    if (res !== "error") {
      await create({ name: "inventory_items", doc: res });
      setModalMessage("Consumable Scheme Added");
      setShowSuccessModal(true);
      navigate(`/dashboard/schemes/view/${id}`);
    }
  }

  return (
    <TContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total: '...',
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
        checked_id,
        setCheckedId,
        price,
        setPrice,
      }}
    >
      <div className="px-7 mt-4 pb-8">
        <TableTopBar
          mt={4}
          mb={2}
          showAdd={false}
          isFiltered={isFiltered}
          handleClearFilters={handleClearFilters}
          tableIsLoading={tableIsLoading}
          showReport={false}
          showFilter={false}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <Table>
          <THeader />
          <TBody />
          <TInfo />
          <div className="max-w-2xl w-full mx-auto">
            <BtnRounded mt={2} click={submit} className="btn">
              <span className=" font-pop-reg">Add Consumable</span>
              <span className="px-3 font-normal text-2xl"></span>
            </BtnRounded>
          </div>
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default SchemeConsumableTable;
