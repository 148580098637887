import React, { useEffect, useState } from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";

import usePouch from "../../../../../../../Hooks/usePouch";

import { toTZS } from "../../../../../../../Util/Currency";

import { convDate } from "../../../../../../../Util/Time";

import TableActions from "./TableActions";

const TRow = ({ doc }) => {
  const [assets, setAsset] = useState("....");
  const [cats, setCats] = useState("....");
  const { fetchById } = usePouch();

  useEffect(() => {
    async function fetchAssets() {
        try {
          let inv = await fetchById({
            db: "inventory_items",
            id: doc.inventory_item_id,
          });
          setAsset(inv);
  
          let cat = await fetchById({
            db: "inventory_item_categories",
            id: inv.category_id,
          });
          setCats(cat.name);
        } catch (e) {
          
        }
      
    }
    fetchAssets();
  }, [fetchById, doc.inventory_item_id]);
  useEffect(()=>{},[doc])
  return (
    <>
      <TableRow key={assets.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.name || assets.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.inventory_item_categories.name || cats} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={` ${doc.qty}`} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={toTZS(doc.inventory_items.unit_cost || assets.unit_cost)} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell >
      <TableActions doc={doc} />
    </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
