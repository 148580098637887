import TableInfo from "../../../../../../../Components/FinalTable/components/TableInfoRow";

import InfoCell from "../../../../../../../Components/FinalTable/components/InfoCell";
import { TablePagination } from "../../../../../../../Components/FinalTable";
import { useContext } from "react";
import SchemesAll from "../context/SchemesAll";

const Infosection = () => {
  const {
    limit,
    setLimit,
    total,
    data,
    page,
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    showPagination,
  } = useContext(SchemesAll);
  return (
    <div className="flex justify-between">
      <TableInfo mt={20}>
        <InfoCell limit={limit} setLimit={setLimit} total={total} docs={data} />
      </TableInfo>
      {showPagination ? (
        <TablePagination
          page={page}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          nextClick={handleNext}
          prevClick={handlePrev}
        />
      ) : null}
    </div>
  );
};

export default Infosection;
