import React, { useState } from "react";

import TeamContext from "./TeamContext";

import { FaUsers } from "react-icons/fa";

import FormScreen from "../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../Components/Label/FormLabel";

import Steppers from "./Components/Steppers";

import Forms from "./Components/Forms";

import { useNavigate } from "react-router-dom";

const AddMember = () => {
  const [step, setStep] = useState(-1);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    consoltation_fee: "0",
    role_id: "",
    title_id: "",
    gender: "",
  });

  const [pic, setPic] = useState(null);
  const [signature, setSignature] = useState(null);

  const navigate = useNavigate();

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  return (
    <TeamContext.Provider
      value={{
        user,
        setUser,
        step,
        setStep,
        pic,
        setPic,
        signature,
        setSignature,
      }}
    >
      <FormScreen backBtn={true} back={handleBack}>
        <FormLabel label="Add User" mt={2} mb={2}>
          <FaUsers />
        </FormLabel>
        <Steppers currentStep={step} close={() => navigate(-1)} />
        <div className="w-full flex flex-row justify-center items-center">
          <div className=" shadow-lg p-6 rounded-2xl" style={{ width: "60%" }}>
            <Forms step={step} setStep={setStep} />
          </div>
        </div>
      </FormScreen>
    </TeamContext.Provider>
  );
};

export default AddMember;
