import React, { useState, useContext, useEffect } from "react";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";

import { Table } from "../../../../../../Components/FinalTable";

import TContext from "./TContext";

import THeader from "./THeader";

import TBody from "./TBody";

import TInfo from "./TInfo";

import SearchInput2 from "../../../../../../Components/Inputs/SearchInput";

import useApp from "../../../../../../Hooks/useApp";

import useAxios from "../../../../../../Hooks/useAxios";
import Fuse from "fuse.js";
import { useNavigate, useParams } from "react-router-dom";

const LiveData = ({ showLive, setShowLive }) => {
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sortOrder, setSordOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [showPagination, setShowPagination] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const { setShowFormModal, setModalMessage, setShowSuccessModal } = useApp();

  const [docus, setDocus] = useState(1);

  const [checked_id, setCheckedId] = useState(-1);

  const request = useAxios();

  useEffect(() => {
    getInitInventory(1, "created_at", "desc");
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      let res = await request({
        method: "GET",
        url: "inventory/search",
        params: {
          value: searchInput,
        },
      });
      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;
      setShowLive(true);

      setData(res);

      setShowPagination(false);
      setIsFiltered(true);
    }

    search();
  }, [searchInput]);

  async function getInitInventory(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc"
  ) {
    let res = await request({
      method: "GET",
      url: "inventory",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitInventory(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitInventory(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(q) {}

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSordOrder(order);
    setSortField(field);
    getInitInventory(1, field, order);
  }

  async function handleClearFilters() {
    setSearchInput("");
    await getInitInventory(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSordOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  async function Submit() {
    if (checked_id === -1) {
      setModalMessage("Select at least one inventory item");
      setShowFormModal(true);
      return;
    }

    let qty = 1;

    for (let doc of data) {
      if (doc.id === checked_id) {
        if (doc.nqty) {
          qty = parseInt(doc.nqty);
        }
        break;
      }
    }

    if (qty < 1) {
      setModalMessage("Qty should be greater than 1");
      setShowFormModal(true);
      return;
    }

    let res = await request({
      method: "POST",
      url: "hospital/investigation/inventory",
      data: {
        investigation_id: parseInt(id),
        inventory_item_id: parseInt(checked_id),
        qty: qty,
      },
    });

    if (res !== "error") {
      setModalMessage("Inventory Item Added");
      setShowSuccessModal(true);
      navigate(`/dashboard/investigations/view/${id}`);
    }
  }

  return (
    <div className=" w-full mt-8">
      <TContext.Provider
        value={{
          showLive,
          limit,
          setLimit,
          data,
          page,
          hasNextPage,
          hasPrevPage,
          handleNext,
          handlePrev,
          handleHeaderQuery,
          handleFilterQuery,
          total: "...",
          setIsFiltered,
          showPagination,
          checked_id,
          setCheckedId,
          docus,
          setDocus,
        }}
      >
        <Table>
          <div className=" w-full flex justify-between mb-2 ">
            <SearchInput2
              placeholder="Search"
              input={searchInput}
              setInput={setSearchInput}
            />
            {isFiltered ? (
              <BtnRounded click={handleClearFilters}>Clear</BtnRounded>
            ) : null}
          </div>
          <THeader />
          <TBody />
          <TInfo />
          <div className="w-full flex justify-end">
            <BtnPlain bg="primary" textColor="white" click={Submit}>
              Complete
            </BtnPlain>
          </div>
        </Table>
      </TContext.Provider>
    </div>
  );
};

export default LiveData;
