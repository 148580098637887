import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../../Components/FinalTable";
import { FiEdit2 } from "react-icons/fi";
import useAxios from "../../../../../../../Hooks/useAxios";
import useApp from "../../../../../../../Hooks/useApp";
import TableContext from "./TContext";

import usePouchCud from "./../../../../../Hooks/usePouchCud";
import { FaRegTrashAlt } from "react-icons/fa";

const TableActions = ({ doc }) => {
  const navigate = useNavigate();
  const request = useAxios();
  const { setShowSuccessModal, setModalMessage } = useApp();
  const {getSpaceInventories} = useContext(TableContext);
  const { deleteDoc } = usePouchCud();

  const handleDelete = async () => {
    let res = await request({
      method: "DELETE",
      url: `store/allocation/${doc.id}`,
    });

    if (res !== "error") {
      setModalMessage("Asset Deleted!");
      setShowSuccessModal(true);
      await deleteDoc({ name: "allocations", doc: res });
      getSpaceInventories()
    }
  };

  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      {/* <ActionBtn next={() => navigate(`view/${doc.id}`)} showNext={false}>
                <div className=" gap-y-2 flex flex-col text-sm"> */}
      {/* <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={() => navigate(`add/fixed`)}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-primary">
                                <FiEdit2 />
                            </p>
                            <p className=" ">Edit Allocation</p>
                        </div>
                    </BtnPlain> */}
      <BtnPlain activeBgColor="white" bg="white" click={handleDelete}>
        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
          <p className="  text-red">
            <FaRegTrashAlt />
          </p>
          <p className=" "></p>
        </div>
      </BtnPlain>
    </div>
    //     </ActionBtn>
    // </div>
  );
};

export default TableActions;
