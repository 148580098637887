import React, { useState } from 'react'

import AllSchemeInventoryItems from './InventoryTableForm/Table'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'

import AddItemsContext from './context/AddItemsContext'

const InventorySchemeTable = () => {
  const [price, setPrice] = useState(0);

  return (
    <AddItemsContext.Provider
      value={{
        price, 
        setPrice,
      }}
    >

    <div className="w-full h-screen bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Inventory Scheme" />
          
        </div>
        <div className='w-[80%] bg-white m-auto'>
          <AllSchemeInventoryItems />
        </div>
    </div>

    </AddItemsContext.Provider>
    
  )
}

export default InventorySchemeTable;