import React, { useEffect, useState } from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";

import usePouch from "../../../../../../../Hooks/usePouch";

import { convDate } from "../../../../../../../Util/Time";

import useApp from "../../../../../../../Hooks/useApp";

import TableActions from "./TableActions";
import { toTZS } from "../../../../../../../Util/Currency";

const TRow = ({ doc }) => {
  const [assets, setAsset] = useState("....");
  const [cats, setCats] = useState("....");
  const { setIsLoading } = useApp();
  const { fetchById } = usePouch();

  useEffect(() => {
    async function fetchAssets() {
      try {
        let inv = await fetchById({
          db: "inventory_items",
          id: doc.inventory_item_id.toString(),
        });
        setAsset(inv);

        let cat = await fetchById({
          db: "inventory_item_categories",
          id: inv.category_id.toString(),
        });
        setCats(cat.name);
      } catch (e) {}
    }
    fetchAssets();
  }, []);
  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.inventory_item_categories.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={` ${doc.qty}`} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={toTZS(doc.inventory_items.unit_cost)} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={convDate(doc.inventory_items.created_at)} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
