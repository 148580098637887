import { useRef, useState } from "react";
import TextInput from "../../../../../../Components/Inputs/TextInput";

import BasicModal from "../../../../../../Components/Modals/BasicModal";
import BtnBlock from "../../../../../../Components/Buttons/BtnBlock";
import { IoMdArrowForward } from "react-icons/io";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import useAxios from "../../../../../../Hooks/useAxios";

import useApp from "../../../../../../Hooks/useApp";

const UpdatePassword = ({ showModal = false, setShowModal, user = {} }) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);
  const [err, setErr] = useState("");

  const request = useAxios();

  const formErrors = useHandleFormError();

  const { setModalMessage, setShowSuccessModal } = useApp();

  async function submit() {
    await setShowError(showError + 1);
    if (formErrors({ input })) return;

    if (input.current.form.password !== input.current.form.confirm_password) {
      setErr("Passwords do not match");
      return;
    }

    let res = await request({
      method: "POST",
      url: "team/update-password",
      data: {
        id: parseInt(user.id),
        password: input.current.form.password,
        confirmPassword: input.current.form.confirm_password,
      },
    });

    if (res === "error") return;

    setErr("");
    setShowModal(false);
    setModalMessage("Password Updated");
    setShowSuccessModal(true);
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className=" font-DM-Bold text-cadet_grey text-xl text-center">
          Update <span className=" text-black">{user.name}</span> password
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="password"
            config={{
              required: true,
              label: "Password",
              placeholder: "Enter Password",
              type: "password",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="confirm_password"
            config={{
              required: true,
              label: "Confirm Password",
              placeholder: "Confirm Password",
              type: "password",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Submit</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>

          <small className="text-red font-DM-Reg mt-4">{err}</small>
        </form>
      </div>
    </BasicModal>
  );
};

export default UpdatePassword;
