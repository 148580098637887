import React from 'react'

import SchemeInvestigateTable from './InvestigateTableForm/Table'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'

const InvestigateTable = () => {
 
  return (
      <div className="w-full h-full bg-ghost_white">
          <div className="w-full bg-ghost_white">
            <BellTopBar label="Add Investigation Scheme" />
            
          </div>
          <div className='w-[70%] mt-10 bg-white m-auto'>
            <SchemeInvestigateTable />
          </div>
      </div>
  
    
  )
}

export default InvestigateTable;