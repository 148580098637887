import { useEffect, useState } from "react";

import { Table } from "../../../../../../Components/FinalTable";

import THeader from "./THeader";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext";

import usePouchPagination from "../../../../../../Hooks/usePouchPagination";
import usePouchCud from "./../../../../Hooks/usePouchCud";

import { TableTopBar } from "../../../../../../Components/FinalTable";

import useFuzzySearch from "../../../../../../Hooks/useFuzzySearch";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";

const PouchData = () => {
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [checked_id, setCheckedId] = useState(-1);
  const [showPagination, setShowPagination] = useState(true);
  const { id } = useParams();
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const { create } = usePouchCud();

  const { setShowFormModal, setModalMessage, setShowSuccessModal } = useApp();

  const [price, setPrice] = useState(0);

  const request = useAxios();

  const navigate = useNavigate();

  const db_name = "investigations";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };
  const searchOptions = {
    keys: ["name"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
  });

  async function handleClearFilters() {
    await setFixed({});
    await resetQuery();

    setIsFiltered(false);
    setSearchInput("");
  }

  async function submit() {
    if (price.length === 0) {
      setModalMessage("Select an Investigation");
      setShowFormModal(true);
      return;
    }
    let res = await request({
      method: "POST",
      url: "hospital/insurance/scheme/investigation",
      data: {
        scheme_id: parseInt(id),
        investigation_id: parseInt(checked_id),
        price: parseInt(price),
      },
    });

    if (res !== "error") {
      await create({ name: "investigations", doc: res });
      setModalMessage("Investigation Scheme Added");
      setShowSuccessModal(true);
      navigate(`/dashboard/schemes/view/${id}`);
    }
  }

  return (
    <TContext.Provider
      value={{
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
        checked_id,
        setCheckedId,
        price,
        setPrice,
      }}
    >
      <div className="p-6">
        <TableTopBar
          mt={2}
          mb={2}
          showAdd={false}
          isFiltered={isFiltered}
          handleClearFilters={handleClearFilters}
          tableIsLoading={tableIsLoading}
          showReport={false}
          showFilter={false}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <Table>
          <THeader />
          <TBody />
          <TInfo />
          <div className="max-w-2xl w-full mx-auto">
            <BtnRounded mt={2} click={submit} className="btn">
              <span className=" font-pop-reg">Add Investigation</span>
              <span className="px-3 font-normal text-2xl"></span>
            </BtnRounded>
          </div>
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default PouchData;
