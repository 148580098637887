/* eslint-disable no-unused-vars */
import { TableTopBar } from "../../../../../Components/FinalTable";

import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import AllInsuranceTable from "./components/AllInsuranceTable";

import AllInsuranceContext from "./context/AllInsuranceContext";

import usePouchListner from "../../../../../Hooks/usePouchListner";

import usePouchPagination from "../../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../../Hooks/useFuzzySearch";

import usePouch from "../../../../../Hooks/usePouch";

import { useNavigate } from "react-router-dom";

import { useState } from "react";

const PouchData = ({showLive}) => {
  const navigate = useNavigate();

  const [openFilter, setOpenFilter] = useState(false);

  const [changes, setChanges] = useState(0);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const { findAll } = usePouch();

  const db_name = "insurance";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["company_name", "name", "description"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
  });
  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <AllInsuranceContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
      }}
    >
      <div className="w-full h-full bg-ghost_white ">
        <BellTopBar label="Insurance" />
        <div className="w-full px-7 pb-8    ">
          <TableTopBar
            FilterClick={() => setOpenFilter(true)}
            mt={2}
            mb={2}
            addLabel="Add Insurance"
            showAdd={true}
            AddClick={() => navigate("add")}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={false}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <AllInsuranceTable />
          {/* <FilterProcedures open={openFilter} setOpen={setOpenFilter} /> */}
        </div>
      </div>
    </AllInsuranceContext.Provider>
  );
};

export default PouchData;
