import React, { useState, useRef, useEffect, useContext } from "react";
import TextInput from "../../../../../../Components/Inputs/TextInput";
import NumberInput from "../../../../../../Components/Inputs/NumberInput";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import SelectInput from "../../../../../../Components/Inputs/SelectInput";

import TeamContext from "../TeamContext";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import usePouch from "../../../../../../Hooks/usePouch";

import useAxios from "../../../../../../Hooks/useAxios";
import { useNavigate } from "react-router-dom";


const FormSection = ({}) => {
  const input = useRef({ form: {}, err: {} });

  const request = useAxios();

  const [roles, setRoles] = useState([]);
  const [titles, setTitles] = useState([]);

  const [showError, setShowError] = useState(0);

  const { user, setUser, setStep } = useContext(TeamContext);

  const { findAll } = usePouch();

  const formErrors = useHandleFormError();

  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      let allRoles = await request({
        method: "GET",
        url: "team/role",
      });

      let allTitles = await request({
        method: "GET",
        url: "team/titles",
      });

      if (allRoles === "error" || allTitles === "error")
      { 
        navigate("/dashboard/team");
        return;
      }


      let roles = [{ label: "Select Role", value: "" }];
      let titles = [{ label: "Select Title", value: "" }];

      allRoles.forEach((role) => {
        roles.push({ label: role.role, value: role.id });
      });

      allTitles.forEach((title) => {
        titles.push({ label: title.name, value: title.id });
      });

      setRoles(roles);
      setTitles(titles);
    };
    init();
  }, []);

  const handleSubmit = async () => {
    await setShowError(showError + 1);
    if (formErrors({ input })) return;

    setUser(input.current.form);

    setStep((c) => c + 1);
  };

  return (
    <div className="w-full">
      <div className="w-full flex gap-x-6">
        <div className=" flex-1">
          <TextInput
            initial={user.name}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "Eg. Jonh Doe",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <TextInput
            initial={user.email}
            input={input}
            showError={showError}
            inputKey="email"
            mt={0.5}
            config={{
              required: true,
              label: "Email",
              placeholder: "Eg. johndoe@mail.com",
              type: "text",
              inputType: "email",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={user.password}
            input={input}
            showError={showError}
            inputKey="password"
            mt={0.5}
            config={{
              required: true,
              label: "Password",
              placeholder: "Enter Password",
              type: "password",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <SelectInput
            showError={showError}
            input={input}
            inputKey="title_id"
            initial={user.title_id}
            mt={0.5}
            config={{
              disabled: false,
              firstDisabled: false,
              required: true,
              items: titles,
              label: "Title",
            }}
          />
        </div>
        <div className=" flex-1 ">
          <NumberInput
            config={{
              disabled: false,
              label: "Enter Phone Number ",
              max: 999999999999,
              min: 10,
              placeholder: "Enter Phone Number",
              required: true,
            }}
            mt={0.5}
            showError={showError}
            initial={user.phone}
            input={input}
            inputKey="phone"
          />

          <NumberInput
            config={{
              disabled: false,
              label: "Consoltation Fee",
              max: 999999999999,
              min: 0,
              placeholder: "",
              required: true,
            }}
            showError={showError}
            mt={0.5}
            initial={user.consoltation_fee}
            input={input}
            inputKey="consoltation_fee"
          />

          <SelectInput
            showError={showError}
            input={input}
            inputKey="role_id"
            initial={user.role_id}
            mt={0.5}
            config={{
              disabled: false,
              firstDisabled: false,
              required: true,
              items: roles,
              label: "Role",
            }}
          />

          <SelectInput
            showError={showError}
            input={input}
            inputKey="gender"
            initial={user.gender}
            mt={0.5}
            config={{
              disabled: false,
              firstDisabled: false,
              required: true,
              items: [
                {
                  label: "Select Gender",
                  value: "",
                },
                {
                  label: "Male",
                  value: "Male",
                },
                {
                  label: "Female",
                  value: "Female",
                },
              ],
              label: "Gender",
            }}
          />
        </div>
      </div>
      <BtnRounded mt={2} click={handleSubmit} className="btn">
        <span className=" font-pop-reg">Continue</span>
      </BtnRounded>
    </div>
  );
};

export default FormSection;
