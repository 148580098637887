import React, { useEffect, useState } from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";

import TableAction from "./TAction";
import { convDate } from "../../../../../../../Util/Time";

const TRow = ({ doc }) => {
 
  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.spaces.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.spaces.no} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.spaces.description} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={`${convDate(doc.created_at)}`} />
        </TableCell>
        <TableCell>
          <TableAction doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
