import React, { useContext, useState } from 'react'

import HeaderBtn from '../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../../Components/FinalTable'
  import AllInsuranceContext from '../context/AllInsuranceContext'
import HeaderBtnSort from '../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort'

const TableHeader = () => {
  const [name, setName] = useState(true);
  const [company_name, setCompanyName] = useState(true);

  const { handleHeaderQuery, showLive, handleFilterQuery, setIsFiltered } =
    useContext(AllInsuranceContext);


  function handleNameSort() {
    if (showLive) {
      handleHeaderQuery("name", name ? "asc" : "desc");
      setName(!name);
      setIsFiltered(true);
      return;
    }
  }

  function handleCompanyNameSort() {
    if (showLive) {
      handleHeaderQuery("company_name", company_name ? "asc" : "desc");
      setCompanyName(!company_name);
      setIsFiltered(true);
      return;
    }
  }

  return (
    <TableHeaderRow>
      <HeaderBtnSort w={90 / 5} label="COMPANY" click={handleCompanyNameSort} />
      <HeaderBtnSort w={90 / 5} label="NAME" click={handleNameSort} />
      <HeaderBtn w={90 / 5} label="DESCRIPTION" />
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default TableHeader