import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import SpaceItem from "./components/SpaceItem";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AssetAllocation from "./components/AssetsAllocations/Table";
import InventoryAllocation from "./components/InventoryAllocations/Table";
import UsersTable from "./components/AssignedUsers/UsersTable";
import useAxios from "../../../../../Hooks/useAxios";
import { navigate } from "@storybook/addon-links";

const Spaces = () => {
  const { id } = useParams();
  const [rom, setRom] = useState({});
  const request = useAxios();

  useEffect(() => {
    async function fetchRoom() {
      let res = await request({
        method: "GET",
        url: `hospital/space/${id}`,
      });

      if (res === "error") {
        navigate(-1);
        return;
      }

      setRom(res);
    }
    fetchRoom();
  }, []);

  return (
    <div className="w-full h-auto bg-ghost_white">
      <BellTopBar label={`${rom.name} Space`} />
      <div className="py-5 my-10 w-full flex align-center justify-center">
        <SpaceItem doc={rom} />
      </div>
      <div className="w-full h-full p-10">
        <UsersTable />
        <AssetAllocation />
        <InventoryAllocation />
      </div>
    </div>
  );
};

export default Spaces;
