import React from 'react'
import HeaderBtn from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtn'
import { TableHeaderRow } from '../../../../../../../Components/FinalTable'

const THead = () => {
  return (
    <div className="w-full">
    <TableHeaderRow>
      <HeaderBtn w={100 / 6} label="TITLE" />
      <HeaderBtn w={100 / 6} label="NAME" />
      <HeaderBtn w={100 / 6} label="ROLE" />
      <HeaderBtn w={100 / 6} label="EMAIL" />
      <HeaderBtn w={100 / 6} label="PHONE" />
      <HeaderBtn w={100 / 6}  />
    </TableHeaderRow>
  </div>
  )
}

export default THead