import React from "react";
import { Table } from "../../../../../../../Components/FinalTable";
import TBody from "./TBody";
import THead from "./THeader";
import TInfo from "./TInfo";
import TableContext from "./TContext";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";

const InventoryInvestigation = ({inv, setInv}) => {

  const navigate = useNavigate();

  return (
    <TableContext.Provider
      value={{
        data: inv,
        setData: setInv,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full">
          <p className="font-DM-Med text-lg uppercase text-BLACK">
            INVENTORY ITEMS INVESTIGATION
          </p>
          <BtnRounded click={() => navigate("add/inventory")}>
            <span className="px-2 font-light text-xl">
              <FaPlus />
            </span>
            <span className=" font-pop-reg">Add Inventory</span>
          </BtnRounded>
        </div>
        <Table>
          <THead />
          <TBody />
          <TInfo />
        </Table>
      </div>
    </TableContext.Provider>
  );
};

export default InventoryInvestigation;
