import React, { useState, useEffect } from "react";

import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import { TableTopBar } from "../../../../../Components/FinalTable";

import AllCash from "./context/AllCash";

import AllCashTable from "./components/AllCashTable";

import { useNavigate } from "react-router-dom";

import useAxios from "../../../../../Hooks/useAxios";

import Fuse from "fuse.js";

const LiveData = ({ showLive, setShowLive }) => {
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);

  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [filters, setFilters] = useState({});
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const request = useAxios();

  const navigate = useNavigate();

  const getInitInventory = async (
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) => {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "hospital/consumables",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
    setTotal(res.total_docs);
  };

  useEffect(() => {
    getInitInventory(1, "created_at", "desc", {});
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "hospital/consumables/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      setData(res);
      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitInventory(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitInventory(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getInitInventory(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitInventory(1, field, order);
  }

  async function handleClearFilters() {
    await getInitInventory(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
    setSearchInput("");
  }

  function handleAdd() {
    navigate("/dashboard/cash/add");
  }

  return (
    <AllCash.Provider
      value={{
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        showLive,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total,
        setTotal,
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="w-full min-h-screen bg-white ">
        <BellTopBar label="Consumables" />
        <div className="w-full px-7 pb-8    ">
          <TableTopBar
            mt={2}
            mb={2}
            showAdd={true}
            AddClick={handleAdd}
            changes={0}
            showFilter={false}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            addLabel="Add Consumable"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <AllCashTable />
        </div>
      </div>
    </AllCash.Provider>
  );
};

export default LiveData;
