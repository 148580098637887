import React from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";
import TableActions from "./TableActions";
import { convDate } from "../../../../../../../Util/Time";
import { toTZS } from "../../../../../../../Util/Currency";

const TRow = ({ doc }) => {
  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={90 / 6}>
          <TableText label={doc.fixed_assets.name} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={doc.fixed_assets.sn} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={doc.fixed_assets.fixed_assets_categories.name} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={` ${doc.qty}`} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={toTZS(doc.fixed_assets.unit_cost)} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
