import React from "react";

import useTeamAll from "../Hooks/useTeamAll";
import TRow from "./TRow";

const TableBody = () => {
  const { data } = useTeamAll();

  return (
    <>
      {data.map((doc) => (
        <TRow key={doc.id || doc._id} doc={doc} />
      ))}
    </>
  );
};

export default TableBody;
