import useApp from "../../../Hooks/useApp";
let params = { sort_field: "created_at", sort_order: "desc", limit: 400 };

const useTables = () => {
  return [
    {
      name: "insurance",
      url: "hospital/insurances",
      params: {
        ...params,
      },
    },
    {
      name: "spaces",
      url: "hospital/spaces",
      params: {
        ...params,
      },
    },
    {
      name: "procedures",
      url: "hospital/procedures",
      params: {
        ...params,
      },
    },
    {
      name: "consumables",
      url: "hospital/consumables",
      params: {
        ...params,
      },
    },
    {
      name: "investigations",
      url: "hospital/investigations",
      params: {
        ...params,
      },
    },
    {
      name: "insurance_scheme",
      url: "hospital/insurance/schemes",
      params: {
        ...params,
      },
    },
    {
      name: "admin_data",
      url: "hospital/analytics/admin",
    },
    {
      name: "team",
      url: "team",
      params: {
        ...params,
      },
    },
    {
      name: "team_roles",
      url: "team/roles",
    },  
  ];
};

export default useTables;
