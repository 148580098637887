import React from 'react'

import ProcedureDetails from './ProcedureDetails'

const Forms = ({ step, setStep }) => {
    if (step === -1) {
        return <ProcedureDetails setStep={setStep} />;
      }
    
      return <ProcedureDetails setStep={setStep} />;
}

export default Forms