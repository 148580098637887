/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import { TableCell, TableText, TableRow } from '../../../../../../../Components/FinalTable'

import {convDate} from '../../../../../../../Util/Time'

import TableActions from './TableActions'

const TRow = ({ doc }) => {
  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={90 / 4}>
          <TableText label={doc.no} />
        </TableCell>
        <TableCell w={90 / 4}>
          <TableText label={doc.name} />
        </TableCell>
        <TableCell w={90 / 4}>
          <TableText label={doc.description} />
        </TableCell>
        <TableCell w={90 / 4}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell >
      <TableActions doc={doc} />
    </TableCell>
      </TableRow>
    </>
  )
}

export default TRow