import { useContext } from 'react'

import AllProceduresContext from '../context/AllProceduresContext'

const useAllProcedures = () => {

    const {
      showLive,
        limit,
        getInitProcedures,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
    } = useContext(AllProceduresContext)

  return {
    showLive,
        limit,
        getInitProcedures,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
  }
}

export default useAllProcedures