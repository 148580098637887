import React, { useEffect, useState } from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";

import usePouch from "../../../../../../../Hooks/usePouch";

import { convDate } from "../../../../../../../Util/Time";

import TableActions from "./TableActions";

const TRow = ({ doc }) => {
  const [space, setSpace] = useState("....");
  const [type, setType] = useState("....");
  const { fetchById } = usePouch();

  useEffect(() => {
    async function Initialize() {
      try {
        let inv = await fetchById({
          db: "spaces",
          id: doc.space_id.toString(),
        });
        setSpace(inv);

        let cat = await fetchById({
          db: "space_types",
          id: inv.type_id.toString(),
        });
        setType(cat.name);
      } catch (e) {}
    }
    Initialize();
  }, []);
  return (
    <>
      <TableRow key={space.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.spaces.name || space.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.spaces.no ||  space.no} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={ doc.spaces.space_types.name || ` ${type}`} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.spaces.description || space.description} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
