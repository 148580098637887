import React, { useState } from "react";

// import "./index.css";

import image1 from "../../Assets/Images/aedrian.jpg";

import InfoSection from "./components/InfoSection";
import FormSection from "./components/FormSection";

const SignIn = () => {
  return (
    <div className=" w-screen h-screen flex flex-row overflow-hidden">
      <div className="flex-1 h-full flex justify-center items-center ">
        <div style={{ width: "70%" }}>
          <InfoSection />
          <FormSection />
        </div>
      </div>
      <div
        className="flex-1 h-full bg-cover"
        style={{ backgroundImage: `url(${image1})` }}
      ></div>
    </div>
  );
};

export default SignIn;
