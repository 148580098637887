import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import TableTopBar from "../../../../../Components/FinalTable/TableTopBar";
import usePouchPagination from "../../../../../Hooks/usePouchPagination";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InvestigationsAll from "./components/context/InvestigationContext";
import InvestigationTable from "./components/Table";

import useFuzzySearch from "../../../../../Hooks/useFuzzySearch";

const All = ({showLive}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [fixed, setFixed] = useState({});
  const [searchInput, setSearchInput] = useState("");

  const db_name = "investigations";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["name"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    handleTab,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
  });

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <InvestigationsAll.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
        handleTab,
      }}
    >
      <div className="w-full h-full bg-ghost_white">
        <BellTopBar label={"Investigations"} />
        <div className="w-full px-7 pb-8">
          <TableTopBar
            showSearch={true}
            showFilter={false}
            showAdd={true}
            showReport={false}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            handleClearFilters={handleClearFilters}
            isFiltered={isFiltered}
            tableIsLoading={tableIsLoading}
            AddClick={() => navigate("/dashboard/investigations/add")}
            addLabel="Add Investigation"
            mt={2}
            px={2}
            mb={2}
          />

          <InvestigationTable />
        </div>
      </div>
    </InvestigationsAll.Provider>
  );
};

export default All;
