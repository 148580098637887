import React, { useState } from "react";
import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import { TableTopBar } from "../../../../../Components/FinalTable";
import AllTeamTable from "./components/AllTeamTable";
import { useNavigate } from "react-router-dom";

import usePouchPagination from "../../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../../Hooks/useFuzzySearch";

import TeamAll from "./Context/TeamAll";

const PouchData = ({ showLive }) => {
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const db_name = "team";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["name", "email", "phone"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
  });

  const navigate = useNavigate();

  function handleAdd() {
    navigate("/dashboard/team/add");
  }

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <TeamAll.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        total,
        setIsFiltered,
        showPagination,
      }}
    >
      <div className="w-full min-h-screen bg-ghost_white ">
        <BellTopBar label="Team" />
        <div className="w-full px-7 pb-8    ">
          <TableTopBar
            mt={2}
            mb={2}
            showAdd={true}
            AddClick={handleAdd}
            showFilter={false}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            addLabel="Add User"
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <AllTeamTable />
        </div>
      </div>
    </TeamAll.Provider>
  );
};

export default PouchData;
