import { useContext } from 'react'

import AllInsuranceContext from '../context/AllInsuranceContext'

const useAllInsurance = () => {

    const {
      showLive,
      getInitInsurance,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
    } = useContext(AllInsuranceContext)

  return {
    showLive,
    getInitInsurance,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
  }
}

export default useAllInsurance