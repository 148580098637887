import React from 'react'

import HeaderBtn from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../../../Components/FinalTable'

const THeader = () => {
  return (
    <TableHeaderRow>
      <HeaderBtn w={90 / 6} label="ITEM NAME"  />
      <HeaderBtn w={90 / 6} label="CATEGORY"  />
      <HeaderBtn w={90 / 6} label="TOTAL QUANTITY" />
      <HeaderBtn w={90 / 6} label="UNIT OF MEASURE" />
      <HeaderBtn w={90 / 6} label="PRICE" />
      
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default THeader