import {  } from '../../../../../../../Components/FinalTable';

import TableHeader from '../../../../../../../Components/FinalTable/components/TableHeader';

import HeaderBtn from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtn';
import HorizontalActionBtn from '../../../../../../../Components/FinalTable/components/Btns/HorizontalActionBtn';


const Header =()=>{
        

    return (
        <TableHeader h={2} mt={10}>
            <HeaderBtn label="NAME" w={90 / 5.5}  />
            <HeaderBtn label="CODE" w={90 / 5.5} />   
            <HeaderBtn label="REGULAR PRICE" w={90 / 5.5} />
            <HeaderBtn label="DATE CREATED" w={90 / 5.5} />
            <div className="flex-1 invisible">
              <HorizontalActionBtn></HorizontalActionBtn>
            </div>         
        </TableHeader>
        
    )
}
export default Header;