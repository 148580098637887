import React from 'react'

import { TableCell, TableText, TableRow } from '../../../../../../../Components/FinalTable'

import { convDate } from '../../../../../../../Util/Time'
import TableActions from './TableActions'

const TRow = ({ doc }) => {


  return (
    <>
      <TableRow key={doc.inventory_items.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.inventory_item_categories.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={` ${doc.qty}`} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.inventory_items.code} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={convDate(doc.inventory_items.created_at)} />
        </TableCell>
        <TableCell>
            <TableActions doc={doc}  />
          </TableCell>
      </TableRow>
    </>
  )
}

export default TRow