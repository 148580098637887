import React, { useContext } from 'react'

import TRow from './TRow'

import TableContext from './TContext'

const TBody = () => {
  const { data } = useContext(TableContext);
  return (
    <>
    {data.map((doc) => <TRow key={doc.team.id || doc.team.id} doc={doc.team} sid={doc.id} />)}
  </>
  )
}

export default TBody