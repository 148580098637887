import React from 'react'

import HeaderBtn from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import { TableHeaderRow } from '../../../../../../../Components/FinalTable'

const THead = () => {
  return (
    <div className="w-full">
    <TableHeaderRow>
      <HeaderBtn w={90 / 5} label="NAME" />
      <HeaderBtn w={90 / 5} label="CATEGORY" />
      <HeaderBtn w={90 / 5} label="UNIT PRICE" />
      <HeaderBtn w={90 / 5} label="PRICE" />
    </TableHeaderRow>
  </div>
  )
}

export default THead