import React, { useContext, useState } from 'react'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  }  from '../../../../../../Components/FinalTable'

  import HeaderBtn from '../../../../../../Components/FinalTable/components/Btns/HeaderBtn'
import HeaderBtnSort from '../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort'
import AllSpacesContext from '../context/AllSpacesContext'


const TableHeader = () => {

  const { handleHeaderQuery, showLive, handleFilterQuery, setIsFiltered } = useContext(AllSpacesContext);
  const [no, setno] = useState(true);
  const [name, setName] = useState(true)
  const [dateCreated, setdateCreated] = useState(true);


  function handleNameSort() {
    if (showLive) {
      handleHeaderQuery("name", name ? "asc" : "desc");
      setName(!name);
      setIsFiltered(true);
      return;
    }
  }

  function handleDateCreatedSort() {
    if (showLive) {
          handleHeaderQuery("created_at", dateCreated ? "asc" : "desc");
          setdateCreated(!dateCreated);
          setIsFiltered(true);
          return;
        }
  }

  function handlenoSort() {

    if (showLive) {
      handleHeaderQuery("no", no ? "asc" : "desc");
      setno(!no);
      setIsFiltered(true);
      return;
    }
  }
  return (
    <TableHeaderRow>
      <HeaderBtnSort w={90 / 5} label="SPACE NAME" click={handleNameSort} />
      <HeaderBtn w={90 / 5} label="SPACE TYPE" />
      <HeaderBtnSort w={90 / 5} label="SPACE NO." click={handlenoSort} />
      <HeaderBtnSort w={90 / 5} label="DATE CREATED" click={handleDateCreatedSort} />
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default TableHeader