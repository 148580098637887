import React, { useRef, useState } from 'react'

import FilterModal from '../../../../../../Components/Modals/FilterModal'

import NumberInput from '../../../../../../Components/Inputs/NumberInput'

import CheckBox from '../../../../../../Components/Inputs/CheckBox'

import useAllProcedures from '../hooks/useAllProcedures'

import usePouch from '../../../../../../Hooks/usePouch'

import useApp from '../../../../../../Hooks/useApp'

import useAxios from '../../../../../../Hooks/useAxios'


const FilterProcedures = ({ open, setOpen }) => {

    const [categories, setCategories] = useState([]);
    const [measures, setMeasures] = useState([]);
  
    const request = useAxios();
  
    const { findAll } = usePouch();
  
    const { setIsFiltered, handleFilterQuery } = useAllProcedures();
  
    const input_initial = {
      form: {
        max_price: "",
        min_price: "",
      },
      err: {},
    };
  
    const input = useRef(input_initial);
  
    const catFilter = useRef({ form: {} });
    const mesFilter = useRef({ form: {} });
  
    const { setIsLoading, setModalMessage, setShowFormModal } = useApp();

    async function applyFilters() {

      let filters = {}
      let to_filter = false
  
  
      if (input.current.form.min_qty !== "") {
        filters.filter_min_quantity =  parseInt(input.current.form.min_qty)
        to_filter = true
        };
     
      if (input.current.form.max_qty !== "") {
        filters.filter_max_quantity =  parseInt(input.current.form.max_qty)
        to_filter = true
      };
  
      if (input.current.form.min_price !== "") {
        filters.filter_min_price =  parseInt(input.current.form.min_price)
        to_filter = true
      };
  
      if (input.current.form.max_price !== "") {
        filters.filter_max_price =  parseInt(input.current.form.max_price)
        to_filter = true
      };
  
      if (to_filter) {
        handleFilterQuery(filters);
        setOpen(false); 
        return;
      }
  
      setOpen(true); 
  
      
    }
      if (!setOpen) return null;
  

  return (
    <FilterModal showFilter={open} setShowFilter={setOpen} apply={applyFilters} >
      <div style={{ width: "320px" }}>
        

        <div className="flex flex-col  gap-y-4">
          <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
            Price Filter
          </h3>
          <div className=" flex gap-x-2" style={{}}>
            <div className="flex-1 ">
              <NumberInput
                input={input}
                inputKey="max_price"
                initial={""}
                config={{
                  required: false,
                  label: "Max Price",
                  min: 0,
                }}
              />
            </div>
            <div className=" flex-1">
              <NumberInput
                input={input}
                inputKey="min_price"
                initial={""}
                config={{
                  required: false,
                  label: "Min Price",
                  min: 0,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </FilterModal>
  )
}

export default FilterProcedures