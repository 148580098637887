import React from "react";

import nurse from "../../../../../../../APP/Assets/Images/hospitalroom.svg";

import { convDate } from "../../../../../../Util/Time";

const SchemeCard = ({ doc }) => {
  return (
    <div className="mt-5 bg-white rounded-lg shadow-md w-[45%] m-auto">
      <img src={nurse} alt="Nurse" className="mx-auto p-5" />
      <div className="flex justify-between">
        <div className="flex flex-col justify-between gap-x-28 p-10 px-20">
          <div className="flex flex-col my-5">
            <h1 className="text-[#98A1B5] font-semibold text-xl">INSURANCE</h1>
            <p className="text-xl">{doc.name}</p>
          </div>
          <div className="flex flex-col">
            <h1 className="text-[#98A1B5] font-semibold text-xl">DATE CREATED</h1>
            <p className="text-xl">{convDate(doc.created_at)}</p>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-x-28 p-10 px-20">
          <div className="flex flex-col my-5">
            <h1 className="text-[#98A1B5] font-semibold text-xl">COMPANY</h1>
            <p className="text-xl">{doc.company_name}</p>
          </div>
          <div className="flex flex-col">
            <h1 className="text-[#98A1B5] font-semibold text-xl">
              DESCRIPTION
            </h1>
            <p className="text-xl w-48 break-words">{doc.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchemeCard;
