import { useNavigate, useParams } from "react-router-dom";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../../Components/FinalTable";

import { FiEdit2 } from "react-icons/fi";

import { useContext } from "react";

import { RiDeleteBinLine } from "react-icons/ri";

import useAxios from "../../../../../../../Hooks/useAxios";
import useApp from "../../../../../../../Hooks/useApp";
import usePouchCud from "./../../../../../Hooks/usePouchCud";
import SchemesAll from "../context/SchemesAll";

const TableAction = ({ doc }) => {
  const navigate = useNavigate();

  const request = useAxios();

  const { deleteDoc } = usePouchCud();

  const { setShowSuccessModal, setModalMessage } = useApp();

  const { data, setData } = useContext(SchemesAll);

  const handleDelete = async () => {
    let res = await request({
      method: "DELETE",
      url: `hospital/insurance/scheme/${doc.id}`,
    });

    if (res !== "error") {
      await deleteDoc({ name: "insurance_scheme", doc: res });
      setModalMessage("Scheme Deleted Successfully");
      setShowSuccessModal(true);
    }

    let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
    setData(newDocs);
  };

  return (
    <div className="flex-1">
      <ActionBtn next={() => navigate(`view/${doc.id}`)}>
        <div className=" gap-y-2 flex flex-col text-sm">
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => navigate(`edit/${doc.id}`)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="text-gray-900">
                <FiEdit2 />
              </p>
              <p className=" ">Edit Scheme</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => handleDelete()}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="text-red">
                <RiDeleteBinLine />
              </p>
              <p className="text-red">Delete scheme</p>
            </div>
          </BtnPlain>
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableAction;
