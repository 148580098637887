import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import TableTopBar from "../../../../../Components/FinalTable/TableTopBar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InvestigationsAll from "./components/context/InvestigationContext";
import InvestigationTable from "./components/Table";
import useAxios from "../../../../../Hooks/useAxios";

const All = ({ showLive, setShowLive }) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [showPagination, setShowPagination] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const request = useAxios();

  useEffect(() => {
    getInvestigations(1, "created_at", "desc");
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "hospital/investigations/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      setData(res);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function getInvestigations(page = 1) {
    setTableIsLoading(true);

    let res = await request({
      method: "GET",
      url: "hospital/investigations",
      params: {
        page,
        limit,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInvestigations(page + 1);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInvestigations(page - 1);
    }
  }

  async function handleClearFilters() {
    setSearchInput("");
    await getInvestigations(1, "created_at", "desc");
    setPage(1);
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <InvestigationsAll.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="w-full h-full bg-ghost_white">
        <BellTopBar label={"Investigations"} />
        <div className="w-full px-7 pb-8">
          <TableTopBar
            showSearch={true}
            showFilter={false}
            showAdd={true}
            showReport={false}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            handleClearFilters={handleClearFilters}
            isFiltered={isFiltered}
            tableIsLoading={tableIsLoading}
            AddClick={() => navigate("/dashboard/investigations/add")}
            addLabel="Add Investigation"
            mt={2}
            px={2}
            mb={2}
          />

          <InvestigationTable />
        </div>
      </div>
    </InvestigationsAll.Provider>
  );
};

export default All;
