import { useState, useRef } from "react";

import FormScreen from '../../../../../Components/Templates/FormScreen'

import FormLabel from '../../../../../Components/Label/FormLabel'

import AddSteppers from "./components/Stepper";

import Forms from "./components/Forms";

import AddInvestigation from "./context/AddInvestigation";

import { AiOutlinePlusCircle } from "react-icons/ai";

import { useNavigate } from "react-router-dom";

const Add = () => {

  const [step, setStep] = useState(-1);

  const navigate = useNavigate()

  const [investigation, setInvestigation] = useState({
    name: "",
    code: '',
    description: "",
    regular_price: "",
  });


  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }


  return (
    <AddInvestigation.Provider
      value={{
        investigation,
        setInvestigation,
        
      }}
    >
      <FormScreen backBtn={false} back={handleBack}>
        <FormLabel label="Create Investigation" mt={2} mb={2}>
          <AiOutlinePlusCircle />
        </FormLabel>
        <AddSteppers currentStep={step} close={() => {navigate('../')}} />
        <div className="w-full flex flex-row justify-center items-center">
          <Forms step={step} setStep={setStep} />
        </div>
      </FormScreen>
    </AddInvestigation.Provider>
  );
};

export default Add;
