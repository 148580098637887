import React, { useEffect, useState } from "react";

import Image from "../../../../../../Assets/Images/User_Img.png";

import sign from "../../../../../../Assets/Images/signature.jpg";

import usePouch from "../../../../../../Hooks/usePouch";

import { useNavigate, useParams } from "react-router-dom";

import useAxios from "../../../../../../Hooks/useAxios";


const ProfileCard = () => {
  const { fetchById } = usePouch();
  const [user, setUser] = useState({});
  const [signature, setSignature] = useState({});
  const [profile, setProfile] = useState({});
  const { id } = useParams();

  const request = useAxios();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProcedure() {
      try {
        let res = await request({
        method: "GET",
        url: `team/${id}`,
        });

        if(res === "error"){
          navigate("/dashboard/team");
        };
        
        setUser(res);
        let sig = await fetchById({
          db: "pics",
          id: res.signature_id.toString(),
        });

        setSignature(res.pics_team_signature_idTopics);
        setProfile(res.pics_team_avatar_idTopics);
      } catch (e) {
      } finally {
      }
    }
    fetchProcedure();
  }, []);

  return (
    <div className="mx-auto w-6/12 my-10 bg-white rounded-lg">
      {profile.pic ? (
        <div className="bg-ghost_white h-56 w-56 rounded-full mx-auto my-14 bg-auto">
          <img
            src={`data:image/jpeg;base64,${profile.pic}`}
            className="m-auto  h-48 w-48  rounded-full bg-auto bg-no-repeat bg-center "
            alt=""
          />{" "}
        </div>
      ) : (
        <div className="bg-ghost_white h-56 w-56 rounded-full mx-auto my-14 bg-no-repeat bg-center ">
          <img
            src={Image}
            className="m-auto h-48 w-48  rounded-full bg-cover bg-no-repeat bg-center "
            alt=""
          />{" "}
        </div>
      )}
      <div className="flex justify-around">
        <div className="flex flex-col">
          <div className=" my-4">
            <h2 className="text-lg font-bold text-cadet_grey">NAME</h2>
            <h3 className="text-lg font-semibold text-gray-700">{user.name}</h3>
          </div>
          <div className=" my-4">
            <h2 className="text-lg font-bold text-cadet_grey">EMAIL</h2>
            <h3 className="text-lg font-semibold text-gray-700">
              {user.email}
            </h3>
          </div>
          <div className=" my-4">
            <h2 className="text-lg font-bold text-cadet_grey">PHONE</h2>
            <h3 className="text-lg font-semibold text-gray-700">
              {user.phone}
            </h3>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="bg-ghost_white w-56 h-40">
            {signature.pic ? (
              <img
                src={`data:image/jpeg;base64,${signature.pic}`}
                className="mx-auto my-auto"
                alt=""
              />
            ) : (
              <img src={sign} className="mx-auto my-auto " alt="" />
            )}
          </div>
          <h2 className="text-lg font-bold text-cadet_grey my-4 ml-6">
            SIGNATURE
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
