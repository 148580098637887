import { useEffect, useState } from "react";

import { Table } from "../../../../../../Components/FinalTable";

import THeader from "./THeader";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext";

import usePouchPagination from "../../../../../../Hooks/usePouchPagination";

import { TableTopBar } from "../../../../../../Components/FinalTable";

import useFuzzySearch from "../../../../../../Hooks/useFuzzySearch";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import { useNavigate, useParams } from "react-router-dom";

import useApp from "../../../../../../Hooks/useApp";

import useAxios from "../../../../../../Hooks/useAxios";

import usePouchCud from "./../../../../Hooks/usePouchCud";
import Fuse from "fuse.js";

const LiveData = ({showLive, setShowLive}) => {
    const navigate = useNavigate();
    const [docs, setDocs] = useState(0);
    const [openFilter, setOpenFilter] = useState(false);
    const [checked_id, setCheckedId] = useState(-1);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortField, setSortField] = useState("created_at");
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);
    const [data, setData] = useState([]);
    const [showPagination, setShowPagination] = useState(true);
    const [filters, setFilters] = useState({});
    const { setShowFormModal, setModalMessage, setShowSuccessModal } = useApp();
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const {id} = useParams()
    const [searchInput, setSearchInput] = useState("");
  
    const request = useAxios();

  useEffect(() => {
    getInitFixedItems(1, "created_at", "desc", filters);
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "fixed/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      const fuse = new Fuse(
        [...res.fixed_assets, ...res.serial_numbers],
        {
          keys: ["name", "sn"],
        }
      );

      const result = fuse.search(searchInput);

      let ids = [];
      let new_docs = [];

      for (let i = 0; i < result.length; i++) {
        if (i === limit) {
          break;
        }
        let doc = result[i].item;
        if (ids.includes(doc.id)) {
          continue;
        }
        ids.push(doc.id);
        new_docs.push(doc);
      }
      setData(new_docs);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function getInitFixedItems(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "fixed",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }
  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitFixedItems(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitFixedItems(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    getInitFixedItems(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getInitFixedItems(1, field, order);
  }

  async function handleClearFilters() {
    await getInitFixedItems(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }
  async function Submit() {
    if (docs.length === 0) {
      setModalMessage("Select at least one Fixed Asset");
      setShowFormModal(true);
      return;
    }
    let res = await request({
      method: "POST",
      url: "hospital/procedure/fixed",
      data: {
        procedure_id: parseInt(id),
        fixed_asset_id: parseInt(checked_id),
        qty: 1,
      },
    });

    if (res !== "error") {
      setModalMessage("Fixed Item Added");
      setShowSuccessModal(true);
      navigate(`/dashboard/procedure/view/${id}`);
    }
  }


  return (
    <TContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
        checked_id,
        setCheckedId,
        docs,
        setDocs,
      }}
    >
      <div className="px-7 pb-8">
        <TableTopBar
          mt={2}
          mb={2}
          showAdd={false}
          // AddClick={handleAdd}

          isFiltered={isFiltered}
          handleClearFilters={handleClearFilters}
          tableIsLoading={tableIsLoading}
          showReport={false}
          showFilter={false}
          // addLabel="Add"
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <Table>
          <THeader />
          <TBody />
          <TInfo />
          <div className="max-w-2xl w-full mx-auto">
            <BtnRounded mt={2} click={Submit} className="btn">
              <span className=" font-pop-reg">Add Item</span>
              <span className="px-3 font-normal text-2xl"></span>
            </BtnRounded>
          </div>
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default LiveData;
