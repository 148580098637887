import React from 'react'

import SchemeDetails from './SchemeDetails'


const Forms = ({ step, setStep }) => {
    if (step === -1) {
        return <SchemeDetails setStep={setStep} />;
      }
    return <SchemeDetails setStep={setStep} />;
}

export default Forms