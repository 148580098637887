import { useContext } from 'react'

import AddProcedure from '../context/addProcedure'

const useAddProcedure = () => {
    const {
        procedure,
        setProcedure,
        complete,
        setComplete,
        items,
        setItems,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
      } = useContext(AddProcedure);

  return {
        procedure,
        setProcedure,
        complete,
        items,
        setItems,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
  };
}

export default useAddProcedure