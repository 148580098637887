import React, { useRef, useState, useEffect, useContext } from "react";
import user_signature from "./../../../../../../Assets/Images/signature.jpg";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import { BsFillPlusCircleFill } from "react-icons/bs";

import useApp from "../../../../../../Hooks/useApp";

import TeamContext from "../TeamContext";

import useAxios from "../../../../../../Hooks/useAxios";

import { useNavigate } from "react-router-dom";

import usePouchCud from "../../../../Hooks/usePouchCud";

const SelectSignature = () => {
  const [files, setFiles] = useState(null);
  const [submited, setSubmited] = useState(null);
  const [image, setImage] = useState(null);

  const { user, pic, signature, setSignature } = useContext(TeamContext);

  const { create } = usePouchCud();

  const doc = useRef();

  // const { setModalMessage, setShowFormModal } = useApp();

  const navigate = useNavigate();

  const request = useAxios();

  function handleAdd() {
    doc.current.click();
    setSubmited(true);
  }

  useEffect(() => {
    if (files === null || submited === false) return;

    async function addDocument() {
      setImage(URL.createObjectURL(files[0]));
      setSignature(files[0]);
    }
    addDocument();
  }, [submited, files]);

  useEffect(() => {
    if (signature === null) return;
    setImage(URL.createObjectURL(signature));
  }, []);

  async function handleSubmit() {
    let formData = new FormData();
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("phone", user.phone);
    formData.append("password", user.password);
    formData.append("title_id", user.title_id);
    formData.append("role_id", user.role_id);
    formData.append("gender", user.gender);
    formData.append("consoltation_fee", parseInt(user.consoltation_fee));

    if (signature !== null) {
      formData.append("signature", signature);
    }

    if (pic !== null) {
      formData.append("avatar", pic);
    }

    let res = await request({
      method: "POST",
      url: "team",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });

    if (res === "error") return;
    await create({ name: "team", res });
    navigate("../");
  }

  return (
    <div>
      <div className="w-full h-full flex justify-center items-center">
        <div className=" bg-bright_grey p-8 rounded-2xl relative">
          <img
            src={image ? image : user_signature}
            alt="user"
            style={{ maxWidth: "350px", maxHeight: "350px" }}
          />
          <span
            onClick={handleAdd}
            className=" text-5xl cursor-pointer absolute right-3 bottom-2 shadow-2xl bg-transparent rounded-full z-50 text-primary"
          >
            <BsFillPlusCircleFill />
          </span>
        </div>
        <input
          className=" hidden"
          ref={doc}
          type="file"
          id="myFile"
          name="filename"
          accept="image/png, image/jpeg"
          onChange={(e) => setFiles(e.target.files)}
        />
      </div>
      <BtnRounded mt={2} click={handleSubmit} className="btn">
        <span className=" font-pop-reg">Continue</span>
      </BtnRounded>
    </div>
  );
};

export default SelectSignature;
