import { useEffect, useRef, useState } from "react";

import TextArea from "../../../../../../Components/Inputs/TextArea";

import TextInput from "../../../../../../Components/Inputs/TextInput";

import NumberInput from "../../../../../../Components/Inputs/NumberInput";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import useAddProcedure from "../hooks/useAddProcedure";

import useAxios from "../../../../../../Hooks/useAxios";

import useApp from "../../../../../../Hooks/useApp";

import { useNavigate, useParams } from "react-router-dom";

import usePouch from "../../../../../../Hooks/usePouch";

import SelectInput from "../../../../../../Components/Inputs/SelectInput";

const ProcedureDetails = ({ setStep }) => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);

  const formErrors = useHandleFormError();

  const { procedure, complete } = useAddProcedure();

  const { findAll } = usePouch();

  const [rooms, setRooms] = useState([]);

  const { setShowSuccessModal, setModalMessage } = useApp();

  const navigate = useNavigate();

  const request = useAxios();

  const { id } = useParams();

  const handleSubmit = async () => {
    setShowError(showError + 1);

    if (formErrors({ input })) return;

    let res = await request({
      method: "PUT",
      url: "hospital/procedure",
      data: {
        ...input.current.form,
        id: parseInt(id),
        regular_price: parseInt(input.current.form.regular_price),
      },
    });

    if (res !== "error") {
      setModalMessage("Procedure Updated");
      setShowSuccessModal(true);
      navigate("../");
    }
  };

  if (complete === false) {
    return;
  }

  return (
    <div
      className="flex flex-col  bg-white rounded-2xl p-10 "
      style={{ width: "70%" }}
    >
      <div className="flex w-full flex-col md:flex-row gap-x-4">
        {/* Left */}
        <div className="flex w-full md:w-full flex-col ">
          <TextInput
            initial={procedure.name || ""}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "Eg. XRAY",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={procedure.code || ""}
            input={input}
            showError={showError}
            inputKey="code"
            mt={0.5}
            config={{
              required: true,
              label: "Code",
              placeholder: "Eg. IEG6",
              maxChar: 250,
              minChar: 2,
            }}
          />

          <NumberInput
            initial={procedure.regular_price || ""}
            input={input}
            showError={showError}
            inputKey="regular_price"
            mt={0.5}
            config={{
              required: true,
              label: "Regular Price",
              placeholder: "Enter regular price ",
              maxChar: 13,
              minChar: 6,
            }}
          />

          <TextArea
            initial={procedure.description || ""}
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: true,
              label: "Description",
              placeholder: "Procedure description",
              maxChar: 250,
              minChar: 4,
            }}
          />
        </div>
      </div>
      <div className="max-w-2xl w-full mx-auto">
        <BtnRounded mt={2} click={handleSubmit} className="btn">
          <span className=" font-pop-reg">Submit</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnRounded>
      </div>
    </div>
  );
};

export default ProcedureDetails;
