import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../Components/FinalTable";

import { FiEdit2 } from "react-icons/fi";
import { MdPassword } from "react-icons/md";
import { FaUserSlash, FaUser } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";

import UpdatePassModal from "./UpdatePassModal";
import UpdatePassword from "./UpdatePassModal";

import useAxios from "../../../../../../Hooks/useAxios";

import useTeamAll from "../Hooks/useTeamAll";

const TableAction = ({ doc }) => {
  const [user, setUser] = useState(doc);

  const [showPassModal, setShowPassModal] = useState(false);

  const { data, setData } = useTeamAll();

  const request = useAxios();

  const navigate = useNavigate();

  async function handleActivation() {
    let res = await request({
      method: "PUT",
      url: "team/activate",
      data: {
        id: doc.id,
        is_active: !doc.is_active,
      },
    });

    if (res === "error") return;

    let newDocs = [];

    data.forEach((row) =>
      row.id === doc.id ? newDocs.push(res) : newDocs.push(row)
    );

    setData(newDocs);
    setUser(res);
  }

  async function handleDelete() {
    let res = await request({
      method: "DELETE",
      url: `team/${doc.id}`,
    });

    if (res === "error") return;

    let newDocs = data.filter((row) => (row.id === doc.id ? false : true));

    setData(newDocs);
  }

  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn showNext={true} next={() => navigate(`view/${user.id}`)}>
        <div className=" gap-y-2 flex flex-col text-sm">
          <BtnPlain
            click={() => navigate(`edit/${user.id}`)}
            activeBgColor="white"
            bg="white"
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-black">
                <FiEdit2 />
              </p>
              <p className=" ">Edit User</p>
            </div>
          </BtnPlain>
          <BtnPlain
            click={() => setShowPassModal(true)}
            activeBgColor="white"
            bg="white"
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer text-primary">
              <p className="  text-black">
                <MdPassword />
              </p>
              <p className=" ">Update Password</p>
            </div>
          </BtnPlain>
          <BtnPlain click={handleActivation} activeBgColor="white" bg="white">
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-black">
                {user.is_active ? <FaUserSlash /> : <FaUser />}
              </p>
              <p className=" ">
                {user.is_active ? "deactivate" : "activate"} User
              </p>
            </div>
          </BtnPlain>
          <BtnPlain activeBgColor="white" bg="white" click={handleDelete}>
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className=" text-red">
                <RiDeleteBinLine />
              </p>
              <p className=" text-red">Delete User</p>
            </div>
          </BtnPlain>
        </div>
        <UpdatePassword
          showModal={showPassModal}
          setShowModal={setShowPassModal}
          user={doc}
        />
      </ActionBtn>
    </div>
  );
};

export default TableAction;
