import React, { useEffect, useState } from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../../Util/Time";
import TableActions from "./TableActions";

import { toTZS } from "../../../../../../../Util/Currency";

const TRow = ({ doc }) => {

  return (
    <>
      <TableRow key={doc.fixed_assets.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.fixed_assets.sn} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.fixed_assets.fixed_assets_categories.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={` ${doc.fixed_assets.name}`} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={toTZS(doc.fixed_assets.unit_cost)} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={convDate(doc.fixed_assets.created_at)} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
