import React from 'react'
import BellTopBar from "../../../../../Components/Bars/BellTopBar"
import SchemeProcedureTable from "./ProceedTableForm/Table"

const ProcedureTable = () => {
  return (
    <div className="w-full h-full bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Procedure Scheme" />
          
        </div>
        <div className='w-[70%] mt-10  bg-white m-auto'>
          <SchemeProcedureTable />
        </div>
    </div>
  )
}

export default ProcedureTable;