import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import TableTopBar from "../../../../../Components/FinalTable/TableTopBar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SchemesAll from "./components/context/SchemesAll";
import SchemesTable from "./components/Table";
import useAxios from "../../../../../Hooks/useAxios";

import Fuse from "fuse.js";

const LiveData = ({ showLive, setShowLive }) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [sortValue, setSortValue] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const request = useAxios();

  useEffect(() => {
    getOrders(1, "created_at", "desc");
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "hospital/insurance/scheme/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "network-error") {
        setShowLive(false);
        return;
      }

      if (res === "error") return;

      const fuse = new Fuse([...res.name, ...res.no], {
        keys: ["name", "no"],
      });

      const result = fuse.search(searchInput);

      let new_docs = [];

      let ids = [];

      for (let i = 0; i < result.length; i++) {
        if (ids.includes(result[i].item.id)) {
          continue;
        }
        ids.push(result[i].item.id);

        new_docs.push(result[i].item);
        if (i === limit) {
          break;
        }
      }

      setData(new_docs);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function getOrders(
    page = 1,
    sort_field = "created_at",
    sort_value = "desc"
  ) {
    setTableIsLoading(true);

    let res = await request({
      method: "GET",
      url: "hospital/insurance/schemes",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_value: sort_value,
      },
      network_error: false,
    });

    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    setTableIsLoading(false);

    if (res === "error") return;

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getOrders(page + 1, sortField, sortValue);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getOrders(page - 1, sortField, sortValue);
    }
  }

  async function handleFilterQuery() {
    setSortField("created_at");
    setSortValue("desc");
    setPage(1);
    getOrders(1, "created_at", "desc");

    setIsFiltered(true);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortValue(order);
    setSortField(field);
    getOrders(1, field, order);
  }

  async function handleClearFilters() {
    setSearchInput("");
    await getOrders(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSortValue("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <SchemesAll.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="w-full h-full bg-ghost_white">
        <BellTopBar label={"Schemes"} />
        <div className="w-full px-6 pb-8">
          <TableTopBar
            showSearch={true}
            showFilter={false}
            showAdd={true}
            showReport={false}
            handleClearFilters={handleClearFilters}
            isFiltered={isFiltered}
            tableIsLoading={tableIsLoading}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            AddClick={() => navigate("/dashboard/schemes/add")}
            addLabel="Add Scheme"
            mt={2}
            px={2}
            mb={2}
          />

          <SchemesTable />
        </div>
      </div>
    </SchemesAll.Provider>
  );
};

export default LiveData;
