import { useState, useRef, useEffect } from "react";

import FormScreen from "../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../Components/Label/FormLabel";

import AddSteppers from "./components/Stepper";

import Forms from "./components/Forms";

import AddProcedure from "./context/addProcedure";

import { FaRegSun } from "react-icons/fa";

import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../Hooks/useAxios";

const Add = () => {
  const [step, setStep] = useState(-1);

  const { id } = useParams();
  const request = useAxios();
  const navigate = useNavigate();

  const [procedure, setProcedure] = useState({
    name: "",
    code: "",
    description: "",
    regular_price: null,
  });

  const [items, setItems] = useState({
    name: "",
    email: "",
    phone: null,
  });

  const [complete, setComplete] = useState(false);

  useEffect(() => {
    async function fetchProcedure() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/procedure/${id}`,
        });

        if (res === "error") {
          navigate("../");
        }

        setProcedure(res);
        setComplete(true);
      } catch (e) {
      } finally {
      }
    }
    fetchProcedure();
  }, []);
  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  return (
    <AddProcedure.Provider
      value={{
        procedure,
        setProcedure,
        complete,
        setComplete,
        items,
        setItems,
      }}
    >
      <FormScreen backBtn={false} back={handleBack}>
        <FormLabel label="Edit Procedure" mt={2} mb={2}>
          <FaRegSun />
        </FormLabel>
        <AddSteppers
          currentStep={step}
          close={() => {
            navigate("../");
          }}
        />
        <div className="w-full flex flex-row justify-center items-center">
          <Forms step={step} setStep={setStep} />
        </div>
      </FormScreen>
    </AddProcedure.Provider>
  );
};

export default Add;
