import { useEffect, useState } from "react";
import Doctors from "./components/Doctors";
import LabTechs from "./components/LabTechs";
import MainBar from "./components/MainBar";
import Navbar from "./components/Navbar";
import Nurses from "./components/Nurses";
import PatientsChart from "./components/PatientsChart";
import Pharmasists from "./components/Pharmasists";
import RecentRequests from "./components/RecentRequests";
import Requests from "./components/Requests";
import Visits from "./components/Visits";
import useApp from "../../../../Hooks/useApp";
import useAxios from "../../../../Hooks/useAxios";
import RecentAllocations from "./components/RecentAllocations";

import usePouch from "../../../../Hooks/usePouch";

const Summary = () => {
  const [pharmasists, setPharmasists] = useState(0);
  const [doctors, setDoctors] = useState(0);
  const [nurses, setNurses] = useState(0);
  const [labtechs, setLabtechs] = useState(0);
  const [requests, setRequests] = useState("");
  const [recentAllocations, setRecentAllocations] = useState([]);
  const [recentRequests, setRecentRequests] = useState([]);
  const [wVisits, setWVisits] = useState([]);
  const [mVisits, setMVisits] = useState([]);

  const { setShowRequestModal, setModalType, setModalMessage, setIsOnline } =
    useApp();

  const request = useAxios();

  const { findAll2, findAll } = usePouch();

  async function getInitialData() {
    let res = await request({
      method: "GET",
      url: "hospital/analytics/admin",
      network_error: false,
    });

    if (res === "network-error") {
      let other = await findAll2({ db: "admin_data", index: ["_id"] });
      if (other.length === 0) return;
      res = other[0];
    }

    setRecentAllocations(res.recent_allocations);
    setRecentRequests(res.recent_requests);
    setRequests(res.requests);
    setDoctors(res.doctors);
    setNurses(res.nurses);
    setPharmasists(res.pharmarcists);
    setLabtechs(res.lab_technicians);
    setWVisits(res.weekly_visits);
    setMVisits(res.monthly_visits);
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className=" w-full h-full bg-ghost_white">
      <div className=" px-10">
        <Navbar />
      </div>
      <div className="py-10 px-10 bg-ghost_white w-full">
        <MainBar />
      </div>
      <div className="flex justify-between gap-x-5 p-10 w-full bg-ghost_white">
        <div className="w-7/12">
          <PatientsChart mVisits={mVisits} />
        </div>
        <div className="w-4/12 mx-auto">
          <Visits wVisits={wVisits} />
        </div>
      </div>
      <div className="flex justify-between lg:gap-x-5 md:gap-x-5  pt-8 px-10 py-20 bg-ghost_white">
        <Pharmasists pharmasists={pharmasists} />
        <Doctors doctors={doctors} />
        <Nurses nurses={nurses} />
        <LabTechs lab_technicians={labtechs} />
      </div>
      <div className="flex justify-between lg:gap-x-5 md:gap-x-5 mb-10  px-10 py-10 bg-ghost_white">
        <RecentRequests recentRequests={recentRequests} />
        <RecentAllocations recentAllocations={recentAllocations} />
        <Requests requests={requests} />
      </div>
    </div>
  );
};

export default Summary;
