import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import { TableTopBar } from "../../../../../Components/FinalTable";
import AllRooms from "./components/Table";
import { useNavigate } from "react-router-dom";
import usePouchPagination from "../../../../../Hooks/usePouchPagination";
import AllSpacesContext from "./context/AllSpacesContext";
import useFuzzySearch from "../../../../../Hooks/useFuzzySearch";
import { useState } from "react";

const PouchData = ({ showLive }) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  // const { findAll } = usePouch();

  const db_name = "spaces";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };
  const searchOptions = {
    keys: ["name", "no"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };
  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
  });
  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
  });

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <AllSpacesContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        handleClearFilters,
        isFiltered,
        setIsFiltered,
        total,
        showPagination,
        resetQuery,
      }}
    >
      <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Spaces" />
        </div>
        <div className="w-full px-7 pb-8">
          <TableTopBar
            mt={2}
            mb={2}
            addLabel="Add"
            showAdd={true}
            AddClick={() => navigate("/dashboard/spaces/add")}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={false}
            setSearch={true}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <AllRooms />
        </div>
      </div>
    </AllSpacesContext.Provider>
  );
};

export default PouchData;
