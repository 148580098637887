import UserDetails from "./UserDetails";

import SelectPic from "./SelectPic";

import SelectSignature from "./SelectSignature";

const Forms = ({ step }) => {
  if (step === -1) {
    return <UserDetails />;
  }

  if (step === 0) {
    return <SelectPic />;
  }

  if (step === 1) {
    return <SelectSignature />;
  }

  return null;
};

export default Forms;
