import React from 'react'

import HeaderBtn from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import { TableHeaderRow } from '../../../../../../../Components/FinalTable'

const THead = () => {
  return (
    <div className="w-full">
    <TableHeaderRow>
    <HeaderBtn w={90 / 6} label="NAME" />
      <HeaderBtn w={90 / 6} label="SERIAL NO." />
      <HeaderBtn w={90 / 6} label="CATEGORY" />
      <HeaderBtn w={90 / 6} label="QUANTITY" />
      <HeaderBtn w={90 / 6} label="UNIT COST" />
      <HeaderBtn w={90 / 6} label="DATE ASSIGNED" />
    </TableHeaderRow>
  </div>
  )
}

export default THead