import React, { useState } from 'react'

import HeaderBtnSort from '../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort'

import HeaderBtn from '../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

import useAllCash from '../hooks/useAllCash'

import usePouch from '../../../../../../Hooks/usePouch'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../../Components/FinalTable'

const TableHeader = () => {
  const [name, setName] = useState(true);
  const [unitOfMeasure, setUnitOfMeasure] = useState(true);
  const [created_at, setCdate] = useState(true);
  const [price, setPrice] = useState(true);
  const [category, setCategory] = useState(true);
  const { handleHeaderQuery, handleFilterQuery, setIsFiltered, showLive } = useAllCash();
  const { findAll } = usePouch(); 


  function handleNameSort() {
    if (showLive) {
      handleHeaderQuery("inventory_name", name ? "asc" : "desc");
      setName(!name);
      setIsFiltered(true);
      return;
    }
    setIsFiltered(false);
    const index = ["name"];
    const sort = [{ name: name ? "asc" : "desc" }];
    const selector = { name: { $gte: null } };

    setName(!name);

    handleHeaderQuery(index, sort, selector);
  }
  

  function handleDateSort() {
    if (showLive) {
      handleHeaderQuery("created_at", created_at ? "asc" : "desc");
      setCdate(!created_at);
      setIsFiltered(true);
      return;
    }
    setIsFiltered(false);
    const index = ["created_at"];
    const sort = [{ created_at: "desc"}];
    const selector = { created_at: { $gte: null } };

    setCdate(!created_at);

    handleHeaderQuery(index, sort, selector);
  }


  function handlePriceSort() {
    if (showLive) {
      handleHeaderQuery("price", price ? "asc" : "desc");
      setPrice(!price);
      setIsFiltered(true);
      return;
    }
    setIsFiltered(false);
    const index = ["price"];
    const sort = [{ price: price ? "asc" : "desc"}];
    const selector = { price: { $gte: null } };

    setPrice(!price);

    handleHeaderQuery(index, sort, selector);
  }


  return (
    <TableHeaderRow>
      <HeaderBtnSort w={90 / 5} label="INVENTORY NAME"  click={handleNameSort}/>
      <HeaderBtnSort w={90 / 5} label="PRICE" click={handlePriceSort} />
      <HeaderBtnSort w={90 / 5} label="CATEGORY"  />
      <HeaderBtnSort w={90 / 5} label="UNIT OF MEASURE" />
      <HeaderBtnSort w={90 / 5} label="DATE CREATED" click={handleDateSort} />
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default TableHeader