import React, { useRef, useState, useEffect } from "react";

import TextInput from "../../../../../../Components/Inputs/TextInput";

import SelectInput from "../../../../../../Components/Inputs/SelectInput";

import useAxios from "./../../../../../../Hooks/useAxios";

import useApp from "./../../../../../../Hooks/useApp";

import { useNavigate } from "react-router-dom";

import BtnBlock from "../../../../../../Components/Buttons/BtnBlock";

import { FaLongArrowAltRight } from "react-icons/fa";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import usePouch from "../../../../../../Hooks/usePouch";

import usePouchCud from "./../../../../Hooks/usePouchCud";

const Form = () => {
  const input = useRef({ form: {}, err: {} });
  const { findAll } = usePouch();
  const [showError, setShowError] = useState(0);
  const [roomType, setRoomType] = useState([]);
  const [userInput, setUserInput] = useState([]);

  const { create } = usePouchCud();

  const request = useAxios();

  const { setShowSuccessModal, setModalMessage } = useApp();

  const navigate = useNavigate();

  const formErrors = useHandleFormError();

  useEffect(() => {
    async function Initialize() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/space/types`,
        });
        let tp = [{ value: "", label: "Select Space Type" }];
        for (let i = 0; i < res.length; i++) {
          let cat = res[i];
          tp.push({
            value: cat.id,
            label: cat.name,
          });
        }
        setRoomType(tp);
      } catch (e) {}
    }
    Initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    setUserInput(input.current.form);

    setShowError(showError + 1);

    if (formErrors({ input })) return;

    let res = await request({
      method: "POST",
      url: "hospital/space",
      data: {
        ...input.current.form,
        type_id: parseInt(input.current.form.type_id),
      },
    });

    if (res !== "error") {
      setModalMessage("Space Added");
      setShowSuccessModal(true);
      await create({ name: "spaces", doc: res });
      navigate("../");
    }
  };
  return (
    <div style={{ width: "50%" }} className="bg-white rounded-[15px] p-10">
      <div className="flex gap-x-6 w-full    ">
        <div className=" flex-1">
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Space Name",
              placeholder: "Eg. Lab, Pharmacy 2 ",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="no"
            mt={0.5}
            config={{
              required: true,
              label: "Space No",
              placeholder: "Eg. A1 ",
              type: "text",
              maxChar: 250,
              minChar: 1,
            }}
          />
          <SelectInput
            input={input}
            inputKey="type_id"
            showError={showError}
            mt={0.5}
            config={{
              required: true,
              firstDisabled: true,
              items: roomType,
              label: "Space Type",
            }}
          />

          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: true,
              label: "Description",
              placeholder: "This is the hospital receptionist space",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <BtnBlock
          bg="primary"
          textColor="white"
          activeBgColor="secondary"
          mt={2}
          click={handleSubmit}
          className="btn"
        >
          <span className=" font-pop-reg">Submit</span>
          <span className="px-3 font-normal text-2xl">
            <FaLongArrowAltRight />
          </span>
        </BtnBlock>
      </div>
    </div>
  );
};

export default Form;
