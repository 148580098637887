import React, { useContext, useEffect, useState } from 'react'

import { 
  TableCell,
  TableRow,
  TableText, 
} from '../../../../../../Components/FinalTable'

import NumberInput from '../../../../../../Components/StateInput/NumberInput'

import TContext from './TContext'

import SingleCheckBox from '../../../../../../Components/FinalTable/components/Actions/SingleCheckBox'


const TRow = ( {doc} ) => {

  const {checked_id, setCheckedId, price, setPrice } = useContext(TContext)

  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 6}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.inventory_item_categories.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.store_qty} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.unit_cost} />
      </TableCell>
      <TableCell w={90 / 6}>
      <NumberInput
        initial={1}
        setInput={setPrice}
        doc={doc}
        input_key="price"
        mt={0.5}
        config={{ max: 9999999, min: 0 }}
      />
      </TableCell>
      <TableCell >
      <SingleCheckBox checked_id={checked_id} setCheckedId={setCheckedId} id={doc.id} />
      </TableCell>
  </TableRow>
  )
}

export default TRow