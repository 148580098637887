const longMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const convDate = (n) => {
  let d = new Date(n);

  return `${d.getDate()} ${shortMonthNames[d.getMonth()]} ${d.getFullYear()}`;
};

const convDate2 = (n) => {
  let d = new Date(n);

  return `${d.getFullYear()}-${toFullNo(d.getMonth() + 1)}-${toFullNo(
    d.getDate()
  )}`;
};

const convDate3 = (n) => {
  let d = new Date(n);
  return `${Days[d.getDay()]}, ${d.getDate()} ${
    longMonths[d.getMonth()]
  } ${d.getFullYear()}`;
};

const toFullNo = (n) => {
  if (parseInt(n) < 10) {
    return `0${n}`;
  }
  return n;
};

const toTimeStamp = (d) => {
  const date = new Date(d);
  return date.getTime();
};

export { convDate, convDate2, convDate3, toTimeStamp };
