import FormScreen from '../../../../../Components/Templates/FormScreen'
import FormLabel from '../../../../../Components/Label/FormLabel'
import CancelBar from '../../../../../Components/Steppers/CancelBar'
import Form from './components/Form';
import { MdAddCircle } from 'react-icons/md';

const Spaces = () => {
  return (
    <div className="w-full h-full bg-ghost_white">
      <FormScreen backBtn={false} back={() => {}}>
        <FormLabel label="Add Space" mt={2} mb={2}>
          <MdAddCircle />
        </FormLabel>
        <CancelBar />
        <div className="w-full h-full flex flex-row justify-center items-center">
          <Form />
        </div>
      </FormScreen>
    </div>
  );
};

export default Spaces;
