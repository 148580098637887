import React, { useEffect, useState } from "react";

import { Table } from "../../../../../../../Components/FinalTable";

import TBody from "./TBody";

import THead from "./THeader";

import TInfo from "./TInfo";

import TableContext from "./TContext";

import { useNavigate, useParams } from "react-router-dom";

import { FaPlus } from "react-icons/fa";

import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";

import useAxios from "../../../../../../../Hooks/useAxios";


const FixedAssetAllocation = () => {
  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(10);

  const [data, setData] = useState([]);

  const { id } = useParams();

  const [showPagination, setShowPagination] = useState(true);



  const [page, setPage] = useState(1);

  const [hasNextPage, setHasNextPage] = useState(false);

  const [hasPrevPage, setHasPrevPage] = useState(false);

  
  const [tableIsLoading, setTableIsLoading] = useState(false);
  
  const [isFiltered, setIsFiltered] = useState(false);
  
  const navigate = useNavigate();

  const request = useAxios();

  const getAssignedFixedAssets = async(
    page = 1
    )=>{
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "store/allocations",
      params: {
        page,
        limit,
        user_id: id,
        is_inventory: false,
      },
      network_error: false,
    });

    if (res === "network-error") {
      navigate("/dashboard/team");
      return;
    }

    
    if (res === "error") {
      navigate("/dashboard/team");
      return;
    }

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
    setTotal(res.total_docs);
    setTableIsLoading(false);
  }
  
  useEffect(()=>{
    getAssignedFixedAssets(1, "created_at", "desc", {})
  },[limit])

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getAssignedFixedAssets(page + 1);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getAssignedFixedAssets(page - 1);
    }
  }

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        isFiltered,
        total,
        setIsFiltered,
        showPagination,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full">
          <p className="font-DM-Med text-lg uppercase text-BLACK">
            USER FIXED ASSETS
          </p>
          <div className="">
            <BtnPlain
              bg="primary"
              textColor="white"
              activeBgColor="secondary"
              mt={2}
              click={() => navigate("add/fixed")}
              className="btn"
            >
              <span className="px-2 font-light text-xl">
                <FaPlus />
              </span>
              <span className=" font-pop-reg">Add Fixed Asset</span>
            </BtnPlain>
          </div>
        </div>
        <Table>
          <THead />
          <TBody />
          <TInfo />
        </Table>
      </div>
    </TableContext.Provider>
  );
};

export default FixedAssetAllocation;
