import { FaHospitalAlt } from "react-icons/fa";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const Spaces = ({ open = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "space-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Spaces"}
        open={open}
        active={location.pathname.includes("dashboard/spaces")}
        click={() => navigate("spaces")}
      >
        <FaHospitalAlt />
      </BtnTemplate>
      <Tooltip id={id} txt1="Space Management" txt2="in one spot">
        <FaHospitalAlt />
      </Tooltip>
    </div>
  );
};

export default Spaces;
