import React, { useState, useEffect } from "react";

import { VscBellDot } from "react-icons/vsc";

import useApp from "../../../../../Hooks/useApp";

import usePouch from "../../../../../Hooks/usePouch";

import WeatherNavbar from "../../../../Admin/components/Navbar";

const Navbar = () => {
  const { user } = useApp();

  const [title, setTitle] = useState(" ");

  const { fetchById } = usePouch();
  useEffect(() => {
    async function init() {
      try {
        let doc = await fetchById({ db: "titles", id: user.title_id });
        setTitle(doc.name);
      } catch (e) {}
    }
    init();
  }, []);

  return (
    <div className="flex justify-between items-center py-4">
      <div>
        <h2 className="text-xl font-DM-Bold text-cadet_grey">
          Welcome back, {title}{" "}
          <span className="text-rose-800">{user.name.split(" ")[0]}</span>{" "}
        </h2>
      </div>
      <div className="flex">
        <WeatherNavbar />
        <div className="mt-6">
          <span>
            <VscBellDot size="20px" color="red" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
