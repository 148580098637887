import React, { useState, useEffect } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../Components/FinalTable";

import Badge from "../../../../../../Components/Badges/Badge";

import TableActions from "./TableActions";

import usePouch from "../../../../../../Hooks/usePouch";

import useTeamAll from "../Hooks/useTeamAll";

const TRow = ({ doc }) => {
  const [role, setRole] = useState(".....");

  const { fetchById } = usePouch();

  const { showLive } = useTeamAll();

  useEffect(() => {
    async function init() {
      if (!showLive) {
        let res = await fetchById({
          db: "team_roles",
          id: doc.role_id.toString(),
        });
        // console.log(res)
        setRole(res.role);
      }
    }
    init();
  });

  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={90 / 5}>
          <TableText label={doc.name} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.email} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.phone} />
        </TableCell>
        <TableCell w={90 / 5}>
          {/* <TableText label={showLive ? doc.team_roles.role : role.role} /> */}
          <TableText label={doc.team_roles ? doc.team_roles.role : ""} />
        </TableCell>
        <TableCell w={90 / 5}>
          <Badge
            label={doc.is_active ? "Active" : "Inactive"}
            bg={doc.is_active ? "pale_spring_bud" : "pale_pink"}
          />
        </TableCell>
        <TableActions doc={doc} />
      </TableRow>
    </>
  );
};

export default TRow;
