import React from 'react'
import { TbNurse } from 'react-icons/tb'
import { HiOutlineSwitchVertical } from 'react-icons/hi'

const Nurses = ({nurses}) => {
  return (
    <div className="w-96 h-40 shadow-sm rounded-2xl bg-primary p-6">
            <div className="flex justify-between items-center mb-10 ">
                <div className="font-DM-Reg text-xl text-white">
                    <p>Nurses</p>
                </div>
                <div>
                    <TbNurse
                        size="28px"
                        className="text-white"
                    />
                </div>
            </div>
            <div className="flex justify-between items-center my-10 ">
                <div className="font-pop-reg text-4xl text-white">
                    <p>{nurses}</p>
                </div>
                <div className="flex justify-center items-center ">
                    <div className="flex flex-col justify-center items-start">
                        <p className="text-white my-0"></p>
                        <p className="text-white mt-0"></p>
                    </div>
                    <div className="flex flex-col align-middle">
                        <HiOutlineSwitchVertical className="text-gray-200 text-3xl" />
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Nurses