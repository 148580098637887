import { useNavigate, useParams } from "react-router-dom";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";

import { useContext } from "react";

import { RiDeleteBinLine } from "react-icons/ri";

import useAxios from "../../../../../../../Hooks/useAxios";
import useApp from "../../../../../../../Hooks/useApp";
import usePouchCud from "./../../../../../Hooks/usePouchCud";

import TableContext from "./TContext";

const TableAction = ({ doc }) => {
  const request = useAxios();

  const { setShowSuccessModal, setModalMessage } = useApp();

  const { data, setData } = useContext(TableContext);
  const { deleteDoc } = usePouchCud();

  const handleDelete = async () => {
    let res = await request({
      method: "DELETE",
      url: `hospital/investigation/fixed/${doc.id}`,
    });

    if (res !== "error") {
      await deleteDoc({ name: "investigation_fixed", doc: res });
      setModalMessage("Fixed Asset Investigation Deleted!");
      setShowSuccessModal(true);
    }

    let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
    setData(newDocs);
  };

  return (
    <div className="flex-1">
      <div className=" gap-y-2 flex flex-col text-sm">
        <BtnPlain activeBgColor="white" bg="white" click={() => handleDelete()}>
          <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
            <p className="text-red">
              <RiDeleteBinLine />
            </p>
          </div>
        </BtnPlain>
      </div>
    </div>
  );
};

export default TableAction;
