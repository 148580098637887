import React, { useContext, useEffect, useState } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../Components/FinalTable";

import TContext from "./TContext";

import NumberInput from "../../../../../../Components/StateInput/NumberInput";

import SingleCheckBox from "../../../../../../Components/FinalTable/components/Actions/SingleCheckBox";
import usePouch from "../../../../../../Hooks/usePouch";

const TRow = ({ doc }) => {
  const { checked_id, setCheckedId, setDocs } = useContext(TContext);
  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 6}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.sn} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.fixed_assets_categories.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.total_qty} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.unit_cost} />
      </TableCell>

      <TableCell w={90 / 6}>
        <SingleCheckBox
          checked_id={checked_id}
          setCheckedId={setCheckedId}
          id={doc.id}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
