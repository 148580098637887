import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import SchemeCard from "./components/SchemeCard";

import { useParams, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import SchemeInventory from "./components/SchemeInventory/Table";
import SchemeInvestigation from "./components/SchemeInvestigation/Table";
import SchemeProcedure from "./components/SchemeProcedure/Table";
import SchemeConsumables from "./components/SchemeConsumables/Table";
import useAxios from "../../../../../Hooks/useAxios";
import useApp from "../../../../../Hooks/useApp";

const View = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {setIsLoading} = useApp()
  const request = useAxios()
  const [scheme, setScheme] = useState([]);
  const [insurance, setInsurance] = useState([])
  const [inventory, setInventory] = useState([]);
  const [procedure, setProcedure] = useState([]);
  const [investigation, setInvestigation] =useState([]);
  const [consumable, setConsumable] = useState([]);

  useEffect(() => {
    async function initialize() {
      await fetchData();
    }

    initialize();
    
  }, []);

  async function fetchData() {
    try {
      let res = await request({
          method: "GET",
          url: `hospital/insurance/scheme/${id}`
      });
              
      setScheme(res);
      setInsurance(res.insurance)
      setInventory(res.scheme_inventory);
      setInvestigation(res.scheme_investigation);
      setConsumable(res.scheme_consumable);
      setProcedure(res.scheme_procedure);
    } catch (e) {
      navigate("../");
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="w-full h-full bg-ghost_white">
      <BellTopBar label={`${scheme.name}`} />
      <SchemeCard doc={insurance}  />
      <div className="mt-5 px-6">
        <SchemeInventory inventory={inventory} setInventory={setInventory} fetchData={fetchData} />
      </div>
      <div className="mt-5 px-6">
        <SchemeInvestigation investigation={investigation} setInvestigation={setInvestigation} fetchData={fetchData}  />
      </div>
      <div className="mt-5 px-6">
        <SchemeProcedure procedure={procedure} setProcedure={setProcedure} fetchData={fetchData} />
      </div>
      <div className="mt-5 px-6">
        <SchemeConsumables consumable={consumable} setConsumable={setConsumable} fetchData={fetchData} />
      </div>
    </div>
  );
};

export default View;
