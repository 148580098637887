import React from "react";
import {
  TableRow,
  TableCell,
  TableText,
} from "../../../../../../../Components/FinalTable";

import TableAction from "./TActions";
import { convDate } from "../../../../../../../Util/Time";

const TRow = ({ doc }) => {
  
  return (
    <TableRow key={doc._id}>
      <TableCell w={90 / 5}>
        <TableText label={doc.no} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.insurance.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.consoltation_limit} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={convDate(doc.created_at)} />
      </TableCell>
      <TableCell>
        <TableAction doc={doc} />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
