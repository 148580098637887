import { FaShieldVirus } from "react-icons/fa";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const Insurance = ({ open = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "insurance-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Insurance"}
        open={open}
        active={location.pathname.includes("dashboard/insurance")}
        click={() => navigate("insurance")}
      >
        <FaShieldVirus />
      </BtnTemplate>
      <Tooltip id={id} txt1="Insurance Management" txt2="in one spot">
        <FaShieldVirus />
      </Tooltip>
    </div>
  );
};

export default Insurance;
