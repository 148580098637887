import { useState } from "react";
import { HiMenuAlt2, HiMenuAlt3 } from "react-icons/hi";

import logo from "./../../../../../Assets/Images/miniLogo.png";

import { colors } from "./../../../../../Util/colors";

import BtnTemplate from "../BtnTemplate";

const Menu = ({ open, setOpen }) => {
  const [hover, setHover] = useState(false);
  let s = 4;
  return (
    <div
      className="w-full cursor-pointer hover:animate-bounce"
      onClick={() => setOpen(!open)}
    >
      <div
        className="flex flex-row justify-between mt-6 w-full px-2 mb-1"
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {open ? <img alt="logo" src={logo} style={{ height: "2em" }} /> : null}
        <p
          className="text-2xl"
          style={{ color: open ? colors.primary : colors.cadet_grey }}
        >
          {hover ? <HiMenuAlt3 /> : <HiMenuAlt2 />}
        </p>
      </div>
      {/* {open ? (
        <p className=" font-pop-reg text-center text-primary">Mkombozi</p>
      ) : null} */}
    </div>
  );
};

export default Menu;
