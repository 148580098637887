import React from 'react'

import insuranceIcon from '../../../../../../Assets/Images/Insurance.svg'

const InsuranceItem = ({ doc }) => {
  return (
    <div className='bg-white w-[650px] shadow rounded flex flex-col justify-start items-between'>
        <img src={insuranceIcon} className='mx-auto p-5 h-80' alt="" />
        <div className="flex justify-between">
        <div className="flex flex-col justify-between gap-x-20 p-10 px-14">
            <div className="flex flex-col my-5">
                <h1 className='text-[#98A1B5] font-semibold text-xl'>NAME</h1>
                <p className='text-xl'>{doc.name}</p>
            </div>
            <div className="flex flex-col">
            <h1 className='text-[#98A1B5] font-semibold text-xl'>INSURANCE ID</h1>
                <p className='text-lg'>{doc.id}</p>    
            </div>
        </div>
        <div className="flex flex-col justify-between gap-x-28 p-10 px-20">
            <div className="flex flex-col my-5">
            <h1 className='text-[#98A1B5] font-semibold text-xl'>COMPANY NAME</h1>
                <p className='text-xl'>{doc.company_name}</p>
            </div>
            <div className="flex flex-col">
            <h1 className='text-[#98A1B5] font-semibold text-xl'>DESCRIPTION</h1>
                <p className='text-lg w-48 break-words'>{doc.description}</p>    
            </div>
        </div>
        </div>
    </div>
  )
}

export default InsuranceItem