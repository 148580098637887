import InsuranceForm from "./components/InsuranceForm";

import FormScreen from '../../../../../Components/Templates/FormScreen'

import FormLabel from '../../../../../Components/Label/FormLabel'

import { useNavigate } from "react-router-dom";

import { FaRegSun } from "react-icons/fa";

const Insurance = () => {

  const navigate = useNavigate()

  return (
     <div className="w-full h-full bg-ghost_white ">
       <FormScreen backBtn={true} back={() => {navigate('../')}}>
        <FormLabel label="Add Insurance" mt={2} mb={2}>
          <FaRegSun />
        </FormLabel>
        <div className="w-full flex flex-row justify-center items-center">
          <InsuranceForm />
        </div>
      </FormScreen>
      </div>
  );
};

export default Insurance;
