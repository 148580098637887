import React, { useState } from 'react'

import AllInvestigationSpacesTable from './InvestigationSpacesForm/Table'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'

import AddItemsContext from './context/AddItemsContext'

const InvestigationSpacesTable = () => {
  const [docs, setDocs] = useState(0)

  return (
    <AddItemsContext.Provider
      value={{
        docs, 
        setDocs
      }}
    >

    <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Investigation Space" />
          
        </div>
        <div className='w-[70%] bg-white m-auto mt-10'>
          <AllInvestigationSpacesTable />
        </div>
    </div>

    </AddItemsContext.Provider>
    
  )
}

export default InvestigationSpacesTable;