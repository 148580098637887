import React, { useContext, useEffect, useState } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../Util/Time";

import TContext from "./TContext";

import SingleCheckBox from "../../../../../../Components/FinalTable/components/Actions/SingleCheckBox";
import usePouch from "../../../../../../Hooks/usePouch";

const TRow = ({ doc }) => {
  const [cats, setCats] = useState("...");
  const { fetchById } = usePouch();

  useEffect(() => {
    async function fetchAssets() {
      try {
        let cat = await fetchById({
          db: "space_types",
          id: doc.type_id.toString(),
        });
        setCats(cat.name);
      } catch (e) {
      } finally {
      }
    }
    fetchAssets();
  }, []);

  const { checked_id, setCheckedId } = useContext(TContext);

  return (
    <TableRow key={doc._id}>
      <TableCell w={90 / 6}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.space_types.name || cats} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.no} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.description} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={convDate(doc.created_at)} />
      </TableCell>
      <TableCell>
        <SingleCheckBox
          checked_id={checked_id}
          setCheckedId={setCheckedId}
          id={doc.id || doc._id}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
