 import { IoMdNotificationsOutline} from 'react-icons/io'
 
 const Header =()=>{
    return (
        <div className=' w-full h-10 bg-white flex justify-between items-center p-8' >
            <h1 className=' font-DM-Bold'  style={{ fontSize:20, color:'#666CCC', marginLeft:10}}>Users/John Doe</h1>
            <span>
                <IoMdNotificationsOutline size={24}/>
                <span className='bg-red h-1 w-1 rounded-full'></span>
            </span>
        </div>
    )
 };

 export default Header;