import React from 'react'

import { Table } from '../../../../../../Components/FinalTable'

import TableHeader from './TableHeader'

import TBody from './TBody'

import TInfo from './TInfo'

const AllInsuranceTable = () => {
    return (
      <Table>
        <TableHeader />
        <TBody />
        <TInfo />
      </Table>
    )
  }
  
  export default AllInsuranceTable