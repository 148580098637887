import React, { useState } from "react";
import NavBar from "./Components/Header";
import ProfileCard from "./Components/ProfileCard";
import InventoryAllocation from "./Components/AssignedInventory/Table";
import SpaceAllocation from "./Components/AssignedSpaces/Table";
import FixedAssetAllocation from "./Components/AssignedFixedAssets/Table";

const AllTeamMembers = () => {
  return (
    <div className="bg-gray-100 h-auto flex flex-col relative ">
      <NavBar />
      <ProfileCard />
      <div className="w-full h-full p-10">
        <InventoryAllocation />
        <SpaceAllocation />
        <FixedAssetAllocation />
      </div>
    </div>
  );
};

export default AllTeamMembers;
