import { BsCashCoin } from "react-icons/bs";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const Consumables = ({ open = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "consumable-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Consumables"}
        open={open}
        active={location.pathname.includes("dashboard/cash")}
        click={() => navigate("cash")}
      >
        <BsCashCoin />
      </BtnTemplate>
      <Tooltip id={id} txt1="Consumables Management" txt2="in one spot">
        <BsCashCoin />
      </Tooltip>
    </div>
  );
};

export default Consumables;
