import { useState, useRef } from "react";

import FormScreen from '../../../../../Components/Templates/FormScreen'

import FormLabel from '../../../../../Components/Label/FormLabel'

import AddSteppers from "./components/Stepper";

import Forms from "./components/Forms";

import AddScheme from "./context/AddScheme";

import { AiOutlinePlusCircle } from "react-icons/ai";

import { useNavigate } from "react-router-dom";

const Add = () => {

  const [step, setStep] = useState(-1);

  const navigate = useNavigate()

  const [scheme, setScheme] = useState({
    name: "",
    no: '',
    description: "",
    insurance_id: null,
  });

  const [items, setItems] = useState({
    name: "",
    no: "",
    description: "",
    insurance_id: '',
  });

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }


  return (
    <AddScheme.Provider
      value={{
        scheme,
        setScheme,
        items,
        setItems,
      }}
    >
      <FormScreen backBtn={false} back={handleBack}>
        <FormLabel label="Create Scheme" mt={2} mb={2}>
          <AiOutlinePlusCircle />
        </FormLabel>
        <AddSteppers currentStep={step} close={() => {navigate('../')}} />
        <div className="w-full flex flex-row justify-center items-center">
          <Forms step={step} setStep={setStep} />
        </div>
      </FormScreen>
    </AddScheme.Provider>
  );
};

export default Add;
