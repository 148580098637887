import FormScreen from "../../../../../Components/Templates/FormScreen";
import FormLabel from "../../../../../Components/Label/FormLabel";
import CancelBar from "../../../../../Components/Steppers/CancelBar";
import Form from "./components/Form";
import { MdModeEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddContext from "./components/AddContext";
import useAxios from "../../../../../Hooks/useAxios";

const Spaces = () => {
  const { id } = useParams();
  const [rom, setRom] = useState({});
  const [complete, setComplete] = useState(false);
  const request = useAxios();
  useEffect(() => {
    async function Initialize() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/space/${id}`,
        });
        setRom(res);
        setComplete(true);
      } catch (e) {}
    }
    Initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddContext.Provider value={{ rom, setRom, complete }}>
      <div className="w-full h-full bg-ghost_white">
        <FormScreen backBtn={false} back={() => {}}>
          <FormLabel label="Edit Space" mt={2} mb={2}>
            <MdModeEdit />
          </FormLabel>
          <CancelBar />
          <div className="w-full h-full flex flex-row justify-center items-center">
            <Form thisRoom={rom} />
          </div>
        </FormScreen>
      </div>
    </AddContext.Provider>
  );
};

export default Spaces;
