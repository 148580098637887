import React, { useState } from "react";
import Avatar from "./Avatar";

import Menu from "./Btns/Menu";

import SummaryBtn from "./Btns/SummaryBtn";

import Procedure from "./Btns/Procedure";

import Investigation from "./Btns/Investigations";

import Space from "./Btns/Space";

import Team from "./Btns/Team";

import Admin from "./Btns/Admin";

import LogOut from "./Btns/LogOut";
import Scheme from "./Btns/Schemes";
import Consumables from "./Btns/Consumables";
import Insurance from "./Btns/Insurance";

const SideBar = () => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className=" transition-all duration-500 ease-in-out h-screen shadow-md w-fit flex flex-col items-center border-r-2 gap-y-2"
      style={{}}
    >
      <Menu open={open} setOpen={setOpen} />
      <SummaryBtn open={open} />
      <Procedure open={open} />
      <Investigation open={open} />
      <Space open={open} />
      <Scheme open={open} />
      <Consumables open={open} />
      <Insurance open={open} />
      <div className="flex-1"></div>
      <div
        style={{ height: "1.3px" }}
        className=" w-full bg-bright_grey mb-4"
      ></div>
      {/* <SettingsBtn open={open} active={false} /> */}
      <Team open={open} active={false} />
      {/* <Admin open={open} active={false} /> */}
      <LogOut open={open} />
      <Avatar name="Samuel mwangi" />
      <div className=" mb-7"></div>
    </div>
  );
};

export default SideBar;
