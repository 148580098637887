import React, { useContext } from "react";

import TeamAll from "../Context/TeamAll";

const useTeamAll = () => {
  const doc = useContext(TeamAll);

  return doc;
};

export default useTeamAll;
