import React, { useState, useRef, useEffect } from "react";
import TextInput from "../../../Components/Inputs/TextInput";

import BtnRounded from "../../../Components/Buttons/BtnRounded";

import useApp from "../../../Hooks/useApp";

import useAxios from "./../../../Hooks/useAxios";

import { useNavigate } from "react-router-dom";

import useIntialize from "../../../Hooks/useInitialize";

const FormSection = () => {
  const input = useRef({ form: {}, err: {} });
  const navigate = useNavigate();

  const request = useAxios();

  const {
    setModalMessage,
    setShowFormModal,
    setUser,
    setToken,
    setIsLoggedIn,
  } = useApp();
  const [showError, setShowError] = useState(0);

  const { update, intialize } = useIntialize();

  useEffect(() => {
    async function init() {
      let doc = await intialize();

      if (doc === "error") {
        return;
      }
      setUser(doc.user);
      setToken(doc.token);
      setIsLoggedIn(true);
      navigate("/dashboard");
    }

    init();
  }, []);

  const handleSubmit = async () => {
    setShowError(showError + 1);

    let keys = Object.keys(input.current.err);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (input.current.err[key]) {
        setModalMessage(`Error processing form. check field ${key}`);
        setShowFormModal(true);
        return;
      }
    }

    let res = await request({
      method: "POST",
      data: {
        email: input.current.form.email,
        password: input.current.form.password,
      },
      url: "team/signin",
    });

    if (res === "error") return;
    let exp = Date.now() + 3600000 * 5.5;

    if (res.user.team_roles.role !== "Admin") {
      setModalMessage(`Unothorized,contact system admin`);
      setShowFormModal(true);
      return;
    }

    let medico = await update({
      user: res.user,
      token: res.token,
      exp,
    });

    if (medico === "error") {
      return;
    }

    setUser(res.user);
    setToken(res.token);
    setIsLoggedIn(true);
    navigate("/dashboard");
  };

  return (
    <div className="w-full">
      <TextInput
        initial=""
        input={input}
        showError={showError}
        inputKey="email"
        mt={0.5}
        config={{
          required: true,
          label: "Email",
          placeholder: "Eg. sam@sam.com",
          type: "email",
          inputType: "email",
          maxChar: 250,
          minChar: 4,
        }}
      />
      <TextInput
        initial=""
        input={input}
        showError={showError}
        inputKey="password"
        mt={0.5}
        config={{
          required: true,
          label: "Password",
          placeholder: "Enter Password",
          type: "password",
          maxChar: 250,
          minChar: 4,
        }}
      />

      <BtnRounded mt={2} click={handleSubmit} className="btn">
        <span className=" font-pop-reg">Login</span>
      </BtnRounded>
    </div>
  );
};

export default FormSection;
