import React from "react";

import {
  Table,
  TableInfoCell,
  TableInfoRow,
  TablePagination,
} from "../../../../../../Components/FinalTable";

import TableHeader from "./TableHeader";

import TableBody from "./TableBody";

import TableInfoSection from "./TableInfoSection";

const AllTeamTable = () => {
  return (
    <Table>
      <TableHeader />
      <TableBody />
      <TableInfoSection />
    </Table>
  );
};

export default AllTeamTable;
