import React, { useState, useEffect } from "react";

import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import { TableTopBar } from "../../../../../Components/FinalTable";
import AllTeamTable from "./components/AllTeamTable";
import { useNavigate } from "react-router-dom";


import TeamAll from "./Context/TeamAll";

import useAxios from "../../../../../Hooks/useAxios";

import Fuse from "fuse.js";

const LiveData = ({ showLive, setShowLive }) => {
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState([]);
    const [showPagination, setShowPagination] = useState(true);
    const [searchInput, setSearchInput] = useState("");
    const [page, setPage] = useState(1);

    const [sortOrder, setSortOrder] = useState("desc");
    const [sortField, setSortField] = useState("created_at");
    const [filters, setFilters] = useState({});
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);

    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);

    const request = useAxios();

    const navigate = useNavigate();

    const getTeamUsers = async (
        page = 1,
        sort_field = "created_at",
        sort_order = "desc",
        filters = {}
    ) => {
        setTableIsLoading(true);
        let res = await request({
        method: "GET",
        url: "team",
        params: {
            page,
            limit,
            sort_field: sort_field,
            sort_order: sort_order,
            ...filters,
        },
        network_error: false,
        });

        if (res === "network-error") {
        setShowLive(false);
        return;
        }

        setTableIsLoading(false);

        if (res === "error") return;

        setData(res.docs);
        setHasNextPage(res.hasNextPage);
        setHasPrevPage(res.hasPrevPage);
        setTotal(res.total_docs);
    };

    useEffect(() => {
        getTeamUsers(1, "created_at", "desc", {});
    }, [limit]);

    useEffect(() => {
        if (searchInput.length < 3) return;
        async function search() {
        setTableIsLoading(true);
        let res = await request({
            method: "GET",
            url: "team/search",
            params: {
            value: searchInput,
            },
        });

        if (res === "network-error") {
            setShowLive(false);
            return;
        }

        if (res === "error") return;

        const fuse = new Fuse([...res.names, ...res.emails], {
            keys: ["name", "email"],
        });

        const result = fuse.search(searchInput);

        let ids = [];
        let new_docs = [];

        for (let i = 0; i < result.length; i++) {
            if (i === limit) {
            break;
            }
            let doc = result[i].item;
            if (ids.includes(doc.id)) {
            continue;
            }
            ids.push(doc.id);
            new_docs.push(doc);
        }
        setData(new_docs);
        setIsFiltered(true);
        setTableIsLoading(false);
        }

        search();
    }, [searchInput]);

    async function handleNext() {
        if (hasNextPage) {
        setPage(page + 1);
        getTeamUsers(page + 1, sortField, sortOrder);
        }
    }

    async function handlePrev() {
        if (hasPrevPage) {
        setPage(page - 1);
        getTeamUsers(page - 1, sortField, sortOrder);
        }
    }

    async function handleFilterQuery(filters) {
        setSortField("created_at");
        setSortOrder("desc");
        getTeamUsers(1, "created_at", "desc", filters);

        setIsFiltered(true);
        setFilters(filters);
    }

    async function handleHeaderQuery(field, order) {
        setPage(1);
        setSortOrder(order);
        setSortField(field);
        getTeamUsers(1, field, order);
    }

    async function handleClearFilters() {
        setSearchInput("");
        await getTeamUsers(1, "created_at", "desc");
        setPage(1);
        setSortField("created_at");
        setSortOrder("desc");
        setIsFiltered(false);
        setShowPagination(true);
    }

    function handleAdd() {
        navigate("/dashboard/team/add");
    }

    return (
        <TeamAll.Provider
        value={{
            showLive,
            limit,
            setLimit,
            data,
            setData,
            page,
            hasNextPage,
            hasPrevPage,
            handleNext,
            handlePrev,
            handleHeaderQuery,
            handleFilterQuery,
            total,
            setIsFiltered,
            showPagination,
        }}
        >
        <div className="w-full min-h-screen bg-ghost_white ">
            <BellTopBar label="Team" />
            <div className="w-full px-7 pb-8    ">
            <TableTopBar
                mt={2}
                mb={2}
                showAdd={true}
                AddClick={handleAdd}
                showFilter={false}
                isFiltered={isFiltered}
                handleClearFilters={handleClearFilters}
                tableIsLoading={tableIsLoading}
                showReport={false}
                addLabel="Add User"
                searchInput={searchInput}
                setSearchInput={setSearchInput}
            />
            <AllTeamTable />
            </div>
        </div>
        </TeamAll.Provider>
    );
}

export default LiveData;