import React from 'react'

import BellTopBar from '../../../../../Components/Bars/BellTopBar'
import AllSpacesTable from './SpacesTableForm/Table'

const InventoryTable = () => {

  return (

    <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Space" />
          
        </div>

        <AllSpacesTable />

    </div>
    
  )
}

export default InventoryTable