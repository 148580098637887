import React, { useContext } from 'react'

import { 
  TableCell,
  TableRow,
  TableText, 
} from '../../../../../../Components/FinalTable'

import TContext from './TContext'

import NumberInput from '../../../../../../Components/StateInput/NumberInput'

import SingleCheckBox from '../../../../../../Components/FinalTable/components/Actions/SingleCheckBox'

const TRow = ( {doc} ) => {

  const {checked_id, setCheckedId, price, setPrice} = useContext(TContext);

  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 5}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.code} />
      </TableCell>
      
      <TableCell w={90 / 5}>
        <TableText label={doc.regular_price} />
      </TableCell>
      <TableCell w={90 / 6}>
      <NumberInput
        initial={1}
        setInput={setPrice}
        doc={doc}
        inputKey="price"
        mt={0.5}
        config={{ max: 9999999999, min: 0 }}
      />
      </TableCell>
      <TableCell >
      <SingleCheckBox checked_id={checked_id} setCheckedId={setCheckedId} id={doc.id} />
      </TableCell>
  </TableRow>
  )
}

export default TRow