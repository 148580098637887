import { useState, useEffect } from "react";

import FormScreen from "../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../Components/Label/FormLabel";

import CancelBar from "../../../../../Components/Steppers/CancelBar";

import { FiEdit2 } from "react-icons/fi";

import { useNavigate, useParams } from "react-router-dom";

import useApp from "../../../../../Hooks/useApp";
import useAxios from "../../../../../Hooks/useAxios";
import EditInvestigationDetails from "./components/InvestigationDetails";

const Edit = () => {
  const [step, setStep] = useState(-1);

  const { id } = useParams();

  const {setIsLoading } = useApp();

  const request = useAxios()

  const navigate = useNavigate();

  const [investigation, setInvestigation] = useState([]);
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    async function initialize() {
      await fetchData();
    }

    initialize();
  }, []);

    async function fetchData() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/investigation/${id}`,
        });
        
      if (res === "error") {
        navigate("../");
        return;
      }

      setInvestigation(res);
      setComplete(true)
      
      } catch (e) {
        navigate("../");
      } finally {
        setIsLoading(false)
      }
    }

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  return (
    
      <FormScreen backBtn={false} back={handleBack}>
        <FormLabel label="Edit Investigation" mt={2} mb={2}>
          <FiEdit2 />
        </FormLabel>
        <CancelBar />
        <div className="w-full flex flex-row justify-center items-center">
          <EditInvestigationDetails 
            investigation={investigation} 
            setInvestigation={setInvestigation}
            complete={complete}
            setComplete={setComplete} 
            fetchData={fetchData} 
          />
        </div>
      </FormScreen>
    
  );
};

export default Edit;
