import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import ProcedureItem from "./components/ProcedureItem";

import { useParams, useNavigate } from "react-router-dom";

import usePouch from "../../../../../Hooks/usePouch";

import { useEffect, useState } from "react";
import InventoryAllocation from "./components/AssignedInventory/Table";
import FixedAssetAllocation from "./components/AssignedFixedAssets/Table";

import AddProcedure from "../Edit/context/addProcedure";
import SpaceAllocation from "./components/AssignedSpaces/Table";
import useAxios from "../../../../../Hooks/useAxios";

const View = () => {
  const { id } = useParams();
  const request = useAxios();
  const navigate = useNavigate();
  const [procedure, setProcedure] = useState({});
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    async function fetchProcedure() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/procedure/${id}`,
        });

        setProcedure(res);
        setComplete(true);
      } catch (e) {}
    }
    fetchProcedure();
  }, []);

  return (
    <div className="w-full h-auto bg-ghost_white">
      <BellTopBar label={`${procedure.name}`} />
      <div className="py-5 my-10 w-full flex align-center justify-center">
        <ProcedureItem doc={procedure} id={id} />
      </div>
      <div className="w-full h-full p-10">
        <InventoryAllocation />
        <SpaceAllocation />
        <FixedAssetAllocation />
      </div>
    </div>
  );
};

export default View;
