import React, { useEffect, useState } from "react";
import { Table } from "../../../../../../../Components/FinalTable";
import usePouchPagination from "../../../../../../../Hooks/usePouchPagination";
import usePouchListner from "../../../../../../../Hooks/usePouchListner";
import TBody from "./TBody";
import THead from "./THeader";
import TInfo from "./TInfo";
import TableContext from "./TContext";
import { useNavigate, useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";

const PouchData = ({showLive}) => {
  const [changes, setChanges] = useState(0);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const navigate = useNavigate();

  const db_name = "procedure_inventory";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
  });

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full">
          <p className="font-DM-Med text-lg uppercase text-BLACK">
            PROCEDURE INVENTORY
          </p>
          <div className="">
            <BtnPlain
              bg="primary"
              textColor="white"
              activeBgColor="secondary"
              mt={2}
              click={() => navigate("add/inventory")}
              className="btn"
            >
              <span className="px-2 font-light text-xl">
                <FaPlus />
              </span>
              <span className=" font-pop-reg">Add Inventory</span>
            </BtnPlain>
          </div>
        </div>
        <Table>
          <THead />
          <TBody />
          <TInfo />
        </Table>
      </div>
    </TableContext.Provider>
  );
};

export default PouchData;
